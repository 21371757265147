import React, { useState, useMemo } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { GoAlert } from "react-icons/go";

//import input validation
import inputHook from "../../hooks/inputHook";

//import firebase functions for login
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

//import message modal for success and failed messages
import MessageModal from "../../components/modals/public/MessageModal";

const ForgotPassword = () => {
  const [inputData, setInputData] = useState({
    email: "",
  });

  const [hasErrors, setHasErrors] = useState({
    email: false,
  });

  const [resetPasswordError, setResetPasswordError] = useState(false);

  //shows message if reset password is success or failed
  const [successModal, setSuccessModal] = useState(false);

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(e.target.value, e.target.name);
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const disabledSubmit = useMemo(
    () =>
      !(
        !Object.values(inputData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [inputData, hasErrors]
  );

  const modalFunction = () => {
    console.log("success");
  };

  //tests user provided email is registered
  const signIn = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, inputData.email)
      .then(async () => {
        setSuccessModal(true);
      })
      .catch((error) => {
        //throw error when a user enter the wrong email
        setResetPasswordError(true);
      });
  };

  return (
    <div>
      <Navigation />
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-left">
          <form className="space-y-6" onSubmit={signIn}>
            {resetPasswordError && (
              <div className="text-red-500 text-sm flex items-center gap-2 justify-center p-2 bg-red-200 rounded-lg">
                <GoAlert /> Invalid email.
              </div>
            )}

            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.email
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  value={inputData.email}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {hasErrors.email && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Invalid email address.
                </div>
              )}
            </div>

            <div>
              <button
                className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75 ${
                  disabledSubmit && "cursor-not-allowed"
                }`}
                type="submit"
                disabled={disabledSubmit}
                aria-labelledby="resetButtonLabel"
              >
                Reset password
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/registration"
              className="ml-1 font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Register here
            </a>
          </p>
        </div>
      </div>
      <MessageModal
        title={"Reset password success"}
        description={
          "Check your email and follow steps to reset your password!"
        }
        approve={true}
        gif={"success"}
        buttonText={"Close"}
        displayModal={successModal}
        setDisplayModal={setSuccessModal}
        functionPassed={modalFunction}
      />
      <Footer />
    </div>
  );
};

export default ForgotPassword;
