import React, { useState, useEffect } from "react";
import "./../../../App.css";
import {
  collection,
  getFirestore,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import ModalNotification from "./ModalNotification";
import MessageModal from "../../../components/modals/public/MessageModal";

const OrganisationUpdateCategoryFormModal = ({
  displayUpdateModalForm,
  setUpdateModalForm,
  orgId,
  refreshTable,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalNotification, setDisplayModalNotification] =
    useState(false);
  const [displayModalMessage, setDisplayModalMessage] = useState(false);

  // Fetch data of organization in firestore
  const [orgCategoryData, setOrgCategoryData] = useState([]);
  const db = getFirestore();
  const [orgCategoryId, setOrgCategoryId] = useState();

  const [directoryID, setDirectoryID] = useState("");
  const [directoryName, setDirectoryName] = useState("");

  useEffect(() => {
    async function fetchCategory() {
      try {
        const querySnapshot = await getDocs(collection(db, "org_catgry"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrgCategoryData(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    fetchCategory();

    if (orgId) {
      setDirectoryID(orgId.id || "");
      setDirectoryName(orgId.ctgry_name || "");
    }
  }, [orgId]);

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleUpdateOrgData = async (e) => {
    e.preventDefault();
    setDisplayModalNotification(true);
  };

  const ModalFunctionUpdateOrgData = async (e) => {
    try {
      const orgDataRef = doc(db, `org_catgry/${orgId.id}`);
      await updateDoc(orgDataRef, {
        ctgry_name: directoryName.toLowerCase(),
      });
      setDisplayModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const modalFunctionClose = () => {
    setUpdateModalForm(false);
    refreshTable();
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${
          displayUpdateModalForm
            ? "opacity-100 ease-out duration-300"
            : "ease-in duration-300"
        }`}
        style={{ visibility: displayUpdateModalForm ? "visible" : "hidden" }}
      ></div>
      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto"
        style={{ visibility: displayUpdateModalForm ? "visible" : "hidden" }}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
              displayUpdateModalForm
                ? "opacity-100 translate-y-0 sm:scale-100"
                : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => setDisplayModalMessage(true)}
            >
              <span className="sr-only">Close</span>
              &times;{" "}
              {/* Or use an icon like X from Heroicons or FontAwesome */}
            </button>
            {/* Modal Title */}
            <div className="px-6 py-4 border-b border-gray-200">
              <h2
                className="text-lg font-medium text-gray-900"
                id="modal-title"
              >
                Update Directory Data
              </h2>
            </div>
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm text-left">
                <form className="space-y-6">
                  <div>
                    <label
                      for="directoryName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Directory Name
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="directoryName"
                        name={directoryName}
                        value={directoryName}
                        onChange={(e) => {
                          handleChange(setDirectoryName);
                          setDirectoryName(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75 "cursor-not-allowed"}`}
                      type="submit"
                      onClick={handleUpdateOrgData}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        title={"Action Successful"}
        description={"Directory has been successfully updated."}
        approve={true}
        gif={"success"}
        buttonText={"Close"}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        functionPassed={modalFunctionClose}
      />
      <ModalNotification
        title={"Please confirm!"}
        description={"Do you confirm to this update?"}
        approve={false}
        buttonText={"Yes"}
        displayModalNotification={displayModalNotification}
        setDisplayModalNotification={setDisplayModalNotification}
        functionPassed={ModalFunctionUpdateOrgData}
      />
      <MessageModal
        title={"Discard Changes?"}
        description={"Your changes will not be saved, do you wish to continue?"}
        buttonText={"Yes"}
        approve={false}
        displayModal={displayModalMessage}
        setDisplayModal={setDisplayModalMessage}
        functionPassed={modalFunctionClose}
      />
    </div>
  );
};

export default OrganisationUpdateCategoryFormModal;
