import React, { useState } from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import LeftSideBar from '../../components/LeftSideBar';
import DiscussionTable from '../../components/forum_management/DiscussionTable';
import GroupTable from '../../components/forum_management/GroupTable';
import CategoryTable from '../../components/forum_management/CategoryTable';

const ForumManagement = () => {
    const [dataTab, setDataTab] = useState("discussion");
    return (
        <div>
            <Navigation />
            <LeftSideBar>
                <div className="grid place-items-center">
                    <div className="container text-left my-10">
                        <div className="text-4xl">Forum Management</div>
                        <div className="mt-6 inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
                            <button
                                onClick={() => {
                                    setDataTab("category");
                                }}
                                className={`px-5 py-2 text-xs font-medium text-gray-700 transition-colors duration-200 ${
                                dataTab == "category" && "bg-blue-100"
                                } sm:text-sm hover:bg-blue-100`}
                            >
                                Category
                            </button>

                            <button
                                onClick={() => {
                                    setDataTab("group");
                                }}
                                className={`px-5 py-2 text-xs font-medium text-gray-700 transition-colors duration-200 ${
                                dataTab == "group" && "bg-blue-100"
                                } sm:text-sm hover:bg-blue-100`}
                            >
                                Group
                            </button>

                            <button
                                onClick={() => {
                                    setDataTab("discussion");
                                }}
                                className={`px-5 py-2 text-xs font-medium text-gray-700 transition-colors duration-200 ${
                                dataTab == "discussion" && "bg-blue-100"
                                } sm:text-sm hover:bg-blue-100`}
                            >
                                Discussion
                            </button>
                        </div>
                        {dataTab === "discussion" ?
                            <DiscussionTable />
                        : dataTab === "group" ? 
                            <GroupTable /> 
                        : dataTab === "category" &&
                            <CategoryTable />
                        }
                    </div>
                </div>
            </LeftSideBar>
            <Footer />
        </div>
    )
}

export default ForumManagement