import React, { useContext, useState } from "react";
import "./../App.css";
import { CgProfile } from "react-icons/cg";

//import authentication context to use stored user
import { AuthContext } from "../context/authContext";

const Navigation = () => {
  // get current url of page
  let path = window.location.pathname;

  // user authenticated data/functions
  const auth = useContext(AuthContext);

  // check if menu / profile are clicked and open appriate menu / profile.
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isProfileMenuClicked, setIsProfileMenuClicked] = useState(false);
  return (
    <nav className="grid md:grid-cols-1 place-items-center">
      <div className="container flex justify-between gap-8 py-2 px-2">
        <div className="flex items-center gap-3">
          <img
            className="h-auto max-w-40 md:max-w-full md:h-20"
            src="../assets/temp_logo_v2.png"
            alt="Connect Eurobodalla Logo"
          />
        </div>
        <div className="flex items-center justify-end gap-3">
          {/* check if user if logged in and show appropriate links */}
          {!auth.isLoggedIn ? (
            <div className="flex gap-3">
              <a href="/login" className="flex items-center">
                <CgProfile className="mr-2" />
                Sign in
              </a>{" "}
              | <a href="/registration">Register</a>
            </div>
          ) : (
            <div className="relative inline-block text-left group z-[5]">
              <div>
                <button
                  onClick={() => setIsProfileMenuClicked(!isProfileMenuClicked)}
                  type="button"
                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center"
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <CgProfile /> My Profile
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`${
                  isProfileMenuClicked
                    ? "ease-out opacity-100 scale-100"
                    : "ease-in opacity-0 scale-90"
                } transition duration-200`}
              >
                <div
                  className={`${
                    isProfileMenuClicked ? "block" : "hidden"
                  } absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div className="py-1" role="none">
                    {auth.role === "admin" && (
                      <a
                        href="/admin-dashboard"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-0"
                      >
                        Admin Dashboard
                      </a>
                    )}
                  </div>

                  <div className="py-1" role="none">
                    <a
                      href="/my-profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-0"
                    >
                      My Profile
                    </a>
                  </div>
                  <div className="py-1" role="none">
                    <a
                      href="/edit-profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-0"
                    >
                      Edit Profile
                    </a>
                  </div>
                  <div className="py-1" role="none">
                    <button
                      onClick={() => auth.logoutUser()}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      tabIndex="-1"
                      id="menu-item-6"
                      type="button"
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`w-full grid place-items-center ${
          path === "/" ? "nav-bg" : "bg-[#0f75bc]"
        } z-[2]`}
      >
        <div className="container flex justify-between gap-8 py-2 px-2">
          {/* check if user if logged in and show appropriate links */}
          {!auth.isLoggedIn ? (
            <div className="hidden lg:flex items-center gap-10 text-white">
              <a href="/">Home</a>
              <a href="/our-team">Our Team</a>
              <a href="/contact">Contact</a>
            </div>
          ) : (
            <div className="hidden lg:flex items-center gap-10 text-white">
              <a href="/dashboard">Home</a>
              <a href="/our-team">Our Team</a>
              <a href="/community-forum">Community Forum</a>
              <a href="/community-events">Community Events</a>
              <a href="/announcements">Announcements</a>
              <a href="/community-directory">Community Directory</a>
              <a href="/contact">Contact Us</a>
            </div>
          )}
          <div className="flex lg:hidden justify-end">
            <button
              onClick={() => setIsMenuClicked(!isMenuClicked)}
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${isMenuClicked ? "flex" : "hidden"} lg:hidden`}
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 z-[6]"></div>
        <div className="fixed inset-y-0 right-0 z-[7] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-20 w-auto"
                src="../assets/temp_logo_v2.png"
                alt=""
              />
            </a>
            <button
              onClick={() => setIsMenuClicked(!isMenuClicked)}
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="mt-6 flow-root text-left">
            <div className="-my-6 divide-y divide-gray-500/10">
              {!auth.isLoggedIn ? (
                <>
                  <div className="space-y-2 py-6">
                    <a
                      href="/"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Home
                    </a>
                    <a
                      href="/our-team"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Our Team
                    </a>
                    <a
                      href="/#news"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Latest News
                    </a>
                    <a
                      href="/contact"
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Contact
                    </a>
                  </div>
                  <div className="py-6">
                    <a
                      href="/login"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign in
                    </a>
                    <a
                      href="/registration"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Register
                    </a>
                  </div>
                </>
              ) : (
                <div className="space-y-2 py-6">
                  <a
                    href="/dashboard"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  <a
                    href="/our-team"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Our Team
                  </a>
                  <a
                    href="/community-forum"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Community Forum
                  </a>
                  <a
                    href="/community-events"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Community Events
                  </a>
                  <a
                    href="/announcements"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Announcements
                  </a>
                  <a
                    href="/community-directory"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Community Directory
                  </a>
                  <a
                    href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact Us
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
