import React, { useState, useEffect } from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import { PiCaretDoubleRight } from "react-icons/pi";

import { useNavigate } from 'react-router-dom';

//import firebase functions for team
import { db } from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";

const Team = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        // executes and fetches data based on query
        const teamSnap = await getDocs(query(collection(db, "team")));
        // set forum category data array for display
        setTeamMembers(teamSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
    };

    const clickRedirect = (teamMemberDetails) => {
        navigate("/our-team/details", { state: teamMemberDetails });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Navigation />
            <section className="team-pic min-h-[600px] drop-shadow-lg p-10 pb-40 grid items-end justify-items-center text-left">
                <div className="container text-7xl text-white">Our Team</div>
            </section>
            <section className="grid justify-items-center">
                <div className="container grid md:grid-cols-2 gap-10 bg-white -mt-20 drop-shadow-md p-10 md:p-20 text-left bg-[#8ED8F842]">
                    <div className="pt-10">
                        <div className="text-3xl text-blue-700">Connect Eurobodalla Team</div>
                        <div className="text-xl text-blue-700 pt-5">The Connect Eurobodalla Team is dedicated to fostering community connections and providing innovative solutions that enhance the well-being and accessibility of services for residents in the Eurobodalla region. Our team is committed to supporting the local community with collaborative and impactful initiatives.</div>
                    </div>
                    {teamMembers.map((memberData, index) => 
                        <div className="container bg-gray-300 p-10 rounded-3xl cursor-pointer flex flex-col-reverse flex-col sm:flex-row" onClick={() => {clickRedirect(memberData)}}>
                            <div className="sm:w-[60%] w-full float-left pl-5 sm:pl-0 sm:pr-5">
                                <div className="text-xl font-bold inline-flex items-center capitalize">{memberData.first_name} {memberData.last_name} <PiCaretDoubleRight/></div><br />
                                <div className="text-xl pt-2 pb-2 font-bold inline-flex items-center text-[#1C75BC]">{memberData.role}</div>
                                <div className="text-xl">{memberData.description.slice(0, 200 - 1) + "..." }</div>
                            </div>
                            <div className={`sm:w-[40%] w-full sm:h-[100%] h-100 pb-5 sm:pb-0 my-0 mx-auto`}>
                                <img className="object-cover w-48 h-48 object-center rounded-full" src={memberData.image_url} alt={`Team Member ${index}`} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-[#00a5e580] h-1.5 w-4/5 self-end my-20"></div>
            </section>
            <Footer />
        </div>
    )
}

export default Team