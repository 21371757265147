import React, { useCallback, useEffect, useState, useContext } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import RightSideBar from "../../components/RightSideBar";
import CommentCard from "../../components/forms/CommentCard";
import { LiaSpinnerSolid } from "react-icons/lia";
import { MdErrorOutline } from "react-icons/md";
import { IoMdAlert, IoIosArrowBack } from "react-icons/io";
import inputHook from "../../hooks/inputHook";
import MessageModal from "../../components/modals/public/MessageModal";

import { useLocation, useNavigate } from "react-router-dom";

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

//import firebase functions for forum discussion comments
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  limit,
  orderBy,
  startAfter,
  limitToLast,
  endBefore,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  increment,
} from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const CommunityForumDiscussion = () => {
  const [loading, setLoading] = useState(true);
  const [addComment, setAddComment] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [textAreaFocus, setTextAreaFocus] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const discussionDetails = location.state;

  const [forumDiscussionComments, setForumDiscussionComments] = useState();
  const [prevSnap, setPrevSnap] = useState();
  const [totalPages, setTotalPages] = useState(0);

  const auth = useContext(AuthContext);
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const fetchData = async (paginateNext = null) => {
    const queryArray = [where("discussion_id", "==", discussionDetails.id)];
    // pagination for forum discussion comments
    prevSnap
      ? paginateNext
        ? queryArray.push(limit(10), startAfter(prevSnap))
        : queryArray.push(limitToLast(10), endBefore(prevSnap))
      : queryArray.push(limit(10));
    // creates query for forum discussion comments pagination or filtering
    const forumDiscussionCommentsQuery = query(
      collection(db, "forum_discussion_comments"),
      orderBy("date", "desc"),
      ...queryArray
    );
    // executes and fetches data based on query
    const forumDiscussionCommentsSnap = await getDocs(
      forumDiscussionCommentsQuery
    );
    // pagination number
    const forumDiscussionCommentsCount = await getCountFromServer(
      query(collection(db, "forum_discussion_comments"))
    );
    setTotalPages(Math.ceil(forumDiscussionCommentsCount.data().count / 10));
    // push paginated array to original array state
    paginateNext
      ? setForumDiscussionComments((comments) =>
          comments.concat(
            forumDiscussionCommentsSnap.docs.map((d) => ({
              id: d.id,
              ...d.data(),
            }))
          )
        )
      : setForumDiscussionComments(
          forumDiscussionCommentsSnap.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          }))
        );
    setPrevSnap(
      forumDiscussionCommentsSnap.docs[
        forumDiscussionCommentsSnap.docs.length - 1
      ]
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInsertNode = useCallback(
    async (input) => {
      const commentInputData = {
        comment: input,
        user_id: auth.userId,
        discussion_id: discussionDetails.id,
        date: new Date().getTime(),
        user: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          profile_image_url: userData.profile_image_url || "",
        },
      };

      const { id } = await addDoc(
        collection(db, "forum_discussion_comments"),
        commentInputData
      );
      await updateDoc(doc(db, "forum_discussions", discussionDetails.id), {
        comments_count: increment(1),
        latest_post: new Date().getTime(),
      });
      commentInputData.id = id;
      setForumDiscussionComments((comments) => [commentInputData, ...comments]);
      setTextArea("");
      setTextAreaFocus(false);
    },
    [forumDiscussionComments]
  );

  const handleEditNode = useCallback(
    async (id, input) => {
      await updateDoc(doc(db, "forum_discussion_comments", id), {
        comment: input,
      });
      setTextArea("");
    },
    [forumDiscussionComments]
  );

  const handleDeleteNode = useCallback(
    async (id) => {
      await deleteDoc(doc(db, "forum_discussion_comments", id));
      await updateDoc(doc(db, "forum_discussions", discussionDetails.id), {
        comments_count: increment(-1),
        latest_post: new Date().getTime(),
      });
    },
    [forumDiscussionComments]
  );

  const handleOnChange = (e) => {
    setTextArea(e.target.value);
  };

  const modalFunction = (e) => {
    setTextArea("");
    setAddComment(false);
  };

  const handleViewMore = (paginateNext) => {
    fetchData(paginateNext);
  };

  const checkIfBlocked = () => {
    let result = false;
    if (discussionDetails.blocked) {
      if (discussionDetails.blocked.includes(auth.userId)) {
        result = true;
      }
    }

    return result;
  };

  return (
    <div>
      <Navigation />
      <RightSideBar title="Community Forum">
        {checkIfBlocked() ? (
          <div className="py-10 text-3xl font-bold">
            You have been blocked in this discussion.
          </div>
        ) : (
          <>
            <div className="pt-2 mb-10">
              <div
                className="text-xl mb-6 text-blue-500 underline text-left flex items-center cursor-pointer w-80"
                onClick={() => navigate(-1)}
              >
                <IoIosArrowBack /> BACK TO PREVIOUS PAGE
              </div>
              <div className="text-3xl mb-5 font-bold text-blue-500 capitalize">
                {discussionDetails.title}
              </div>
              <div className="flex gap-10">
                <div className="w-11/12">
                  <div className="text-base font-bold mb-2">
                    CREATED BY: {discussionDetails.created_by}
                  </div>
                  <div className="text-base font-bold mb-5">
                    POSTED ON:{" "}
                    {moment(discussionDetails.date).format("DD MMM YYYY")}
                  </div>
                  <div className="text-xl whitespace-pre-line">
                    {discussionDetails.body}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`form border-[1px] rounded-2xl grid gap-2 focus-within:ring-2 focus-within:ring-inset ${
                  inputHook(textArea).hasError && textAreaFocus
                    ? "focus-within:ring-red-600 border-red-600"
                    : "focus-within:ring-indigo-600 border-gray-300"
                } p-5`}
              >
                <textarea
                  name="comment"
                  className="font-medium w-full border-transparent focus:outline-none focus-within:ring-transparent overflow-y-scroll"
                  placeholder="Add a comment"
                  rows="2"
                  onClick={() => setAddComment(true)}
                  onBlur={() => setTextAreaFocus(true)}
                  value={textArea}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                ></textarea>
                {addComment && (
                  <div className="flex justify-self-end">
                    <button
                      onClick={() => {
                        textArea === ""
                          ? setAddComment(false)
                          : setDisplayModal(true);
                        setTextAreaFocus(false);
                      }}
                      className="mr-2 text-center rounded-lg bg-white border-2 border-black px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]"
                      type="button"
                    >
                      CANCEL
                    </button>
                    <button
                      onClick={() =>
                        !inputHook(textArea).hasError &&
                        handleInsertNode(textArea)
                      }
                      className="text-center rounded-lg bg-[#F1BD4E] border-2 border-[#F1BD4E] px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]"
                      type="button"
                    >
                      SAVE
                    </button>
                  </div>
                )}
              </div>
              {inputHook(textArea).hasError && textAreaFocus && (
                <p className="my-2 text-red-600 inline-flex items-center">
                  <MdErrorOutline /> The field is required and cannot be empty
                </p>
              )}
            </div>
            <div className="bg-[#00A5E55E] w-auto sm:w-full py-10 px-5 sm:p-10 my-10 mx-[-2.5rem] sm:mx-0">
              <div className="text-xl font-bold text-blue-500">COMMENTS</div>
              <div className="max-h-[800px] min-h-[100px] overflow-y-scroll">
                {loading ? (
                  <div className="animate-pulse">
                    <div className="inline-flex items-center text-white font-bold text-2xl m-5 justify-center w-full">
                      <LiaSpinnerSolid className="animate-spin h-10 w-10 mr-3" />
                      Loading...
                    </div>
                    <div className="flex my-5 gap-5">
                      <div className="w-[12%]">
                        <div className="h-[90px] w-[90px] rounded-full border-4 border-white bg-gray-200" />
                      </div>
                      <div className="bg-[#FFFFFF] rounded-xl p-5 w-[88%] grid gap-2">
                        <div className="grid grid-cols-2">
                          <div className="h-4 bg-gray-200 rounded-full w-[80%]"></div>
                          <div className="flex gap-5 justify-self-end">
                            <div className="h-4 bg-gray-200 rounded-full w-[100px]"></div>
                            <div className="h-4 bg-gray-200 rounded-full w-[100px]"></div>
                          </div>
                        </div>
                        <div className="h-4 bg-gray-200 rounded-full w-[30%]"></div>
                        <div className="mt-5 w-full bg-gray-200 h-4 rounded-full"></div>
                        <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {forumDiscussionComments?.map((comment) => (
                      <CommentCard
                        commentData={comment}
                        handleEditNode={handleEditNode}
                        handleDeleteNode={handleDeleteNode}
                        subComment={false}
                        discussionId={discussionDetails.id}
                      />
                    ))}
                    {forumDiscussionComments.length <= 0 && (
                      <div className="mt-5 text-left text-2xl font-bold text-gray-500 flex items-center">
                        <IoMdAlert className="mr-2 text-red-500" /> No comments
                        available.
                      </div>
                    )}
                    {forumDiscussionComments.length >= 9 && (
                      <button
                        onClick={() => {
                          handleViewMore(true);
                        }}
                        type="button"
                        className="w-full text-center rounded-lg bg-[#F1BD4E] border-2 border-[#F1BD4E] px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]"
                      >
                        View More...
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <MessageModal
              title={"Discard Comment?"}
              description={
                "You have a comment in progress, are you sure you want to discard it?"
              }
              buttonText={"Discard"}
              approve={false}
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
              functionPassed={modalFunction}
            />
          </>
        )}
      </RightSideBar>
      <Footer />
    </div>
  );
};

export default CommunityForumDiscussion;
