// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyBYG-ALG6teQPd1nPJyWdYX8aKrlUI18rE",
//   authDomain: "connect-eurobodalla-63066.firebaseapp.com",
//   projectId: "connect-eurobodalla-63066",
//   storageBucket: "connect-eurobodalla-63066.firebasestorage.app",
//   messagingSenderId: "425027774545",
//   appId: "1:425027774545:web:2abeaed648762ff68b7070",
//   measurementId: "G-WG4FE3GMZ8",
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// const firebaseConfig = {
//   apiKey: "AIzaSyClSIr-ar222_7MSpxhDCUQa6OIBnSAmxg",
//   authDomain: "connect-eurobodalla-dev.firebaseapp.com",
//   databaseURL: "https://connect-eurobodalla-dev-default-rtdb.firebaseio.com",
//   projectId: "connect-eurobodalla-dev",
//   storageBucket: "connect-eurobodalla-dev.firebasestorage.app",
//   messagingSenderId: "816533594124",
//   appId: "1:816533594124:web:33b2a87161bb4bd95dce29",
//   measurementId: "G-GL30213CJQ"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Export auth, db firebase functions
export { auth, database, db, storage };
