import React from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <div>
      <Navigation />
      <section className="banner-pic drop-shadow-lg pt-10 grid justify-items-center">
        <div className="container p-10 pt-24 md:p-0 md:pt-24">
          <div className="text-left px-2 grid gap-5">
            <div className="text-5xl md:text-7xl text-white">
              Welcome to <br />
              Connect Eurobodalla
            </div>
            <div className="text-2xl text-white pt-10 italic">
              Sign in or register to access your <br />
              personalized Connect Eurobodalla portal.
            </div>
            <div className="text-2xl text-white pt-10">
              <a
                href="/login"
                className="pr-3 underline font-bold underline-offset-[20px] decoration-[5px] decoration-[#00a5e580]"
              >
                Sign in
              </a>{" "}
              or{" "}
              <a
                href="/registration"
                className="pl-3 underline font-bold underline-offset-[20px] decoration-[5px] decoration-[#00a5e580]"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="grid justify-items-center">
        <div className="container bg-white mt-[-300px] drop-shadow-md mb-[100px] p-10 rounded-3xl">
          <div className="text-4xl font-bold">
            Sign in for easy access to resources
          </div>
          <div className="grid md:grid-cols-2 pt-10">
            <div className="grid justify-items-center">
              <div className="text-2xl font-bold">Community Forum</div>
              <div className="text-2xl font-light pt-5 ml-10 mr-10">
                Join and participate in conversations with other community
                members. Share ideas, ask questions, and connect with others on
                topics that matter to you.
              </div>
              <div className="bg-[#00a5e580] h-1.5 w-1/2 self-end m-5"></div>
            </div>
            <div className="grid justify-items-center">
              <div className="text-2xl font-bold">Community Directory</div>
              <div className="text-2xl font-light pt-5 ml-10 mr-10">
                Find and connect with local businesses, services, and community
                members. Browse through the directory to discover resources and
                contacts in the Eurobodalla area.
              </div>
              <div className="bg-[#00a5e580] h-1.5 w-1/2 self-end m-5"></div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 pt-10">
            <div className="grid justify-items-center">
              <div className="text-2xl font-bold">Announcements</div>
              <div className="text-2xl font-light pt-5 ml-10 mr-10">
                Stay informed with the latest updates and important information.
                Access announcements relevant to the community and never miss
                out on key news.
              </div>
              <div className="bg-[#00a5e580] h-1.5 w-1/2 self-end m-5"></div>
            </div>
            <div className="grid justify-items-center">
              <div className="text-2xl font-bold">Community Events</div>
              <div className="text-2xl font-light pt-5 ml-10 mr-10">
                Explore and participate in upcoming community events. Keep track
                of local activities and see how you can get involved.
              </div>
              <div className="bg-[#00a5e580] h-1.5 w-1/2 self-end m-5"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission-pic grid justify-items-center">
        <div className="container grid justify-items-end">
          <div className="md:w-1/2 text-left grid gap-5 bg-[#0f75bcc4] self-center p-16 sm:p-20 rounded-3xl">
            <div className="text-7xl text-white">Our Mission</div>
            <div className="text-2xl text-white pt-10 italic">
              To provide a safe platform for a range of services and
              opportunities for community groups in the Eurobodalla to
              communicate, collaborate and share information, events, resources
              and expertise.
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
