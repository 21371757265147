import React from "react";
import PropTypes from "prop-types";
import "./../App.css";

const RightSideBar = (props) => {
  return (
    <div className="md:grid place-items-center">
      <div className="container md:grid gap-3 my-10 text-left">
        <div className="text-4xl mb-5 ml-10 md:ml-0">{props.title}</div>
        <div className="px-10 md:flex md:p-0">
          <div className="md:w-3/4 md:border-t-2 border-gray-200 md:pr-10">
            {props.children}
          </div>
          <div className="md:w-1/4 md:border-l-2 border-t-2 border-gray-200 p-5 grid">
            <div>
              <div className="font-bold text-xl">Quick Links</div>
              <ol className="list-disc list-outside text-xl my-5 ml-5 leading-10">
                <li>
                  <a href="/community-directory" className="text-blue-500">
                    Community Directory
                  </a>
                </li>
                <li>
                  <a href="/community-events" className="text-blue-500">
                    Community Events
                  </a>
                </li>
                <li>
                  <a href="/community-forum" className="text-blue-500">
                    Community Forum
                  </a>
                </li>
              </ol>
            </div>
            <div className="self-end">
              <div className="font-bold text-xl">
                Connect Eurobodalla Support
              </div>
              <div className="text-xl my-5">
                Questions? Contact <br />
                <span className="text-blue-500">
                  admin@thetecexec.com.au
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
