import React, { useEffect, useState } from "react";
import "./../../App.css";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

//import firebase functions for fetching forum members
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  startAfter,
  limitToLast,
  endBefore,
  orderBy,
  getCountFromServer,
  where,
} from "firebase/firestore";

const Members = ({ memberIds }) => {
  const [forumMembers, setForumMembers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState([]);

  const [queryArray, setQueryArray] = useState([limit(10)]);
  const searchQueryArray = [where("id", "in", memberIds)];
  const orderQueryArray = [orderBy("full_name")];

  const fetchData = async () => {
    // creates query for forum members pagination or filtering
    const forumMembersQuery = query(
      collection(db, "users"),
      ...orderQueryArray,
      ...queryArray,
      ...searchQueryArray
    );
    // executes and fetches data based on query
    const forumMembersSnap = await getDocs(forumMembersQuery);
    // set previous snap
    setPrevSnap({next: forumMembersSnap.docs[9], prev: forumMembersSnap.docs[0]});
    // set forum members data array for display
    setForumMembers(
      forumMembersSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
    // pagination number
    const forumMembersCount = await getCountFromServer(
      query(collection(db, "users"), where("id", "in", memberIds))
    );
    setTotalPages(Math.ceil(forumMembersCount.data().count / 10));
    // set loading animations to false
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

  const handlePageChange = (paginateNext) => {
    setQueryArray([limit(10)]);
    // set current page when next or previous is clicked
    if (paginateNext) {
      setCurrentPage((prev) => prev + 1);
      setQueryArray([limit(10), startAfter(prevSnap.next)]);
    } else {
      setCurrentPage((prev) => prev - 1);
      setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
    }
    setLoading(true);
  };

  return (
    <>
      <table className="table-auto w-full mt-5 mb-10">
        <thead>
          <tr>
            <td className="py-4 font-bold text-blue-500 text-xl">NAME</td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <>
              <tr className="animate-pulse">
                <td className="pr-4 py-4 text-base font-medium text-gray-700">
                  <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                </td>
              </tr>
              <tr className="animate-pulse">
                <td className="pr-4 py-4 text-base font-medium text-gray-700">
                  <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                </td>
              </tr>
              <tr className="animate-pulse">
                <td className="pr-4 py-4 text-base font-medium text-gray-700">
                  <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                </td>
              </tr>
            </>
          ) : (
            <>
              {forumMembers.map((forumMembersInfo, index) => (
                <tr>
                  <td className="py-4 text-xl capitalize">
                    {forumMembersInfo.full_name}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {totalPages > 0 && (
        <div className="flex items-center justify-between mt-6">
          <div></div>
          <div className="items-center hidden lg:flex gap-x-3">
            <button
              onClick={() => handlePageChange(false)}
              disabled={currentPage === 1}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
            >
              <FaCaretLeft />
            </button>
            Page {currentPage} of {totalPages}
            <button
              onClick={() => handlePageChange(true)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
            >
              <FaCaretRight />
            </button>
          </div>
          <div></div>
        </div>
      )}
    </>
  );
};

export default Members;
