import React, { useState, useEffect, useRef } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { RxCaretRight } from "react-icons/rx";
import { MdCalendarMonth } from "react-icons/md";
import { IoMdAlert } from "react-icons/io";
import AnnouncementModal from "../../components/modals/user/AnnouncementModal";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";

//import firebase functions for announcement
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  orderBy,
  where,
} from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const Dashboard = () => {
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const [displayModal, setDisplayModal] = useState(true);
  const navigate = useNavigate();

  const [nonPrioAnnouncementData, setNonPrioAnnouncementData] = useState([]);
  const [prioAnnouncementData, setPrioAnnouncementData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState([]);

  const newsRef = useRef();
  // get current url hash of page
  const hash = window.location.hash;

  const fetchData = async () => {
    const prioAnnouncementsSnap = await getDocs(
      query(
        collection(db, "announcements"),
        orderBy("date", "desc"),
        where("priority", "==", "y"),
        limit(3)
      )
    );
    const nonPrioAnnouncementsSnap = await getDocs(
      query(
        collection(db, "announcements"),
        orderBy("date", "desc"),
        where("priority", "==", "n"),
        limit(3)
      )
    );
    setPrioAnnouncementData(
      prioAnnouncementsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
    setNonPrioAnnouncementData(
      nonPrioAnnouncementsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    if (hash) {
      newsRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
    const initClient = () => {
      gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_CALENDAR_SECRET_KEY,
          clientId: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/calendar.readonly",
        })
        .then(() => {
          listUpcomingEvents();
        });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const listUpcomingEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: process.env.REACT_APP_GOOGLE_CALENDAR_ID,
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 5,
        orderBy: "startTime",
      })
      .then((response) => {
        setEvents(response.result.items);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  };

  const clickAnnouncementRedirect = (announcement) => {
    navigate("/announcement-details", { state: announcement });
  };

  return (
    <div>
      <Navigation />
      <section className="dashboard-pic min-h-[600px] drop-shadow-lg pt-10 grid justify-items-center"></section>
      <section className="grid justify-items-center">
        <div className="container grid bg-white/75 mt-[-200px] drop-shadow-md p-10 text-left rounded-3xl">
          <div className="text-4xl font-bold capitalize">
            Hello, {userData.first_name}!
          </div>
          <div className="text-2xl text-blue-500 py-5">
            <a href="/my-profile" className="pr-3 hover:underline">
              View Profile
            </a>
          </div>
          <div className="grid md:grid-cols-2 gap-4 md:w-3/4 place-self-center">
            <div
              className="border-[#0f75bc] border-4 text-2xl font-bold p-5 cursor-pointer"
              onClick={() => navigate("/community-forum")}
            >
              <div className="flex items-center hover:underline">
                Community Forum <RxCaretRight />
              </div>
              <div className="text-xl font-normal py-2">
                Engage with fellow members by discussing important topics and
                sharing insights within the community.
              </div>
            </div>
            <div
              className="border-[#0f75bc] border-4 text-2xl font-bold p-5 cursor-pointer"
              onClick={() => navigate("/announcements")}
            >
              <div className="flex items-center hover:underline">
                Announcements <RxCaretRight />
              </div>
              <div className="text-xl font-normal py-2">
                Engage with fellow members by discussing important topics and
                sharing insights within the community.
              </div>
            </div>
            <div className="border-[#0f75bc] border-4 text-2xl font-bold p-5 cursor-pointer">
              <div className="flex items-center hover:underline">
                Community Events <RxCaretRight />
              </div>
              <div className="text-xl font-normal py-2">
                Engage with fellow members by discussing important topics and
                sharing insights within the community.
              </div>
            </div>
            <div
              className="border-[#0f75bc] border-4 text-2xl font-bold p-5 cursor-pointer"
              onClick={() => navigate("/community-directory")}
            >
              <div className="flex items-center hover:underline">
                Community Directory <RxCaretRight />
              </div>
              <div className="text-xl font-normal py-2">
                Engage with fellow members by discussing important topics and
                sharing insights within the community.
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#00a5e580] h-1.5 w-1/2 self-end m-10"></div>
        <div className="w-full grid justify-items-center gray-bg">
          <div className="container md:flex drop-shadow-md py-10">
            <div className="md:w-1/2 p-10 md:p-0">
              <div ref={newsRef} className="grid text-left gap-3">
                <div className="text-4xl font-bold">Stay Connected!</div>
                <div className="text-2xl font-bold">Latest News</div>
                {loading ? (
                  <div className="grid mt-10 gap-5 animate-pulse">
                    <div className="bg-gray-200 h-40 w-40 md:h-[500px] md:w-full"></div>
                    <div className="w-48 bg-gray-200 h-6 rounded-full"></div>
                    <div className="w-80 bg-gray-200 h-6 rounded-full"></div>
                    <div className="w-full bg-gray-200 h-6 rounded-full"></div>
                    <div className="w-full bg-gray-200 h-6 rounded-full"></div>
                  </div>
                ) : (
                  <>
                    {nonPrioAnnouncementData.length >= 1 && (
                      <div
                        className="grid mt-10 gap-5 cursor-pointer"
                        onClick={() =>
                          clickAnnouncementRedirect(nonPrioAnnouncementData[0])
                        }
                      >
                        <div className="news-pic h-40 w-40 md:h-[500px] md:w-full">
                          <img
                            className="object-cover object-center w-full h-full"
                            src={nonPrioAnnouncementData[0].image_url}
                            alt="News image 1"
                          />
                        </div>
                        <div className="text-2xl font-normal mt-6">
                          {moment(nonPrioAnnouncementData[0].date).format(
                            "DD MMM YYYY"
                          )}
                        </div>
                        <div className="text-2xl font-bold hover:underline capitalize">
                          {nonPrioAnnouncementData[0].title}
                        </div>
                        <div className="text-2xl font-normal">
                          {nonPrioAnnouncementData[0].body.slice(0, 200) +
                            "..."}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="bg-[#000000] h-0.5 w-1/2 self-start my-10"></div>
              {loading == false && nonPrioAnnouncementData.length == 0 && (
                <div className="text-left text-2xl font-bold text-gray-500 flex items-center">
                  <IoMdAlert className="mr-2 text-red-500" /> No
                  news/announcement available.
                </div>
              )}
              <div className="grid md:grid-cols-2 gap-20">
                {loading ? (
                  <>
                    <div className="grid text-left gap-5 animate-pulse">
                      <div className="bg-gray-200 h-40 w-40 md:h-[200px] md:w-full"></div>
                      <div className="w-48 bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </div>
                    <div className="grid text-left gap-5 animate-pulse">
                      <div className="bg-gray-200 h-40 w-40 md:h-[200px] md:w-full"></div>
                      <div className="w-48 bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </div>
                  </>
                ) : (
                  <>
                    {nonPrioAnnouncementData.length >= 2 && (
                      <div
                        className="grid text-left gap-5 cursor-pointer"
                        onClick={() =>
                          clickAnnouncementRedirect(nonPrioAnnouncementData[1])
                        }
                      >
                        <div className="news-pic-small h-40 w-40 md:h-[200px] md:w-full">
                          <img
                            className="object-cover object-center w-full h-full"
                            src={nonPrioAnnouncementData[1].image_url}
                            alt="News image 2"
                          />
                        </div>
                        <div className="text-xl font-normal mt-6">
                          {moment(nonPrioAnnouncementData[1].date).format(
                            "DD MMM YYYY"
                          )}
                        </div>
                        <div className="text-xl font-bold hover:underline capitalize">
                          {nonPrioAnnouncementData[1].title}
                        </div>
                        <div className="text-xl font-normal">
                          {nonPrioAnnouncementData[1].body.slice(0, 100) +
                            "..."}
                        </div>
                      </div>
                    )}
                    {nonPrioAnnouncementData.length >= 3 && (
                      <div
                        className="grid text-left gap-5 cursor-pointer"
                        onClick={() =>
                          clickAnnouncementRedirect(nonPrioAnnouncementData[2])
                        }
                      >
                        <div className="news-pic-small h-40 w-40 md:h-[200px] md:w-full">
                          <img
                            className="object-cover object-center w-full h-full"
                            src={nonPrioAnnouncementData[2].image_url}
                            alt="News image 3"
                          />
                        </div>
                        <div className="text-xl font-normal mt-6">
                          {moment(nonPrioAnnouncementData[2].date).format(
                            "DD MMM YYYY"
                          )}
                        </div>
                        <div className="text-xl font-bold hover:underline capitalize">
                          {nonPrioAnnouncementData[2].title}
                        </div>
                        <div className="text-xl font-normal">
                          {nonPrioAnnouncementData[2].body.slice(0, 100) +
                            "..."}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {nonPrioAnnouncementData.length > 3 && (
                <button
                  onClick={() => navigate("/announcements")}
                  className="mt-5 text-center w-full rounded-full border-2 border-black text-black px-3 py-2 text-base font-bold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
                  type="button"
                >
                  View more news on Eurobodalla Shire Council
                </button>
              )}
            </div>
            {events.length > 0 && (
              <div className="md:w-1/2 grid place-items-center justify-end mt-10 md-0">
                <div className="shadow-sm bg-white/[.90] rounded-[100px] py-20 px-10 grid gap-5 text-left">
                  <div className="text-2xl font-bold">Events</div>
                  {events.map((event) => (
                    <>
                      <div className="flex items-center w-[350px]">
                        <div>
                          <MdCalendarMonth className="text-5xl mr-2 text-[#0f75bc]" />
                        </div>
                        <div className="text-xl uppercase">
                          {moment(event.end.dateTime).format("ddd DD MMM")}
                          <br />
                          {event.summary}
                        </div>
                      </div>
                      <div className="bg-black/[.10] h-1"></div>
                    </>
                  ))}
                  <a
                    href="/community-events"
                    className="mt-5 text-center w-full rounded-full bg-[#87d7f780] text-black px-3 py-2 text-base font-bold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#87d7f780]"
                    type="button"
                  >
                    View Community Events
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <AnnouncementModal
        title={"Latest Announcements"}
        description={
          "These messages highlight opportunities for service, provide details about roles, schedules, and requirements, and inspire action to support impactful initiatives."
        }
        approve={false}
        announcementsData={prioAnnouncementData}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
      />
      <Footer />
    </div>
  );
};

export default Dashboard;
