import React, { useEffect, useMemo, useRef, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import emailjs from "@emailjs/browser";

//import input validation
import inputHook from "../../hooks/inputHook";

//import message modal for success and failed messages
import MessageModal from "../../components/modals/public/MessageModal";

const Contact = () => {
  const form = useRef();
  const [inputTest1, setInputTest1] = useState(Math.round(Math.random() * 9));
  const [inputTest2, setInputTest2] = useState(Math.round(Math.random() * 9));
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [displayFailedModal, setDisplayFailedModal] = useState(false);
  const [displayTestModal, setDisplayTestModal] = useState(false);

  const [inputData, setInputData] = useState({
    from_name: "",
    email: "",
    sec_answer: "",
    message: "",
  });

  const [hasErrors, setHasErrors] = useState({
    from_name: false,
    email: false,
    sec_answer: false,
    message: false,
  });

  useEffect(() => {
    setInputTest1(Math.round(Math.random() * 9));
    setInputTest2(Math.round(Math.random() * 9));
  }, []);

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(e.target.value, e.target.name);
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const disabledSubmit = useMemo(
    () =>
      !(
        !Object.values(inputData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [inputData, hasErrors]
  );

  const sendEmail = (e) => {
    e.preventDefault();
    if (parseInt(inputData.sec_answer) === inputTest1 + inputTest2) {
      emailjs
        .sendForm(
          "service_4hkg72t",
          "template_h1vgg2p",
          form.current,
          "e4sb7oRNAnVtCmDVL"
        )
        .then(
          () => {
            setDisplaySuccessModal(true);
          },
          (error) => {
            setDisplayFailedModal(true);
          }
        );
    } else {
      setDisplayTestModal(true);
    }
  };

  const closeModal = () => {
    setDisplaySuccessModal(false);
  };

  return (
    <div>
      <Navigation />
      <section className="bg-white">
        <div className="container px-6 py-12 mx-auto">
          <div className="lg:flex lg:items-center lg:-mx-6">
            <div className="lg:w-1/2 lg:mx-6 text-left">
              <h1 className="text-4xl font-semibold text-gray-800 capitalize lg:text-15xl">
                Contact us <br />
              </h1>

              <div className="mt-6 space-y-8 md:mt-8">
                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <span className="mx-2 text-2xl text-gray-700 truncate w-100">
                    PO Box 199, Batemans Bay NSW 2536
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>

                  <span className="mx-2 text-2xl text-gray-700 truncate w-72">
                    1300 366 802
                  </span>
                </p>

                <p className="flex items-start -mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-blue-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  <span className="mx-2 text-gray-700 text-2xl truncate w-72">
                    connect@ce.org.au
                  </span>
                </p>
              </div>
            </div>

            <div className="mt-8 lg:w-1/2 lg:mx-6 text-left">
              <div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl lg:max-w-xl shadow-gray-300/50">
                <h1 className="text-lg font-medium text-gray-700">
                  Contact us
                </h1>

                <form className="mt-6" ref={form} onSubmit={sendEmail}>
                  <div className="flex-1">
                    <label className="block mb-2 text-sm text-gray-600">
                      Full name
                    </label>
                    <input
                      type="text"
                      name="from_name"
                      onChange={(e) => onInputChange(e)}
                      value={inputData.from_name}
                      placeholder="Full name"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 ${
                        hasErrors.from_name
                          ? "focus:outline-red-400 focus:ring-red-600"
                          : "focus:ring-indigo-600"
                      }`}
                    />
                  </div>

                  <div className="flex-1 mt-6">
                    <label className="block mb-2 text-sm text-gray-600">
                      Email address
                    </label>
                    <input
                      type="email"
                      name="email"
                      onChange={(e) => onInputChange(e)}
                      value={inputData.email}
                      placeholder="Email"
                      className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 ${
                        hasErrors.email
                          ? "focus:outline-red-400 focus:ring-red-600"
                          : "focus:ring-indigo-600"
                      }`}
                    />
                  </div>

                  <div className="w-full mt-6">
                    <label className="block mb-2 text-sm text-gray-600">
                      Message
                    </label>
                    <textarea
                      className={`block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 ${
                        hasErrors.message
                          ? "focus:outline-red-400 focus:ring-red-600"
                          : "focus:ring-indigo-600"
                      }`}
                      name="message"
                      onChange={(e) => onInputChange(e)}
                      value={inputData.message}
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="w-full mt-6">
                    <label className="block mb-2 text-sm text-gray-600">
                      Security Question
                    </label>
                    <div className="flex gap-2 items-center">
                      <input
                        type="text"
                        name="sec_input_1"
                        value={inputTest1}
                        className="block w-full readyonly px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 read-only:bg-gray-100 bg-white border border-gray-200 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        readOnly={true}
                      />
                      <div className="font-bold">+</div>
                      <input
                        type="text"
                        name="sec_input_2"
                        value={inputTest2}
                        className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 read-only:bg-gray-100 bg-white border border-gray-200 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        readOnly={true}
                      />
                      <div className="font-bold">=</div>
                      <input
                        type="text"
                        name="sec_answer"
                        onChange={(e) => onInputChange(e)}
                        value={inputData.sec_answer}
                        placeholder="Answer here..."
                        className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 ${
                          hasErrors.sec_answer
                            ? "focus:outline-red-400 focus:ring-red-600"
                            : "focus:ring-indigo-600"
                        }`}
                      />
                    </div>
                  </div>

                  <button
                    className={`w-full px-6 py-3 mt-6 text-base font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50  ${
                      disabledSubmit && "cursor-not-allowed"
                    }`}
                    disabled={disabledSubmit}
                    aria-labelledby="sendButtonLabel"
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <MessageModal
          title={"Message sent!"}
          description={
            "Your message has been sent to the website administrators."
          }
          approve={true}
          gif={"success"}
          buttonText={"Close"}
          displayModal={displaySuccessModal}
          setDisplayModal={setDisplaySuccessModal}
          functionPassed={closeModal}
        />
        <MessageModal
          title={"Something went wrong!"}
          description={
            "Your message was not sent to the website administrators."
          }
          approve={false}
          gif={null}
          buttonText={"Close"}
          displayModal={displayFailedModal}
          setDisplayModal={setDisplayFailedModal}
          functionPassed={closeModal}
        />
        <MessageModal
          title={"Your answer was wrong!"}
          description={
            "Your message will not be sent to the website administrators."
          }
          approve={false}
          gif={null}
          buttonText={"Close"}
          displayModal={displayTestModal}
          setDisplayModal={setDisplayTestModal}
          functionPassed={closeModal}
        />
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
