import React, { useEffect, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import RightSideBar from "../../components/RightSideBar";
import { useNavigate } from "react-router-dom";
import {
  FaCaretRight,
  FaCaretLeft,
} from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

//import firebase functions for forum categories
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  getCountFromServer,
  startAfter,
  endBefore,
  orderBy,
  limitToLast,
} from "firebase/firestore";

const CommunityForum = () => {
  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState([]);

  const [loading, setLoading] = useState(true);

  const queryArray = [limit(10)];

  const fetchData = async () => {
    // creates query for forum category pagination or filtering
    const forumCategoryQuery = query(collection(db, "forum_categories"), orderBy("category_name"), ...queryArray);
    // executes and fetches data based on query
    const forumCategorySnap = await getDocs(forumCategoryQuery);
    // set previous snap
    setPrevSnap({next: forumCategorySnap.docs[9], prev: forumCategorySnap.docs[0]});
    // set forum category data array for display
    setCategoriesData(forumCategorySnap.docs.map((d) => ({ id: d.id, ...d.data() })));
    // pagination number
    const forumCategoryCount = await getCountFromServer(query(collection(db, "forum_categories")));
    setTotalPages(Math.ceil(forumCategoryCount.data().count / 10));
    // set loading animations to false
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (paginateNext) => {
    setLoading(true);
    queryArray.length = 0;
    // set current page when next or previous is clicked
    if(paginateNext) {
        setCurrentPage((prev) => prev+1);
        queryArray.push(limit(10), startAfter(prevSnap.next));
    } else {
        setCurrentPage((prev) => prev-1);
        queryArray.push(limitToLast(10), endBefore(prevSnap.prev));
    }
    fetchData();
  }

  const clickRedirect = (category) => {
    navigate("/community-forum/browse", { state: category });
  };

  return (
    <div>
      <Navigation />
      <RightSideBar title="Community Forum">
        <img className="my-10" src={"/assets/user/community-forum.jpeg"} />
        <div className="my-10 text-2xl">
          <div className="font-bold">
            Community Forums are vibrant, member-driven spaces within Connect
            Eurobodalla where individuals from the local community come together
            to share knowledge, ideas, and experiences on a wide range of
            topics, including local development, environmental initiatives,
            community events, and more.
          </div>
          <br />
          These forums empower members to contribute their expertise and
          collaborate on initiatives that benefit the broader community. By
          participating in these discussions, members can connect with others
          who share similar interests, discover new opportunities for
          involvement, and collectively drive positive change in the Eurobodalla
          region. The forums also serve as a resource hub, providing guidance
          and support for community projects, including finding partners,
          securing funding, and accessing essential resources.
          <br />
          <br />
          Anyone who wants to actively engage and contribute to the well-being
          of the community is welcome to join the forums. Members can start
          discussions, join ongoing conversations, and even initiate
          community-led projects to address local needs.
        </div>
        <div className="bg-black/[.10] h-1.5 w-1/2 self-end my-10"></div>
        <div className="text-4xl font-bold my-10">
          Connect Eurobodalla Forum Categories
        </div>
        <div className="my-10">
          {loading ? (
            <div className="animate-pulse">
              <div className="mb-5 flex gap-2">
                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
              </div>
              <div className="mb-5 flex gap-2">
                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
              </div>
              <div className="mb-5 flex gap-2">
                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
              </div>
            </div>
          ) : (
            <>
              {categoriesData.map((category, index) => (
                <div className="mb-5 flex gap-2">
                  <div
                    onClick={() => clickRedirect(category)}
                    className="text-2xl text-blue-500 cursor-pointer hover:underline capitalize"
                  >
                    {category.category_name}
                  </div>
                </div>
              ))}
            </>
          )}
          {loading == false && categoriesData.length == 0 && (
            <div className="text-left text-2xl font-bold text-gray-500 flex items-center">
              <IoMdAlert className="mr-2 text-red-500" /> No forum category available.
            </div>
          )}
          {totalPages > 0 && (
            <div className="flex items-center justify-between mt-6">
              <div></div>
              <div className="items-center hidden lg:flex gap-x-3">
                <button
                  onClick={() => handlePageChange(false)}
                  disabled={currentPage === 1}
                  className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                >
                  <FaCaretLeft />
                </button>
                Page {currentPage} of {totalPages}
                <button
                  onClick={() => handlePageChange(true)}
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                >
                  <FaCaretRight />
                </button>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </RightSideBar>
      <Footer />
    </div>
  );
};

export default CommunityForum;
