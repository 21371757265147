import React, { useEffect, useState } from "react";
import './../../App.css';
import inputHook from "../../hooks/inputHook";
import { MdErrorOutline } from "react-icons/md";
import MessageModal from "./../modals/public/MessageModal";

const CommentInputArea = ({commentInputVisible, setCommentInputVisible, commentData, handleInsertNode, handleEditNode, commentEdit, setCommentEdit}) => {
    const [textArea, setTextArea] = useState('');
    const [textAreaFocus, setTextAreaFocus] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const onAddComment = () => {
        handleInsertNode(textArea);
        setTextArea('');
        setTextAreaFocus(false);
        setCommentInputVisible(false);
    };

    const onEditComment = () => {
        handleEditNode(commentData.id, textArea);
        commentData.comment = textArea;
        setTextArea('');
        setTextAreaFocus(false);
        setCommentInputVisible(false);
    };

    const modalFunction = (e) => {
        setCommentEdit(false);
        setTextArea('');
        setCommentInputVisible(false);
    }

    useEffect(() => {
        commentEdit && setTextArea(commentData.comment);
    }, [commentEdit, commentData])

    return (
        <>
        {commentInputVisible &&
            <>
            <div className={`form border-[1px] rounded-2xl grid gap-2 focus-within:ring-2 focus-within:ring-inset ${(inputHook(textArea).hasError && textAreaFocus) ? "focus-within:ring-red-600 border-red-600" : "focus-within:ring-indigo-600 border-gray-300"} p-5`}>
                <textarea name="comment" className="font-medium w-full border-transparent focus:outline-none focus-within:ring-transparent overflow-y-scroll" placeholder="Add a comment" rows="2" value={textArea} 
                    onBlur={() => setTextAreaFocus(true)}
                    onChange={(e) => setTextArea(e.target.value)}></textarea>
                <div className="flex justify-self-end">
                    <button onClick={() => {textArea === "" ? setCommentInputVisible(false) : setDisplayModal(true); setTextAreaFocus(false); }} type="button" className="mr-2 text-center rounded-lg bg-white border-2 border-black px-1 sm:px-3 sm:py-2 sm:text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]">CANCEL</button>
                    <button onClick={() => { if(!inputHook(textArea).hasError && textAreaFocus) { commentEdit ? onEditComment() : onAddComment() }}} type="button" className="text-center rounded-lg bg-[#F1BD4E] border-2 border-[#F1BD4E] px-1 sm:px-3 sm:py-2 sm:text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]">SAVE</button>
                </div>
            </div>
            {(inputHook(textArea).hasError && textAreaFocus) && <p className="my-2 text-red-600 inline-flex items-center"><MdErrorOutline /> The field is required and cannot be empty</p>}
            <MessageModal title={'Discard Comment?'} description={'You have a comment in progress, are you sure you want to discard it?'} buttonText={'Discard'} approve={false} displayModal={displayModal} setDisplayModal={setDisplayModal} functionPassed={modalFunction} />
            </>
        }
        </>
    )
}

export default CommentInputArea