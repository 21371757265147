import React, { useState, useMemo, useEffect } from "react";
import "./../../../App.css";

//import input validation
import inputHook from "../../../hooks/inputHook";

//import firebase functions for forum groups creation
import { db, storage } from "../../../firebase";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  getDocs,
  query,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { AiOutlineLoading } from "react-icons/ai";

const ForumGroupFormModal = ({
  createForumGroup,
  forumGroupData,
  setForumGroupData,
  displayModal,
  setDisplayModal,
  setLoadingTable,
  displaySuccessModal,
}) => {
  const [categoriesData, setCategoriesData] = useState([]);

  const [hasErrors, setHasErrors] = useState({
    title: false,
    body: false,
    category_id: false,
    status: false,
    date: false,
  });

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(e.target.value, e.target.name);
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });

    setForumGroupData({
      ...forumGroupData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchData = async () => {
    const forumCategorySnap = await getDocs(
      query(collection(db, "forum_categories"))
    );
    setCategoriesData(
      forumCategorySnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const disabledSubmit = useMemo(
    () =>
      !(
        forumGroupData.length !== 0 &&
        !Object.values(forumGroupData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [forumGroupData, hasErrors]
  );

  const [loading, setLoading] = useState(false);

  //executes creation or update of forum groups
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const imageFile = e.target[0].files[0];
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const imageName = array[0];

    if (imageFile) {
      await uploadBytes(
        ref(storage, `forum-group/images/${imageName}.jpg`),
        imageFile
      ).then(async () => {
        if (forumGroupData.image_url) {
          await deleteObject(ref(storage, forumGroupData.image_url)).catch(
            (err) => {
              console.log(err);
            }
          );
        }
      });
      const url = await getDownloadURL(
        ref(storage, `forum-group/images/${imageName}.jpg`)
      )
        .then((url) => {
          return url;
        })
        .catch((err) => {
          return;
        });
      forumGroupData.image_url = url;
      e.target[0].value = "";
    }
    forumGroupData.category_name = categoriesData.find(
      (x) => x.id === forumGroupData.category_id
    ).category_name;
    forumGroupData.title = forumGroupData.title.toLowerCase();
    createForumGroup
      ? await addDoc(collection(db, "forum_groups"), forumGroupData)
      : await setDoc(
          doc(db, "forum_groups", forumGroupData.id),
          forumGroupData
        );
    setForumGroupData({});
    setDisplayModal(false);
    setLoadingTable(true);
    setTimeout(() => {
      setLoading(false);
      displaySuccessModal(true);
    }, 300);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${
          displayModal
            ? "opacity-100 ease-out duration-300"
            : "ease-in duration-300"
        }`}
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      ></div>
      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto"
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${
              !loading && "sm:my-8 sm:w-full sm:max-w-lg"
            } ${
              displayModal
                ? "opacity-100 translate-y-0 sm:scale-100"
                : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="lg:flex lg:items-start">
                {loading ? (
                  <AiOutlineLoading className="animate-spin mb-2 text-4xl bold" />
                ) : (
                  <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left flex-1">
                    <h2
                      className="text-2xl font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {createForumGroup
                        ? "Create new forum group"
                        : "Edit forum group"}
                    </h2>
                    <div className="mt-2 mb-5">
                      <p className="text-sm text-gray-500">
                        <span className="text-red-500">*</span> Fill up required
                        fields.
                      </p>
                    </div>
                    <form className="grid gap-4" onSubmit={submitForm}>
                      <div className="text-base grid gap-2">
                        <div>
                          <span className="font-bold">Upload Image</span>
                          <br />
                          <span className="text-sm text-gray-500">
                            Forum Group Photo
                          </span>
                          <input
                            type="file"
                            accept="image/*"
                            className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                          />
                        </div>
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Title <span className="text-red-500">*</span>
                        </span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.title
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2 capitalize`}
                          name="title"
                          placeholder="Title"
                          value={
                            forumGroupData.title ? forumGroupData.title : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.title && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Title is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Body <span className="text-red-500">*</span>
                        </span>
                        <textarea
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.body
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          id="textarea"
                          name="body"
                          placeholder="Enter body of content here..."
                          required
                          value={forumGroupData.body ? forumGroupData.body : ""}
                          onChange={(e) => onInputChange(e)}
                        ></textarea>
                        {hasErrors.body && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Body is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Category <span className="text-red-500">*</span>
                        </span>
                        <select
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.category_id
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="category_id"
                          value={
                            forumGroupData.category_id
                              ? forumGroupData.category_id
                              : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Please select one</option>
                          {categoriesData.map((category) => (
                            <option value={category.id}>
                              {category.category_name}
                            </option>
                          ))}
                        </select>
                        {hasErrors.category_id && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Category is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Status <span className="text-red-500">*</span>
                        </span>
                        <select
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.status
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="status"
                          value={
                            forumGroupData.status ? forumGroupData.status : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Please select one</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                        {hasErrors.status && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Status is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Date <span className="text-red-500">*</span>
                        </span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.date
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="date"
                          type="date"
                          value={forumGroupData.date ? forumGroupData.date : ""}
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.date && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Date is required.
                          </div>
                        )}
                      </div>
                      <div
                        className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}
                      >
                        <button
                          type="submit"
                          onClick={(e) => {}}
                          className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-600 hover:bg-emerald-500 sm:ml-3 sm:w-auto ${
                            disabledSubmit && "cursor-not-allowed"
                          }`}
                          disabled={disabledSubmit}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setDisplayModal(false)}
                          className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForumGroupFormModal;
