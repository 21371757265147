import React, { useEffect, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaPlus,
  FaCaretLeft,
  FaCaretRight,
} from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import MessageModal from "../../components/modals/public/MessageModal";
import AnnouncementFormModal from "../../components/modals/admin/AnnouncementFormModal";
import LeftSideBar from "../../components/LeftSideBar";

//import firebase functions for fetching announcements
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  deleteDoc,
  limit,
  startAfter,
  limitToLast,
  endBefore,
  orderBy,
  getCountFromServer,
  or,
  and,
  where
} from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const AnnouncementManagement = () => {
  const [announcementFormModal, setAnnouncementFormModal] = useState(false);
  const [createAnnouncement, setCreateAnnouncement] = useState(true);
  const [announcementData, setAnnouncementData] = useState({
    title: false,
    body: false,
    priority: false,
    date: false,
    expiry: false,
  });
  const [announcements, setAnnouncements] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState([]);

  const [queryArray, setQueryArray] = useState([limit(10)]);
  const [searchQueryArray, setSearchQueryArray] = useState([where("title", ">=", "")]);
  const [orderQueryArray, setOrderQueryArray] = useState([orderBy("date", "desc")]);

  const fetchData = async () => {
    // creates query for announcements pagination or filtering
    const announcementQuery = query(
      collection(db, "announcements"),
      ...orderQueryArray,
      ...searchQueryArray,
      ...queryArray
    );
    // executes and fetches data based on query
    const announcementsSnap = await getDocs(announcementQuery);
    // set previous snap
    setPrevSnap({next: announcementsSnap.docs[9], prev: announcementsSnap.docs[0]});
    // set announcements data array for display
    setAnnouncements(
      announcementsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
    // pagination number
    const announcementsCount = await getCountFromServer(
      query(collection(db, "announcements"), ...searchQueryArray)
    );
    setTotalPages(Math.ceil(announcementsCount.data().count / 10));
    // set loading animations to false
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

  //displays modal and sets announcement selected for deletion
  const onDelete = (announcementData) => {
    setAnnouncementData(announcementData);
    setDeleteModal(true);
    setDeleteMessage(true);
  };

  //displays modal and sets announcement selected for editing
  const onEdit = (announcementData) => {
    setAnnouncementData(announcementData);
    setCreateAnnouncement(false);
    setAnnouncementFormModal(true);
    setDeleteMessage(false);
  };

  const modalFunction = async () => {
    // deletes announcement document based on id
    await deleteDoc(doc(db, "announcements", announcementData.id));
    setDisplaySuccessModal(true);
    setLoading(true);
  };

  const modalClose = () => {
    setDeleteMessage(false);
  };

  const handlePageChange = (paginateNext) => {
    setQueryArray([limit(10)]);
    // set current page when next or previous is clicked
    if (paginateNext) {
      setCurrentPage((prev) => prev + 1);
      setQueryArray([limit(10), startAfter(prevSnap.next)]);
    } else {
      setCurrentPage((prev) => prev - 1);
      setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
    }
    setLoading(true);
  };

  const handleSearchQueries = (searchKey) => {
    setSearchQueryArray([where("title", ">=", "")]);
    setQueryArray([limit(10)]);

    searchKey &&
        setSearchQueryArray([
          or(
            and(
                where("title", ">=", searchKey),
                where("title", "<=", searchKey + "\uf8ff")
            )
          )
        ]);
    
    setCurrentPage(1);
    setLoading(true);
  };

  const handleFilters = (e) => {
    setOrderQueryArray([orderBy("date", "desc")]);
    setQueryArray([limit(10)]);

    if(e.target.value === "desc") {
      setOrderQueryArray([orderBy("date", "desc")]);
    } else if (e.target.value === "asc") {
      setOrderQueryArray([orderBy("date", "asc")]);
    } else if (e.target.value === "priority") {
      setOrderQueryArray([orderBy("priority", "desc")]);
    }

    setCurrentPage(1);
    setLoading(true);
  }

  return (
    <div>
      <Navigation />
      <LeftSideBar>
        <div className="grid place-items-center">
          <div className="container text-left my-10">
            <div className="text-4xl">Announcement Management</div>
            <div className="relative flex items-center pt-6 md:mt-0">
              <button
                  onClick={() => {
                    setAnnouncementFormModal(true);
                    setCreateAnnouncement(true);
                    setAnnouncementData({title: "", body: "", priority: "", date: "", expiry: ""});
                    setDeleteMessage(false);
                  }}
                  className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-800 hover:bg-emerald-700 sm:w-auto flex items-center gap-2"
                >
                  <FaPlus /> New announcement
              </button>
            </div>
            <div className="mt-6 md:flex md:items-center md:justify-between">
              <select onChange={(e) => handleFilters(e)} className="border border-gray-600 text-base rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-auto p-2.5" name="filter" aria-label="Sort">
                  <option value="desc">Newest</option>
                  <option value="asc">Oldest</option>
                  <option value="priority">Priority</option>
              </select>
              <div className="relative flex items-center md:mt-0">
                <span className="absolute">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mx-3 text-gray-400"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                    </svg>
                </span>

                <input
                    type="text"
                    placeholder="Search by title"
                    onChange={(e) =>
                        handleSearchQueries(e.target.value.toLowerCase())
                    }
                    className="block py-1.5 pr-5 text-gray-700 bg-white border border-gray-600 rounded-lg md:w-80 placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="my-10 overflow-x-scroll w-[300px] sm:w-[590px] md:w-full">
              <table className="min-w-min md:min-w-fit divide-y divide-gray-200 md:w-[100%]">
                <thead className="bg-blue-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-4 px-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Title
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Description
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Priority
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Date
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Expiry Date
                    </th>

                    <th
                      scope="col"
                      className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <>
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    announcements.map((announcementInfo, index) => (
                      <tr>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                          {announcementInfo.title}
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          {announcementInfo.body.slice(0, 50 - 1) + "..."}
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          {announcementInfo.priority == "y" ? "Yes" : "No"}
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          {moment(announcementInfo.date).format("DD MMM YYYY")}
                        </td>
                        <td className="px-4 py-4 text-base font-bold text-red-700 w-[16.6%] break-words">
                          {moment(announcementInfo.expiry).format(
                            "DD MMM YYYY"
                          )}
                        </td>
                        <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                          <div className="flex items-center gap-x-2">
                            <button
                              onClick={(e) => onDelete(announcementInfo)}
                              className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-red-800 transition-colors duration-200 hover:bg-red-600 focus:outline-none" aria-label="Delete"
                            >
                              <span class='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto'>Delete</span>
                              <FaRegTrashAlt id={`${index}`} />
                            </button>
                            <button
                              onClick={(e) => onEdit(announcementInfo)}
                              className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-teal-800 transition-colors duration-200 hover:bg-teal-600 focus:outline-none" aria-label="Edit"
                            >
                              <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">Edit</span>
                              <FaRegEdit />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {announcements.length <= 0 && loading == false && (
                    <tr>
                      <td colSpan={4} className="text-center">
                        <div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center">
                          <IoMdAlert className="mr-2 text-red-500" /> No
                          announcement available.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {totalPages > 0 && loading == false && (
                <div className="flex items-center justify-between mt-6">
                  <div></div>
                  <div className="items-center hidden lg:flex gap-x-3">
                    <button
                      onClick={() => handlePageChange(false)}
                      disabled={currentPage === 1}
                      className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                      aria-label="Next"
                    >
                      <FaCaretLeft />
                    </button>
                    Page {currentPage} of {totalPages}
                    <button
                      onClick={() => handlePageChange(true)}
                      disabled={currentPage === totalPages}
                      className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                      aria-label="Previous"
                    >
                      <FaCaretRight />
                    </button>
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </LeftSideBar>
      <AnnouncementFormModal
        createAnnouncement={createAnnouncement}
        announcementData={announcementData}
        setAnnouncementData={setAnnouncementData}
        displayModal={announcementFormModal}
        setDisplayModal={setAnnouncementFormModal}
        setLoadingTable={setLoading}
        displaySuccessModal={setDisplaySuccessModal}
      />
      <MessageModal
        title={"Delete this announcement?"}
        description={"Are you sure you want to delete this announcement?"}
        buttonText={"Delete"}
        gif={null}
        approve={false}
        displayModal={deleteModal}
        setDisplayModal={setDeleteModal}
        functionPassed={modalFunction}
      />
      <MessageModal 
        title={"Action Successful"} 
        description={`Announcement has been successfully ${deleteMessage ? "deleted" : createAnnouncement ? "created" : "updated"}.`} 
        buttonText={"Close"} 
        gif={"success"} 
        approve={true} 
        displayModal={displaySuccessModal} 
        setDisplayModal={setDisplaySuccessModal} 
        functionPassed={modalClose} />
      <Footer />
    </div>
  );
};

export default AnnouncementManagement;
