import React, { useState, useMemo, useEffect } from "react";
import './../../../App.css';

//import input validation
import inputHook from "../../../hooks/inputHook";

//import firebase functions for forum discussion creation
import { db } from "../../../firebase";
import { addDoc, collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { AiOutlineLoading } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

const ForumDiscussionFormModal = ({ createForumDiscussion, forumDiscussionData, setForumDiscussionData, displayModal, setDisplayModal, setLoadingTable, displaySuccessModal }) => {
    const [forumGroupsData, setForumGroupsData] = useState([]);
    const [forumGroupMembersData, setForumGroupMembersData] = useState([]);
    const [blockedUser, setBlockedUser] = useState();

    const [hasErrors, setHasErrors] = useState({
        title: false,
        body: false,
        group_id: false,
        created_by: false,
        date: false
    });

    //tests user input to input validation requirements
    const onInputChange = (e) => {
        const validation = inputHook(e.target.value, e.target.name);
        setHasErrors({
            ...hasErrors,
            [e.target.name]: validation.hasError
        });
        
        setForumDiscussionData({
            ...forumDiscussionData,
            [e.target.name]: e.target.value
        });
    }

    const onChangeBlockedUsers = (value, remove, setState) => {
        if(value !== "") {
            const index = blockedUser.indexOf(value);
            if (remove) {
            setState((oldValues) => {
                return oldValues.filter((blockedUser) => blockedUser !== value);
            });
            } else {
            index === -1 && setState((prev) => [...prev, value]);
            }
        }
        groupMembersData();
      };

    const fetchData = async () => {
        setBlockedUser(forumDiscussionData.blocked || []);
        const forumGroupSnap = await getDocs(query(collection(db, "forum_groups")));
        setForumGroupsData(forumGroupSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
    }

    const groupMembersData = async () => {
        const member_ids = forumGroupsData.find(x => x.id === forumDiscussionData.group_id)?.members;
        if(member_ids !== undefined) {
            const groupMemberSnap = await getDocs(query(collection(db, "users"), where("id", "in", member_ids)));
            setForumGroupMembersData(groupMemberSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        }
    }

    useEffect(() => {
        fetchData();
        groupMembersData();
    }, [displayModal])

    const disabledSubmit = useMemo(() => !((forumDiscussionData.length !== 0 && !Object.values(forumDiscussionData).some(x => x === null || x === '')) && Object.values(hasErrors).every(x => x === false)), [forumDiscussionData, hasErrors])

    const [loading, setLoading] = useState(false);

    //executes creation or update of forum discussions
    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        forumDiscussionData.blocked = blockedUser;
        forumDiscussionData.group_title = forumGroupsData.find(x => x.id === forumDiscussionData.group_id).title;
        forumDiscussionData.title = forumDiscussionData.title.toLowerCase();
        createForumDiscussion ? await addDoc(collection(db, "forum_discussions"), forumDiscussionData) : await setDoc(doc(db, "forum_discussions", forumDiscussionData.id), forumDiscussionData);
        setForumDiscussionData({});
        setDisplayModal(false);
        setLoadingTable(true);
        setTimeout(() => {setLoading(false); displaySuccessModal(true);}, 300);
    }

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${displayModal ? "opacity-100 ease-out duration-300" : "ease-in duration-300" }`} style={{ visibility: displayModal ? "visible" : "hidden" }}></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto" style={{ visibility: displayModal ? "visible" : "hidden" }}>
                    <div className="flex min-h-full items-end justify-center p-4 items-center sm:p-0">
                        <div className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${loading ? "" : "sm:my-8 sm:w-full sm:max-w-lg"} ${displayModal ? "opacity-100 translate-y-0 sm:scale-100" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }`}>
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="lg:flex lg:items-start">
                                    {loading ?
                                        <AiOutlineLoading className="animate-spin mb-2 text-4xl bold" />
                                    :
                                        <div className="mt-3 sm:mx-4 sm:mt-0 text-left flex-1">
                                            <h2 className="text-2xl font-semibold leading-6 text-gray-900" id="modal-title">{createForumDiscussion ? "Create new forum discussion" : "Edit forum discussion" }</h2>
                                            <div className="mt-2 mb-5">
                                                <p className="text-sm text-gray-500"><span className="text-red-500">*</span> Fill up required fields.</p>
                                            </div>
                                            <form className="grid gap-4" onSubmit={submitForm}>
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Title <span className="text-red-500">*</span></span>
                                                    <input className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.title ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2 capitalize`}
                                                        name="title" 
                                                        placeholder="Title"
                                                        value={(forumDiscussionData.title) ? forumDiscussionData.title : ""}
                                                        onChange={(e) => onInputChange(e)}
                                                        />
                                                    {hasErrors.title &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Title is required.
                                                        </div>
                                                    }
                                                </div>
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Body <span className="text-red-500">*</span></span>
                                                    <textarea className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.body ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                                                        id="textarea"
                                                        name="body"
                                                        required
                                                        value={(forumDiscussionData.body) ? forumDiscussionData.body: ""}
                                                        placeholder="Enter body of content here..."
                                                        onChange={(e) => onInputChange(e)}></textarea>
                                                    {hasErrors.body &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Body is required.
                                                        </div>
                                                    }
                                                </div>
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Group <span className="text-red-500">*</span></span>
                                                    <select className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.group_id ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                                                        name="group_id" 
                                                        value={(forumDiscussionData.group_id) ? forumDiscussionData.group_id : ""}
                                                        onChange={(e) => onInputChange(e)}>
                                                        <option value="">Please select one</option>
                                                        {forumGroupsData.map((group) => 
                                                            <option value={group.id}>{group.title}</option>
                                                        )}
                                                    </select>
                                                    {hasErrors.group_id &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Group is required.
                                                        </div>
                                                    }
                                                </div>
                                                {createForumDiscussion === false && 
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Blocked users</span>
                                                    <select className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.category_id ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                                                        onChange={(e) => onChangeBlockedUsers(e.target.value, false, setBlockedUser)}>
                                                        <option value={""}>Please select one or more</option>
                                                        {forumGroupMembersData.map((member_data) => 
                                                            <option value={member_data.id}>{member_data.full_name}</option>
                                                        )}
                                                    </select>
                                                    <div className="text-base flex gap-2">
                                                        {blockedUser.map((member_id) => (
                                                            <div className="rounded-full bg-gray-200 p-2 capitalize flex hover:bg-gray-300">
                                                            {forumGroupMembersData.find(x => x.id === member_id) ? forumGroupMembersData.find(x => x.id === member_id).full_name : ""}
                                                            <IoMdClose
                                                                className="text-xs cursor-pointer"
                                                                onClick={() =>
                                                                    onChangeBlockedUsers(
                                                                    member_id,
                                                                    true,
                                                                    setBlockedUser
                                                                )
                                                                }
                                                            />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                }
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Created By <span className="text-red-500">*</span></span>
                                                    <input className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.created_by ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                                                        name="created_by" 
                                                        placeholder="Created by"
                                                        value={(forumDiscussionData.created_by) ? forumDiscussionData.created_by : ""}
                                                        onChange={(e) => onInputChange(e)}
                                                        />
                                                    {hasErrors.created_by &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Created by is required.
                                                        </div>
                                                    }
                                                </div>
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Date <span className="text-red-500">*</span></span>
                                                    <input className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.date ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                                                        name="date"
                                                        type="date"
                                                        value={(forumDiscussionData.date) ? forumDiscussionData.date : ""}
                                                        onChange={(e) => onInputChange(e)}
                                                        />
                                                    {hasErrors.date &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Date by is required.
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}>
                                                    <button type="submit" onClick={(e) => {}} className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-600 hover:bg-emerald-500 sm:ml-3 sm:w-auto ${disabledSubmit && "cursor-not-allowed"}`} disabled={disabledSubmit}>Save</button>
                                                    <button type="button" onClick={() => setDisplayModal(false)} className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ForumDiscussionFormModal