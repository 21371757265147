import React from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

const CommunityEvents = () => {
    return (
        <div>
            <Navigation />
            <section className="bg-white">
                <div className="container px-6 py-12 mx-auto">
                    <div className="text-4xl mb-5 ml-10 md:ml-0 text-left">Community Events</div>
                    <div className="border-t-2 pt-10"></div>
                    <iframe src={process.env.REACT_APP_GOOGLE_CALENDAR_EMBED_LINK} 
                            className={`w-full`}
                            height="600" 
                            frameBorder="0" 
                            scrolling="no" 
                            title="Community Events Calendar"></iframe>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default CommunityEvents