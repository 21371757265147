import React from "react";
import PropTypes from "prop-types";
import "./../App.css";
import { AiFillHome } from "react-icons/ai";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { MdPersonSearch } from "react-icons/md";
import { FaUserCog, FaBuilding, FaUsers, FaComments } from "react-icons/fa";

let path = window.location.pathname;

const LeftSideBar = (props) => {
  return (
    <div>
      <div className="md:grid gap-3 text-left">
        <div className="px-10 md:flex md:p-0">
          <div className="md:w-1/5 md:border-r-2 border-gray-200 md:p-5 grid overflow-x-scroll md:overflow-auto">
            <div>
              <ol className="font-medium text-base flex md:block min-w-max md:min-w-fit">
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/admin-dashboard" ? "bg-[#E9F5FE]" : "bg-white"
                  }`}
                >
                  <a
                    href="/admin-dashboard"
                    className={`${
                      path === "/admin-dashboard"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <AiFillHome />
                    Dashboard
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/user-management" ? "bg-[#E9F5FE]" : "bg-white"
                  }`}
                >
                  <a
                    href="/user-management"
                    className={`${
                      path === "/user-management"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <FaUserCog />
                    User Management
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/organization-management"
                      ? "bg-[#E9F5FE]"
                      : "bg-white"
                  }`}
                >
                  <a
                    href="/organization-management"
                    className={`${
                      path === "/organization-management"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <FaBuilding />
                    Organisation Management
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/team-management" ? "bg-[#E9F5FE]" : "bg-white"
                  }`}
                >
                  <a
                    href="/team-management"
                    className={`${
                      path === "/team-management"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <FaUsers />
                    Team Management
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/announcement-management"
                      ? "bg-[#E9F5FE]"
                      : "bg-white"
                  }`}
                >
                  <a
                    href="/announcement-management"
                    className={`${
                      path === "/announcement-management"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <BsFillMegaphoneFill />
                    Announcement Management
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/forum-management" ? "bg-[#E9F5FE]" : "bg-white"
                  }`}
                >
                  <a
                    href="/forum-management"
                    className={`${
                      path === "/forum-management"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <FaComments />
                    Forum Management
                  </a>
                </li>
                <li
                  className={`px-1 py-2 my-2 ${
                    path === "/pending-approvals" ? "bg-[#E9F5FE]" : "bg-white"
                  }`}
                >
                  <a
                    href="/pending-approvals"
                    className={`${
                      path === "/pending-approvals"
                        ? "text-[#4F4F4F]"
                        : "text-[#5D7285]"
                    } flex items-center gap-2`}
                  >
                    <MdPersonSearch />
                    Pending Approvals
                  </a>
                </li>
              </ol>
            </div>
          </div>
          <div className="md:w-4/5 md:pr-10 md:pl-10">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

// Add prop validation
LeftSideBar.propTypes = {
  children: PropTypes.node, // Validate 'children' as a React node (can be a string, element, or array of nodes)
};

// Optional: Add default props
LeftSideBar.defaultProps = {
  children: null, // Default to null if no children are provided
};

export default LeftSideBar;
