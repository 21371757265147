import React, { useEffect, useState } from "react";
import './../../App.css';
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

//import firebase functions for fetching forum discussions
import { db } from "../../firebase";
import { collection, getDocs, query, limit, startAfter, limitToLast, endBefore, orderBy, getCountFromServer, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

//import moment for date formatting
import moment from "moment/moment";

const Discussions = ({forumGroupDetails}) => {
    const navigate = useNavigate();
    const [forumDiscussion, setForumDiscussion] = useState([]);

    const [loading, setLoading] = useState(true);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevSnap, setPrevSnap] = useState([]);

    const [queryArray, setQueryArray] = useState([limit(10)]);
    const searchQueryArray = [where("group_id", "==", forumGroupDetails.id)];
    const orderQueryArray = [orderBy("date", "desc")];

    const fetchData = async () => {
        // creates query for forum discussions pagination or filtering
        const forumDiscussionQuery = query(collection(db, "forum_discussions"), ...orderQueryArray, ...queryArray, ...searchQueryArray);
        // executes and fetches data based on query
        const forumDiscussionSnap = await getDocs(forumDiscussionQuery);
        // set previous snap
        setPrevSnap({next: forumDiscussionSnap.docs[9], prev: forumDiscussionSnap.docs[0]});
        // set forum discussions data array for display
        setForumDiscussion(forumDiscussionSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        // pagination number
        const forumDiscussionCount = await getCountFromServer(query(collection(db, "forum_discussions"), where("group_id", "==", forumGroupDetails.id)));
        setTotalPages(Math.ceil(forumDiscussionCount.data().count / 10));
        // set loading animations to false
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [loading]);

    const handlePageChange = (paginateNext) => {
        setQueryArray([limit(10)]);

        // set current page when next or previous is clicked
        if(paginateNext) { 
            setCurrentPage((prev) => prev+1);
            setQueryArray([limit(10), startAfter(prevSnap.next)]);
        } else { 
            setCurrentPage((prev) => prev-1);
            setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
        };

        setLoading(true);
    }

    const clickRedirect = (forumDiscussion) => {
        navigate("/community-forum/discussion", { state: forumDiscussion });
    }

    return (
        <>
            <table className="table-auto w-full mt-5 mb-10">
                <thead>
                    <tr>
                        <td className="py-4 font-bold text-blue-500 text-xl">DISCUSSIONS</td>
                        <td className="py-4 font-bold text-blue-500 text-xl">POSTS</td>
                        <td className="py-4 font-bold text-blue-500 text-xl">LAST POST</td>
                    </tr>
                </thead>
                <tbody>
                    {loading ? 
                        <>
                        <tr className="animate-pulse">
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        <tr className="animate-pulse">
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="pr-4 py-4 text-base font-medium text-gray-700">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        </>
                    : 
                        <>
                        {forumDiscussion.map((forumDiscussionInfo, index) => 
                            <tr className="cursor-pointer" onClick={() => clickRedirect(forumDiscussionInfo)}>
                                <td className="py-4 text-xl text-blue-500 hover:underline capitalize">{forumDiscussionInfo.title}</td>
                                <td className="py-4 text-xl">{forumDiscussionInfo.comments_count || 0}</td>
                                <td className="py-4 text-xl">{moment(forumDiscussionInfo.latest_post).format("DD MMM YYYY")}</td>
                            </tr>
                        )}
                        </>
                    }
                </tbody>
            </table>
            {totalPages > 0 &&
                <div className="flex items-center justify-between mt-6">
                    <div></div>
                    <div className="items-center hidden lg:flex gap-x-3">
                        <button onClick={() => handlePageChange(false)} disabled={currentPage === 1} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"><FaCaretLeft /></button>
                        Page {currentPage} of {totalPages}
                        <button onClick={() => handlePageChange(true)} disabled={currentPage === totalPages} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"><FaCaretRight /></button>
                    </div>
                    <div></div>
                </div>
            }
        </>
    )
}

export default Discussions