import React, { useEffect, useState } from "react";
import "./../../App.css";
import { FaCheck } from "react-icons/fa";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";

const SearchableSelect = ({ organization, setOrganization }) => {
  const [activeSelect, setActiveSelect] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchDirData = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "org_data"), orderBy("org_name"))
      );
      const newData = querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
          id: d.id,
          ...data,
          org_name: capitalizeWords(data.org_name || ""),
        };
      });
      setDropdownData(newData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const [selectedData, setSelectedData] = useState({
    org_name: "",
  });

  useEffect(() => {
    fetchDirData();
  }, []);

  const onSearch = (e) => {
    const searchValue = e.target.value;
    const filtered = dropdownData.filter((dataItem) => {
      return Object.values(dataItem)
        .join("")
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
    searchValue !== ""
      ? setDropdownData(filtered)
      : setDropdownData(dropdownData);
    if (searchValue == "") {
      fetchDirData();
    }
  };

  const onSelect = (data) => {
    setSelectedData(data);
    setOrganization({
      ...organization,
      organization: data.org_name,
    });
  };

  return (
    <div className="relative inline-block text-left group z-[5] w-full mt-2">
      <div>
        <button
          type="button"
          onClick={() => setActiveSelect(!activeSelect)}
          className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center"
        >
          {selectedData.org_name != ""
            ? selectedData.org_name
            : "Select organization"}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        className={`${
          activeSelect
            ? "ease-out opacity-100 scale-100"
            : "ease-in opacity-0 scale-90"
        } transition duration-200 w-full`}
      >
        <div
          className={`${
            activeSelect ? "block" : "hidden"
          } absolute w-full left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="p-1" role="none">
            <input
              onChange={(e) => onSearch(e)}
              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 px-2`}
              id="search"
              name="email"
              type="email"
              placeholder="Search by keywords"
            />
          </div>
          {dropdownData.map((data) => (
            <div
              className="py-1 flex items-center hover:bg-gray-100"
              role="none"
            >
              <button
                type="button"
                onClick={() => onSelect(data)}
                className="w-full text-left px-4 py-2 text-sm text-gray-700"
              >
                {data.org_name}
              </button>
              {selectedData.org_name === data.org_name && (
                <FaCheck className="-mx-8 text-emerald-500" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchableSelect;
