import React from "react";
import './../../../App.css';
import { GoAlert } from "react-icons/go";
import { GoCheckCircle } from "react-icons/go";

const ModalNotification = ({ title, description, approve, gif=null, buttonText, displayModalNotification, setDisplayModalNotification, functionPassed }) => {

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${displayModalNotification ? "opacity-100 ease-out duration-300" : "ease-in duration-300" }`} style={{ visibility: displayModalNotification ? "visible" : "hidden" }}></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto" style={{ visibility: displayModalNotification ? "visible" : "hidden" }}>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${displayModalNotification ? "opacity-100 translate-y-0 sm:scale-100" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }`}>
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                {gif === "success" &&
                                    <img src="../assets/success.gif" />
                                }
                                <div className="sm:flex sm:items-start">
                                    {gif !== "success" &&
                                        <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${approve === true ? "bg-emerald-100" : "bg-red-100" } sm:mx-0 sm:h-10 sm:w-10`}>
                                            {approve === true ? <GoCheckCircle className={"h-6 w-6 text-emerald-600"} /> : <GoAlert className={"h-6 w-6 text-red-600"} /> }
                                        </div>
                                    }
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{title}</h3>
                                        <div className="mt-2">
                                        <p className="text-sm text-gray-500">{description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 ${gif === "success" && "content-center"}`}>
                                <button type="button" onClick={() => {functionPassed(); setDisplayModalNotification(false); }} className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm ${gif === "success" || approve === true ? "bg-emerald-600 hover:bg-emerald-500 sm:ml-3" : "bg-red-600 hover:bg-red-500 sm:ml-3"} sm:w-auto`}>{buttonText}</button>
                                {gif !== "success" && <button type="button" onClick={() => setDisplayModalNotification(false)} className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">No</button> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ModalNotification