import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import LeftSideBar from "../../components/LeftSideBar";
import UserTable from "../../components/pending_approvals/UserTable";
import ForumJoinTable from "../../components/pending_approvals/ForumJoinTable";

const PendingApprovals = () => {
  const [showUserTable, setShowUserTable] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const table = params.get("table");
    if (table === "forum") {
      setShowUserTable(false);
    }
  }, [location]);
  return (
    <div>
      <Navigation />
      <LeftSideBar>
        <div className="grid place-items-center">
          <div className="container text-left my-10">
            <div className="text-4xl">Pending Approvals</div>
            <div className="mt-6 md:flex md:items-center md:justify-between">
              <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
                <button
                  onClick={() => setShowUserTable(true)}
                  className={`px-5 py-2 text-xs font-medium text-gray-700 transition-colors duration-200 ${
                    showUserTable === true && "bg-blue-100"
                  } sm:text-sm hover:bg-gray-100`}
                >
                  Registration Request
                </button>

                <button
                  onClick={() => setShowUserTable(false)}
                  className={`px-5 py-2 text-xs font-medium text-gray-700 transition-colors duration-200 sm:text-sm ${
                    showUserTable === false && "bg-blue-100"
                  } hover:bg-gray-100`}
                >
                  Forum Join Request
                </button>
              </div>
            </div>
            <div className="my-10">
              {showUserTable ? <UserTable /> : <ForumJoinTable />}
            </div>
          </div>
        </div>
      </LeftSideBar>
      <Footer />
    </div>
  );
};

export default PendingApprovals;
