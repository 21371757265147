import React, { useEffect, useState } from "react";
import "./../../App.css";
import { useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import UserTable from "../../components/user_management_table/UserTable";
import VolunteerTable from "../../components/user_management_table/VolunteerTable";
import LeftSideBar from "../../components/LeftSideBar";

const UserManagement = () => {
  const [showTable, setShowTable] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const table = params.get("table");
    if (table === "volunteers") {
      setShowTable(false);
    }
  }, [location]);

  return (
    <div>
      <Navigation />
      <LeftSideBar>
        <div className="grid place-items-center">
          <div className="container text-left my-10">
            <div className="text-4xl">User Management</div>
            <div className="mt-6 inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
              <button
                onClick={() => setShowTable(true)}
                className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                  showTable === true && "bg-blue-100"
                } sm:text-sm hover:bg-gray-100`}
              >
                User Management
              </button>

              <button
                onClick={() => setShowTable(false)}
                className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                  showTable === false && "bg-blue-100"
                } sm:text-sm hover:bg-gray-100`}
              >
                Volunteers
              </button>
            </div>
            <div className="my-10">
              {showTable ? <UserTable /> : <VolunteerTable />}
            </div>
          </div>
        </div>
      </LeftSideBar>
      <Footer />
    </div>
  );
};

export default UserManagement;
