import React, { useState, useEffect } from "react";
import "./../../../App.css";
import {
  collection,
  getFirestore,
  getDocs,
  addDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import Modal from "./Modal";
import ModalNotification from "./ModalNotification";
import MessageModal from "../../../components/modals/public/MessageModal";

const OrganisationAddDataFormModal = ({
  displayModalForm,
  setDisplayModalForm,
  orgId,
  refreshTable,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalNotification, setDisplayModalNotification] =
    useState(false);
  const [displayModalMessage, setDisplayModalMessage] = useState(false);

  // Fetch data of organization in firestore
  const [orgCategoryData, setOrgCategoryData] = useState([]);
  const db = getFirestore();
  const [orgCategoryId, setOrgCategoryId] = useState();

  const [orgName, setOrgName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const [title, setTitle] = useState("Discard changes?");
  const [description, setDescription] = useState(
    "Your changes will not be saved, do you wish to continue?"
  );
  const [buttonText, setButtonText] = useState("Yes");
  const [gifAction, setGifAction] = useState("success");

  // show error
  const [orgError, setOrgError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    async function fetchCategory() {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "org_catgry"), orderBy("ctgry_name"))
        );
        const newData = querySnapshot.docs.map((d) => {
          const data = d.data();
          return {
            id: d.id,
            ...data,
            ctgry_name: capitalizeWords(data.ctgry_name || ""),
          };
        });
        setOrgCategoryData(newData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    fetchCategory();
  }, []);

  const handleCategoryChange = (event) => {
    const selectedId = event.target.value;
    setOrgCategoryId(selectedId);
  };

  const handleSubmitOrgData = async (e) => {
    e.preventDefault();
    setDisplayModalNotification(true);
  };

  const ModalFunctionAddOrgData = async (e) => {
    // Validate if user selected a category!
    if (!orgCategoryId || orgCategoryId === "-") {
      setCategoryError("Please select a valid category");
      return;
    }
    try {
      const orgDataRef = collection(db, "org_data");

      // Normalize input
      const normalizedOrgName = orgName
        .trim()
        .replace(/\s+/g, " ")
        .toLowerCase();

      // Query Firestore
      const querySnapshot = await getDocs(
        query(orgDataRef, where("org_name", "==", normalizedOrgName))
      );

      if (!querySnapshot.empty) {
        setFunctionPassed(() => modalFunctionExist);
        setOrgError("Organisation name exists!");
        return;
      }

      // Add new organization
      await addDoc(orgDataRef, {
        org_ctgry_id: orgCategoryId,
        org_name: normalizedOrgName,
        org_add: address.trim().toLowerCase(),
        org_cont_per: contact.trim().toLowerCase(),
        org_cont_num: phoneNum.trim(),
        org_email: email.trim().toLowerCase(),
        org_website: website.trim() || null,
      });

      setDisplayModal(true);
      setOrgCategoryId("");
      setOrgName("");
      setAddress("");
      setContact("");
      setPhoneNum("");
      setEmail("");
      setWebsite("");
    } catch (error) {
      console.error("Error adding organization data:", error);
    }
  };

  const modalFunctionExist = () => {
    setFunctionPassed(() => modalFunctionClose);
    setTitle("Discard changes?");
    setDescription("Your changes will not be saved, do you wish to continue?");
    setGifAction(false);
    setButtonText("Yes");
    setDisplayModalMessage(false);
  };

  const modalFunctionClose = () => {
    setDisplayModalForm(false);
    refreshTable();
  };

  const [functionPassed, setFunctionPassed] = useState(
    () => modalFunctionClose
  );

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${
          displayModalForm
            ? "opacity-100 ease-out duration-300"
            : "ease-in duration-300"
        }`}
        style={{ visibility: displayModalForm ? "visible" : "hidden" }}
      ></div>
      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto"
        style={{ visibility: displayModalForm ? "visible" : "hidden" }}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
              displayModalForm
                ? "opacity-100 translate-y-0 sm:scale-100"
                : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => modalFunctionClose()}
            >
              <span className="sr-only">Close</span>
              &times;{" "}
              {/* Or use an icon like X from Heroicons or FontAwesome */}
            </button>
            {/* Modal Title */}
            <div className="px-6 py-4 border-b border-gray-200">
              <h2
                className="text-lg font-medium text-gray-900"
                id="modal-title"
              >
                New Organisation
              </h2>
            </div>
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm text-left">
                <form className="space-y-6">
                  <div>
                    <label
                      for="selectedCtgryID"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Select Directory
                    </label>
                    <div className="flex items-center space-x-2">
                      {" "}
                      {/* Container with flex layout */}
                      <select
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-red-400 focus:ring-red-600 sm:text-sm sm:leading-6 px-2`}
                        id="selectedCtgryID"
                        onChange={handleCategoryChange}
                        value={orgCategoryId}
                      >
                        <option>-</option>
                        {orgCategoryData.map((category, index) => (
                          <option value={category.id}>
                            {category.ctgry_name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                    {categoryError && (
                      <span className="text-red-500">{categoryError}</span>
                    )}
                  </div>
                  <div>
                    <label
                      for="orgName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Organization Name
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="orgName"
                        name={orgName}
                        value={orgName}
                        onChange={(e) => {
                          setOrgName(e.target.value);
                        }}
                        type="text"
                      />
                      {orgError && (
                        <span className="text-red-500">{orgError}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      for="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <textarea
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="address"
                        name={address}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <label
                      for="phoneNum"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contact Number
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="phoneNum"
                        name={phoneNum}
                        value={phoneNum}
                        onChange={(e) => {
                          setPhoneNum(e.target.value);
                        }}
                        type="number"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="contact"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contact Person
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="contact"
                        name={contact}
                        value={contact}
                        onChange={(e) => {
                          setContact(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="email"
                        name={email}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="website"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Website Link
                    </label>
                    <div className="mt-2">
                      <input
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2`}
                        id="website"
                        name={website}
                        value={website}
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                        placeholder="Optional"
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75 "cursor-not-allowed"}`}
                      type="submit"
                      onClick={handleSubmitOrgData}
                    >
                      Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={title}
        description={description}
        approve={true}
        gif={gifAction}
        buttonText={buttonText}
        displayModal={displayModalMessage}
        setDisplayModal={setDisplayModalMessage}
        functionPassed={functionPassed}
      />
      <ModalNotification
        title={"Please confirm!"}
        description={"Do you confirm this registration?"}
        approve={false}
        buttonText={"Yes"}
        displayModalNotification={displayModalNotification}
        setDisplayModalNotification={setDisplayModalNotification}
        functionPassed={ModalFunctionAddOrgData}
      />
      <MessageModal
        title={"Action Successful."}
        description={"Organisation has been successfully created."}
        buttonText={"Close"}
        gif={"success"}
        approve={true}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        functionPassed={modalFunctionClose}
      />
    </div>
  );
};

export default OrganisationAddDataFormModal;
