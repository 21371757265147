import React from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import RightSideBar from '../../components/RightSideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

//import moment for date formatting
import moment from "moment/moment";

const AnnouncementDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const announcementDetail = location.state;
    return (
        <div>
            <Navigation />
            <RightSideBar title="Announcements">
                <div className="text-xl mb-6 mt-6 text-blue-500 underline text-left flex items-center cursor-pointer w-80" onClick={() => navigate(-1)}><IoIosArrowBack /> BACK TO PREVIOUS PAGE</div>
                <img className="my-10" src={announcementDetail.image_url} />
                <div className="my-10 text-2xl">
                    <div className="font-bold capitalize text-3xl">{announcementDetail.title}</div>
                    <div className="mt-5 whitespace-pre-line">{announcementDetail.body}</div>
                </div>
                <div className="bg-black/[.10] h-1.5 w-1/2 self-end my-10"></div>
                <div>{moment(announcementDetail.date).format("DD MMM YYYY")}</div>
            </RightSideBar>
            <Footer />
        </div>
    )
}

export default AnnouncementDetails