import {
  isEmail,
  isNotEmpty,
  minLength,
  hasUpperCase,
  hasSpecialCharacter,
  hasNumber,
  confirmPassword,
} from "../utils/validation";

const switchInputCases = (inputName, value, pval) => {
  let validated;
  switch (inputName) {
    case "email":
      validated = isNotEmpty(value) && isEmail(value);
      return validated;
    case "password":
      validated =
        isNotEmpty(value) &&
        minLength(value, 6) &&
        hasUpperCase(value) &&
        hasNumber(value);
      return validated;
    case "confirm_password":
      validated = isNotEmpty(value) && confirmPassword(value, pval);
      return validated;
    default:
      validated = isNotEmpty(value);
      return validated;
  }
};

const input = (value, inputName = null, pval = null) => {
  const inputValue = value;
  let validated = switchInputCases(inputName, value, pval);

  const hasError = !validated;
  return { inputValue, validated, hasError };
};

export default input;
