import React, { useState, useMemo, useEffect } from "react";
import "./../../../App.css";

//import input validation
import inputHook from "../../../hooks/inputHook";

//import firebase functions for announcements creation
import { db, storage } from "../../../firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

//import moment for date formatting
import moment from "moment/moment";

import { AiOutlineLoading } from "react-icons/ai";

const AnnouncementFormModal = ({
  createAnnouncement,
  announcementData,
  setAnnouncementData,
  displayModal,
  setDisplayModal,
  setLoadingTable,
  displaySuccessModal,
}) => {
  const [hasErrors, setHasErrors] = useState({
    title: false,
    body: false,
    priority: false,
    date: false,
    expiry: false,
  });

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(e.target.value, e.target.name);
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });

    setAnnouncementData({
      ...announcementData,
      [e.target.name]: e.target.value,
    });
  };
  const disabledSubmit = useMemo(
    () =>
      !(
        announcementData.length !== 0 &&
        !Object.values(announcementData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [announcementData, hasErrors]
  );

  const [loading, setLoading] = useState(false);

  //executes creation or update of announcements
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const imageFile = e.target[0].files[0];
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const imageName = array[0];

    if (imageFile) {
      if (announcementData.image_url) {
        await deleteObject(ref(storage, announcementData.image_url)).catch(
          () => {}
        );
      }
      await uploadBytes(
        ref(storage, `announcement/images/${imageName}.jpg`),
        imageFile
      );
      const url = await getDownloadURL(
        ref(storage, `announcement/images/${imageName}.jpg`)
      )
        .then((url) => {
          return url;
        })
        .catch((err) => {
          return;
        });
      announcementData.image_url = url;
      e.target[0].value = "";
    }
    announcementData.expiry = new Date(announcementData.expiry).getTime();
    announcementData.title = announcementData.title.toLowerCase();
    createAnnouncement
      ? await addDoc(collection(db, "announcements"), announcementData)
      : await setDoc(
          doc(db, "announcements", announcementData.id),
          announcementData
        );
    setAnnouncementData({});
    setDisplayModal(false);
    setLoadingTable(true);
    setTimeout(() => {
      setLoading(false);
      displaySuccessModal(true);
    }, 300);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${
          displayModal
            ? "opacity-100 ease-out duration-300"
            : "ease-in duration-300"
        }`}
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      ></div>
      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto"
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      >
        <div className="flex min-h-full items-end justify-center p-4 items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${
              loading ? "" : "sm:my-8 sm:w-full sm:max-w-lg"
            } ${
              displayModal
                ? "opacity-100 translate-y-0 sm:scale-100"
                : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="lg:flex lg:items-start">
                {loading ? (
                  <AiOutlineLoading className="animate-spin mb-2 text-4xl bold" />
                ) : (
                  <div className="mt-3 sm:mx-4 sm:mt-0 text-left flex-1">
                    <h2
                      className="text-2xl font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {createAnnouncement
                        ? "Create new announcement"
                        : "Edit announcement"}
                    </h2>
                    <div className="mt-2 mb-5">
                      <p className="text-sm text-gray-500">
                        <span className="text-red-500">*</span> Fill up required
                        fields.
                      </p>
                    </div>
                    <form className="grid gap-4" onSubmit={submitForm}>
                      <div className="text-base grid gap-2">
                        <div>
                          <span className="font-bold">Upload Image</span>
                          <br />
                          <span className="text-sm text-gray-500">
                            Announcement Photo
                          </span>
                          <input
                            type="file"
                            accept="image/*"
                            className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                          />
                        </div>
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Title <span className="text-red-500">*</span>
                        </span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.title
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="title"
                          placeholder="Title"
                          value={
                            announcementData.title ? announcementData.title : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.title && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Title is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Description <span className="text-red-500">*</span>
                        </span>
                        <textarea
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.body
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          id="textarea"
                          name="body"
                          placeholder="Enter body of content here..."
                          value={
                            announcementData.body ? announcementData.body : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        ></textarea>
                        {hasErrors.body && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Body is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Priority <span className="text-red-500">*</span>
                        </span>
                        <select
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.priority
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="priority"
                          value={
                            announcementData.priority
                              ? announcementData.priority
                              : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Please select one</option>
                          <option value="y">Yes</option>
                          <option value="n">No</option>
                        </select>
                        {hasErrors.priority && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Priority is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Date <span className="text-red-500">*</span>
                        </span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.date
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="date"
                          type="date"
                          value={
                            announcementData.date ? announcementData.date : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.date && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Date is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">
                          Expiry Date <span className="text-red-500">*</span>
                        </span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.expiry
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          type="date"
                          name="expiry"
                          value={
                            announcementData.expiry
                              ? moment(announcementData.expiry).format(
                                  "YYYY-MM-DD"
                                )
                              : ""
                          }
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.expiry && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Expiry date is required.
                          </div>
                        )}
                      </div>

                      <div
                        className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}
                      >
                        <button
                          type="submit"
                          onClick={(e) => {}}
                          className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-600 hover:bg-emerald-500 sm:ml-3 sm:w-auto ${
                            disabledSubmit && "cursor-not-allowed"
                          }`}
                          disabled={disabledSubmit}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setDisplayModal(false)}
                          className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementFormModal;
