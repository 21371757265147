import React, { useState, useEffect } from "react";
import "./../../App.css";
import { FaRegTrashAlt, FaCaretRight, FaCaretLeft, FaFileExcel } from "react-icons/fa";

import MessageModal from "../modals/public/MessageModal";
import { useLocation } from "react-router-dom";
import { IoMdAlert } from "react-icons/io";

//import firebase functions for fetching users
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  limit,
  limitToLast,
  deleteDoc,
  getCountFromServer,
  startAfter,
  endBefore,
  orderBy,
  where,
} from "firebase/firestore";

//export table to xlsx (excel sheet)
import * as XLSX from "xlsx/xlsx";

const VolunteerTable = () => {
  const [userData, setUserData] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState();

  const queryArray = [limit(10)];
  const searchQueryArray = [];

  const location = useLocation();

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      // Base query
      let userQuery = query(
        collection(db, "users"),
        where("is_willing_to_volunteer", "==", "yes"),
        orderBy("full_name"), // Firestore requires this for range queries
        limit(10)
      );

      // Add search filters
      searchQueryArray.forEach((filter) => {
        userQuery = query(userQuery, filter);
      });

      // Fetch data
      const userSnap = await getDocs(userQuery);
      if (userSnap.empty) {
        setUserData([]);
        setLoading(false);
        return;
      }

      // Process the fetched data
      const processedData = userSnap.docs.map((d) => {
        const data = d.data();
        return {
          id: d.id,
          full_name: capitalizeWords(data.full_name || ""),
          address: capitalizeWords(data.address || ""),
          suburb: capitalizeWords(data.suburb || ""),
          postcode: data.postcode,
          is_willing_to_volunteer: capitalizeWords(
            data.is_willing_to_volunteer
          ),
          groups_interested_in: Array.isArray(data.groups_interested_in)
            ? data.groups_interested_in.map(capitalizeWords).join(", ")
            : "",
          availability: Array.isArray(data.availability)
            ? data.availability.map(capitalizeWords).join(", ")
            : "",
        };
      });

      // Set user data and pagination
      setUserData(processedData);
      setPrevSnap(userSnap.docs[userSnap.docs.length - 1]);
      const userCount = await getCountFromServer(
        query(
          collection(db, "users"),
          where("is_willing_to_volunteer", "==", "yes")
        )
      );
      setTotalPages(Math.ceil(userCount.data().count / 10));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const handleSearchQueries = async (searchKey) => {
    searchQueryArray.length = 0;

    if (!searchKey) {
      // Clear search if no input
      fetchData();
      return;
    }

    try {
      const lowercaseSearchKey = searchKey.toLowerCase();

      // Perform queries for each searchable field
      const queries = [
        query(
          collection(db, "users"),
          where("is_willing_to_volunteer", "==", "yes"),
          where("full_name", ">=", lowercaseSearchKey),
          where("full_name", "<=", lowercaseSearchKey + "\uf8ff")
        ),
        query(
          collection(db, "users"),
          where("is_willing_to_volunteer", "==", "yes"),
          where("organization", ">=", lowercaseSearchKey),
          where("organization", "<=", lowercaseSearchKey + "\uf8ff")
        ),
        query(
          collection(db, "users"),
          where("is_willing_to_volunteer", "==", "yes"),
          where("suburb", ">=", lowercaseSearchKey),
          where("suburb", "<=", lowercaseSearchKey + "\uf8ff")
        ),
        query(
          collection(db, "users"),
          where("is_willing_to_volunteer", "==", "yes"),
          where("postcode", ">=", lowercaseSearchKey),
          where("postcode", "<=", lowercaseSearchKey + "\uf8ff")
        ),
      ];

      // Execute all queries in parallel
      const querySnapshots = await Promise.all(queries.map((q) => getDocs(q)));

      // Merge results, ensuring no duplicates
      const results = querySnapshots.flatMap((snap) =>
        snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );

      const uniqueResults = Array.from(
        new Map(results.map((item) => [item.id, item])).values()
      );

      setUserData(uniqueResults); // Update table with combined results
    } catch (error) {
      console.error("Error during search:", error);
      setUserData([]); // Clear table if error occurs
    }
  };

  const modalFunction = async () => {
    setLoading(false);
    //delete user document
    await deleteDoc(doc(db, "users", deleteSelected));
    fetchData();
  };

  const handlePageChange = (paginateNext) => {
    setLoading(true);
    queryArray.length = 0;
    // set current page when next or previous is clicked
    if (paginateNext) {
      setCurrentPage((prev) => prev + 1);
      queryArray.push(limit(10), startAfter(prevSnap));
    } else {
      setCurrentPage((prev) => prev - 1);
      queryArray.push(limitToLast(10), endBefore(prevSnap));
    }
    fetchData();
  };

  const exportDataToExcel = async () => {
    const userSnap = await getDocs(query(collection(db, "users"), orderBy("full_name"), where("is_willing_to_volunteer", "==", "yes")));
    const userSheetData = userSnap.docs.map((d) => ({ 
      "First name": d.data().first_name,
      "Last name": d.data().last_name,
      "Phone": d.data().phone_number,
      "Address": `${d.data().address} ${d.data().suburb} ${d.data().postcode}`,
      "Volunteering?": d.data().is_willing_to_volunteer,
      "Interest groups": Array.isArray(d.data().groups_interested_in)? d.data().groups_interested_in.map(capitalizeWords).join(", ") : "",
      "Availability": Array.isArray(d.data().availability)? d.data().availability.map(capitalizeWords).join(", ") : "",
    }));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(userSheetData);

    XLSX.utils.book_append_sheet(wb, ws, "User Data");
    XLSX.writeFile(wb, "Volunteer Data "+new Date()+".xlsx");
  };

  return (
    <div>
      <div className="relative flex items-center mt-4 md:mt-0">
        <span className="absolute">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-5 h-5 mx-3 text-gray-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </span>

        <input
          type="text"
          placeholder="Search by name, suburb, postcode"
          onChange={(e) => handleSearchQueries(e.target.value.toLowerCase())}
          className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-600 rounded-lg md:w-100 placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        <div className="w-full md:flex md:items-center md:place-content-end">
          <button
            onClick={() => {
              exportDataToExcel();
            }}
            className="inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 sm:w-auto flex items-center gap-2"
          >
            <FaFileExcel /> Export
          </button>
        </div>
      </div>
      <div className="my-10 overflow-x-scroll w-[300px] sm:w-[590px] md:w-full">
        <table className="min-w-max md:min-w-fit divide-y divide-gray-200 md:w-[100%]">
          <thead className="bg-blue-100">
            <tr>
              <th
                scope="col"
                className="py-4 px-4 text-sm font-bold text-left rtl:text-right"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
              >
                Address
              </th>
              <th
                scope="col"
                className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
              >
                Volunteer Status
              </th>

              <th
                scope="col"
                className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
              >
                Volunteer Groups
              </th>

              <th
                scope="col"
                className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
              >
                Availability
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
              </>
            ) : (
              userData.map((user, index) => (
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                    {user.full_name}
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[20%] break-words capitalize">
                    {`${user.address}, ${user.suburb}, ${user.postcode}`}
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[12%] break-words">
                    {user.is_willing_to_volunteer}
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[23%] break-all">
                    {user.groups_interested_in}
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[17%] break-all">
                    {user.availability}
                  </td>
                </tr>
              ))
            )}
            {userData.length <= 0 && loading == false && (
              <tr>
                <td colSpan={3} className="text-center">
                  <div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center">
                    <IoMdAlert className="mr-2 text-red-500" /> No result found.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex items-center justify-between mt-6">
          <div></div>
          <div className="items-center hidden lg:flex gap-x-3">
            <button
              onClick={() => handlePageChange(false)}
              disabled={currentPage === 1}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              aria-label="Next"
            >
              <FaCaretLeft />
            </button>
            Page {currentPage} of {totalPages}
            <button
              onClick={() => handlePageChange(true)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              aria-label="Previous"
            >
              <FaCaretRight />
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <MessageModal
        title={"Delete User?"}
        description={"Are you sure you want to delete this user?"}
        buttonText={"Delete"}
        gif={null}
        approve={false}
        displayModal={deleteModal}
        setDisplayModal={setDeleteModal}
        functionPassed={modalFunction}
      />
    </div>
  );
};

export default VolunteerTable;
