import React, { useEffect, useState, useContext } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import RightSideBar from "../../components/RightSideBar";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoMdAlert } from "react-icons/io";
import MessageModal from "../../components/modals/public/MessageModal";

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

//import firebase functions for forum groups
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  limit,
  limitToLast,
  getCountFromServer,
  startAfter,
  endBefore,
  orderBy,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const CommunityForumBrowse = () => {
  const auth = useContext(AuthContext);
  //set user data using local storage (user info)
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const navigate = useNavigate();
  const location = useLocation();
  const forumCategory = location.state;

  const [forumGroupsData, setForumGroupsData] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState([]);

  const [joinModal, setJoinModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [cancelJoinModal, setCancelJoinModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();

  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("all");

  const [queryArray, setQueryArray] = useState([limit(10)]);
  const [searchQueryArray, setSearchQueryArray] = useState([where("category_id", "==", forumCategory.id)]);
  const [orderQueryArray, setOrderQueryArray] = useState([orderBy("date", "desc")]);

  const fetchData = async () => {
    // creates query for forum groups pagination or filtering
    const forumGroupsQuery = query(
      collection(db, "forum_groups"),
      ...orderQueryArray,
      ...queryArray,
      ...searchQueryArray
    );
    // executes and fetches data based on query
    const forumGroupsSnap = await getDocs(forumGroupsQuery);
    // executes and fetches data based on query
    setPrevSnap({next: forumGroupsSnap.docs[9], prev: forumGroupsSnap.docs[0]});
    // set forum groups data array for display
    setForumGroupsData(
      forumGroupsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
    );
    // pagination number
    const userCount = await getCountFromServer(
      query(collection(db, "forum_groups"), ...searchQueryArray)
    );
    setTotalPages(Math.ceil(userCount.data().count / 10));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

  const handlePageChange = (paginateNext) => {
    setQueryArray([limit(10)]);
    // set current page when next or previous is clicked
    if (paginateNext) {
      setCurrentPage((prev) => prev + 1);
      setQueryArray([limit(10), startAfter(prevSnap.next)]);
    } else {
      setCurrentPage((prev) => prev - 1);
      setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
    }
    setLoading(true);
  };

  const handleSearchQueries = (tabActive, searchKey) => {
    setSearchQueryArray([where("category_id", "==", forumCategory.id)]);
    setQueryArray([limit(10)]);

    if (tabActive == "my group") {
      setSearchQueryArray([
        where("category_id", "==", forumCategory.id),
        where("members", "array-contains", auth.userId)
      ]);
      searchKey &&
        setSearchQueryArray([
          where("category_id", "==", forumCategory.id),
          where("members", "array-contains", auth.userId),
          where("title", ">=", searchKey),
          where("title", "<=", searchKey + "\uf8ff")
        ]);
    } else if (tabActive == "all") {
      searchQueryArray.push(where("category_id", "==", forumCategory.id));
      searchKey &&
        setSearchQueryArray([
          where("category_id", "==", forumCategory.id),
          where("title", ">=", searchKey),
          where("title", "<=", searchKey + "\uf8ff")
        ]);
    }

    setCurrentPage(1);
    setLoading(true);
  };

  const clickRedirect = (forumGroup) => {
    navigate("/community-forum/details", { state: JSON.stringify(forumGroup) });
  };

  const joinFunction = async () => {
    await addDoc(collection(db, "forum_group_join_request"), {
      group_id: selectedGroup.id,
      group_title: selectedGroup.title,
      user_id: auth.userId,
      user_full_name: userData.full_name,
      joined_date: Date.now(),
    });
    await updateDoc(doc(db, "forum_groups", selectedGroup.id), {
      members_request: arrayUnion(auth.userId),
    });
    setLoading(true);
  };

  const cancelJoinFunction = async () => {
    var removeJoinRequest = await getDocs(
      query(
        collection(db, "forum_group_join_request"),
        where("group_id", "==", selectedGroup.id),
        where("user_id", "==", auth.userId)
      )
    );
    removeJoinRequest.docs.map(async (joinRequest) => {
      await deleteDoc(doc(db, "forum_group_join_request", joinRequest.id));
    });
    await updateDoc(doc(db, "forum_groups", selectedGroup.id), {
      members_request: arrayRemove(auth.userId),
    });
    setLoading(true);
  };

  const leaveFunction = async () => {
    await updateDoc(doc(db, "forum_groups", selectedGroup.id), {
      members: arrayRemove(auth.userId),
    });
    setLoading(true);
  };

  return (
    <div>
      <Navigation />
      <RightSideBar title="Community Forum">
        <div className="pt-4">
          <div
            className="text-xl mb-6 text-blue-500 underline text-left flex items-center cursor-pointer w-80"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack /> BACK TO PREVIOUS PAGE
          </div>
          <div className="text-3xl mb-5 font-bold capitalize">
            {forumCategory.category_name}
          </div>
        </div>
        <div className="flex gap-5 border-y-black/[.10] border-y-4 py-2">
          <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
            <button
              onClick={() => {
                setTab("all");
                handleSearchQueries("all");
              }}
              className={`px-5 py-2 text-base font-bold text-gray-600 transition-colors duration-200 ${
                tab == "all" && "bg-gray-100"
              } hover:bg-gray-100`}
            >
              ALL GROUPS
            </button>

            <button
              onClick={() => {
                setTab("my group");
                handleSearchQueries("my group");
              }}
              className={`px-5 py-2 text-base font-bold text-gray-600 transition-colors duration-200 ${
                tab == "my group" && "bg-gray-100"
              } hover:bg-gray-100`}
            >
              MY GROUPS
            </button>
          </div>
        </div>
        <div className="my-10">
          <div className="text-xl font-bold mb-2">SEARCH ALL GROUPS</div>
          <input
            className="mb-4 p-2 font-medium rounded-sm shadow-lg ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
            name="search"
            placeholder="Find group"
            onChange={(e) => {
              handleSearchQueries(tab, e.target.value);
            }}
          />
        </div>
        <div className="flex gap-10">
          <div>
            <div className="text-xl font-bold mb-2">SORT BY</div>
            <select
              className="mb-4 p-2 font-medium rounded-sm shadow-lg ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
              name="filter"
              onChange={(e) => {
                setOrderQueryArray([orderBy("date", "desc")]);
                setQueryArray([limit(10)]);

                if (e.target.value === "newest") {
                  setOrderQueryArray([orderBy("date", "desc")]);
                } else {
                  setOrderQueryArray([orderBy("date")]);
                }
                setCurrentPage(1);
                setLoading(true);
              }}
            >
              <option value="newest">Newest Post</option>
              <option value="oldest">Oldest Post</option>
            </select>
          </div>
        </div>
        <div className="grid gap-10 my-10">
          {loading ? (
            <>
              <div className="md:flex gap-10 animate-pulse">
                <div className="h-[150px] w-[150px] bg-gray-200"></div>
                <div className="grid gap-2">
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="text-base font-bold flex gap-5">
                    <div className="w-40 bg-gray-200 h-4 rounded-full"></div>
                  </div>
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-40 bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-60 bg-gray-200 h-8 rounded-full"></div>
                </div>
              </div>
              <div className="md:flex gap-10 animate-pulse">
                <div className="h-[150px] w-[150px] bg-gray-200"></div>
                <div className="grid gap-2">
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="text-base font-bold flex gap-5">
                    <div className="w-40 bg-gray-200 h-4 rounded-full"></div>
                  </div>
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-40 bg-gray-200 h-4 rounded-full"></div>
                  <div className="w-60 bg-gray-200 h-8 rounded-full"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              {forumGroupsData.map((forumGroup, index) => {
                let button = (
                  <button
                    onClick={() => {
                      setJoinModal(true);
                      setSelectedGroup(forumGroup);
                    }}
                    className="w-40 text-center rounded-lg bg-[#1479D1] px-3 py-2 text-base font-bold text-white shadow-sm hover:bg-[#1479D1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
                    type="button"
                  >
                    Join
                  </button>
                );
                if (forumGroup.members) {
                  if (forumGroup.members.includes(auth.userId)) {
                    button = (
                      <button
                        onClick={() => {
                          setLeaveModal(true);
                          setSelectedGroup(forumGroup);
                        }}
                        className="w-60 text-center rounded-lg bg-red-500 px-3 py-2 text-base font-bold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
                        type="button"
                      >
                        Leave group
                      </button>
                    );
                  }
                }

                if (forumGroup.members_request) {
                  if (forumGroup.members_request.includes(auth.userId)) {
                    button = (
                      <button
                        onClick={() => {
                          setCancelJoinModal(true);
                          setSelectedGroup(forumGroup);
                        }}
                        className="w-60 text-center rounded-lg bg-neutral-600 px-3 py-2 text-base font-bold text-white shadow-sm hover:bg-neutral-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
                        type="button"
                      >
                        Cancel join request
                      </button>
                    );
                  }
                }
                return (
                  <div className="md:flex gap-10">
                    <div className="h-[150px] w-[150px]">
                      <img src={forumGroup.image_url} />
                    </div>
                    <div className="grid gap-2 cursor-pointer">
                      <div className="text-2xl">
                        <a
                          onClick={() => {
                            clickRedirect(forumGroup);
                          }}
                          className="text-blue-500 hover:underline capitalize"
                        >
                          {forumGroup.title}
                        </a>
                      </div>
                      <div className="text-base font-bold flex gap-5">
                        <div>
                          {forumGroup.members ? forumGroup.members.length : "0"}{" "}
                          MEMBERS
                        </div>
                        <div className="uppercase">{forumGroup.status}</div>
                      </div>
                      <div className="text-base font-bold">
                        {forumGroup.body.slice(0, 100) + "..."}
                      </div>
                      <div className="text-base italic">
                        Last post:{" "}
                        {moment(forumGroup.date).format("DD MMM YYYY")}
                      </div>
                      {button}
                    </div>
                  </div>
                );
              })}
              {forumGroupsData.length <= 0 && 
                <div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center"><IoMdAlert className="mr-2 text-red-500" /> No forum groups available.</div>
              }
            </>
          )}
          {totalPages > 0 && (
            <div className="flex items-center justify-between mt-6">
              <div></div>
              <div className="items-center hidden lg:flex gap-x-3">
                <button
                  onClick={() => handlePageChange(false)}
                  disabled={currentPage === 1}
                  className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                >
                  <FaCaretLeft />
                </button>
                Page {currentPage} of {totalPages}
                <button
                  onClick={() => handlePageChange(true)}
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
                >
                  <FaCaretRight />
                </button>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </RightSideBar>
      <MessageModal
        title={"Join forum group?"}
        description={"Are you sure you want to join this forum group?"}
        buttonText={"Join"}
        approve={true}
        displayModal={joinModal}
        setDisplayModal={setJoinModal}
        functionPassed={joinFunction}
      />
      <MessageModal
        title={"Leave forum group?"}
        description={"Are you sure you want to leave this forum group?"}
        buttonText={"Leave"}
        approve={false}
        displayModal={leaveModal}
        setDisplayModal={setLeaveModal}
        functionPassed={leaveFunction}
      />
      <MessageModal
        title={"Cancel join request for this forum group?"}
        description={
          "Are you sure you want to cancel join request to this forum group?"
        }
        buttonText={"Yes"}
        approve={false}
        displayModal={cancelJoinModal}
        setDisplayModal={setCancelJoinModal}
        functionPassed={cancelJoinFunction}
      />
      <Footer />
    </div>
  );
};

export default CommunityForumBrowse;
