import React, { useState, useMemo, useContext } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { GoAlert, GoEye, GoEyeClosed } from "react-icons/go";

//import input validation
import inputHook from "../../hooks/inputHook";

//import firebase functions for login
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

//import authentication context to store firebase return signin value
import { AuthContext } from "../../context/authContext";

const Login = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });

  const [hasErrors, setHasErrors] = useState({
    email: false,
    password: false,
  });

  const [viewPassword, setViewPassword] = useState(false);
  const [signInError, setSignInError] = useState(false);

  const [errorMsg, setErrorMsg] = useState();

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(e.target.value, e.target.name);
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  const disabledSubmit = useMemo(
    () =>
      !(
        !Object.values(inputData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [inputData, hasErrors]
  );

  //tests user provided email and password if registered
  const signIn = async (e) => {
    e.preventDefault();
    try {
      const userAuthData = await signInWithEmailAndPassword(
        auth,
        inputData.email,
        inputData.password
      );

      // Fetch user details from Firestore
      const userDoc = await getDoc(doc(db, "users", userAuthData.user.uid));
      const userInfo = userDoc.data();

      // Check if account is active
      if (!userInfo.status) {
        setSignInError(true);
        setErrorMsg("Account is inactive. Please contact support.");
        return;
      }

      // Pass role and other details to AuthContext
      authContext.loginUser(
        userAuthData.user.uid,
        userInfo.name, // Replace with your user's name field
        userAuthData.user.accessToken,
        userInfo.role // Pass the role
      );

      // Save user info in local storage
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      navigate("/dashboard");
    } catch (error) {
      setSignInError(true);
      setErrorMsg("Invalid username or password.");
    }
  };

  return (
    <div>
      <Navigation />
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-left">
          <form className="space-y-6" onSubmit={signIn}>
            {signInError && (
              <div className="text-red-500 text-sm flex items-center gap-2 justify-center p-2 bg-red-200 rounded-lg">
                <GoAlert /> {errorMsg}
              </div>
            )}

            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.email
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  value={inputData.email}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {hasErrors.email && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Invalid email address.
                </div>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  for="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2 relative flex items-center flex-row-reverse">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 pr-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.password
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="password"
                  name="password"
                  type={viewPassword ? "text" : "password"}
                  autocomplete="current-password"
                  required
                  value={inputData.password}
                  onChange={(e) => onInputChange(e)}
                />
                <div className="absolute">
                  {viewPassword ? (
                    <GoEyeClosed
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() => setViewPassword(!viewPassword)}
                    />
                  ) : (
                    <GoEye
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() => setViewPassword(!viewPassword)}
                    />
                  )}
                </div>
              </div>
              {hasErrors.password && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Invalid password.
                </div>
              )}
            </div>

            <div>
              <button
                className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75 ${
                  disabledSubmit && "cursor-not-allowed"
                }`}
                type="submit"
                disabled={disabledSubmit}
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <a
              href="/registration"
              className="ml-1 font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Register here
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
