import React, { useEffect, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import LeftSideBar from "../../components/LeftSideBar";
import { BarChart } from "@mui/x-charts/BarChart";

//import firebase functions for fetching users
import { db } from "../../firebase";
import {
  collection,
  query,
  getCountFromServer,
  where,
  getDocs,
} from "firebase/firestore";

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(null);
  const [forumGroupReqCount, setForumGroupReqCount] = useState(0);
  const [volunteerCount, setVolunteerCount] = useState(null);
  const [orgDataCount, setOrgDataCount] = useState(0);
  const [orgCatgryCount, setOrgCatgryCount] = useState(0);
  const [userRegReqCount, setUserRegReqCount] = useState(null);

  const [priorityCounts, setPriorityCounts] = useState([]);

  const fetchUserCount = async () => {
    try {
      const activeUserQuery = query(
        collection(db, "users"),
        where("status", "==", true)
      );
      const activeUserSnapshot = await getCountFromServer(activeUserQuery);
      const count = activeUserSnapshot.data().count;
      setUserCount(count);

      const regReqQuery = query(
        collection(db, "users"),
        where("status", "==", false)
      );
      const regReqSnapshot = await getCountFromServer(regReqQuery);
      const countRegReq = regReqSnapshot.data().count;
      setUserRegReqCount(countRegReq);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  const fetchForumGroupCount = async () => {
    try {
      const forumGroupReq = await getCountFromServer(
        query(collection(db, "forum_group_join_request"))
      );
      const count = Math.ceil(forumGroupReq.data().count);
      setForumGroupReqCount(count);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  const fetchVolunteerCount = async () => {
    try {
      const volQuery = query(
        collection(db, "users"),
        where("is_willing_to_volunteer", "==", "yes")
      );
      const volSnapshot = await getCountFromServer(volQuery);
      const count = volSnapshot.data().count;
      setVolunteerCount(count);
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  const fetchOrgDirCount = async () => {
    try {
      const orgDataSnapshot = await getCountFromServer(
        collection(db, "org_data")
      );
      setOrgDataCount(orgDataSnapshot.data().count);

      const orgCatgrySnapshot = await getCountFromServer(
        collection(db, "org_catgry")
      );
      setOrgCatgryCount(orgCatgrySnapshot.data().count);
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };

  useEffect(() => {
    const fetchAnnouncementData = async () => {
      try {
        const announcementsRef = collection(db, "announcements");
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);
        const endOfYear = new Date(new Date().getFullYear() + 1, 0, 1);

        const priorityQuery = query(
          announcementsRef,
          where("priority", "==", "y"),
          where("date", ">=", startOfYear.toISOString().split("T")[0]),
          where("date", "<", endOfYear.toISOString().split("T")[0])
        );

        const snapshot = await getDocs(priorityQuery);

        const priorityData = Array(12).fill(0);
        snapshot.forEach((doc) => {
          const date = new Date(doc.data().date);
          const monthIndex = date.getMonth();
          priorityData[monthIndex]++;
        });

        const formattedData = priorityData.map((count, index) => ({
          month: new Date(0, index).toLocaleString("default", {
            month: "long",
          }),
          count,
        }));

        setPriorityCounts(formattedData);
      } catch (error) {
        console.error("Error fetching announcement data:", error);
      }
    };

    fetchAnnouncementData();
    fetchUserCount();
    fetchForumGroupCount();
    fetchVolunteerCount();
    fetchOrgDirCount();
  }, [priorityCounts]);

  return (
    <div>
      <Navigation />
      <LeftSideBar>
        <div className="grid place-items-center">
          <div className="container text-left my-10 mx-[-2.5rem] sm:mx-0">
            <div className="text-4xl">Admin Dashboard</div>
            <div className="mt-6 md:flex md:items-center md:justify-between"></div>
            <div className="my-5 grid grid-cols-2 sm:grid-cols-4 gap-4">
              <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center  w-[100%] h-[100%]">
                <a href="/user-management">
                  <h5 class="mb-2 text-md font-bold text-gray-400">
                    ACTIVE USERS
                  </h5>
                </a>
                <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                  {userCount}
                </p>
              </div>
              <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center  w-[100%] h-[100%]">
                <a href="/user-management?table=volunteers">
                  <h5 class="mb-2 text-md font-bold text-gray-400">
                    ACTIVE VOLUNTEERS
                  </h5>
                </a>
                <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                  {volunteerCount}
                </p>
              </div>
              <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center  w-[100%] h-[100%]">
                <a href="/pending-approvals">
                  <h5 class="mb-2 text-md font-bold text-gray-400">
                    REGISTRATION REQUEST
                  </h5>
                </a>
                <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                  {userRegReqCount}
                </p>
              </div>
              <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center  w-[100%] h-[100%]">
                <a href="/pending-approvals?table=forum">
                  <h5 class="mb-2 text-md font-bold text-gray-400">
                    FORUM JOIN REQUEST
                  </h5>
                </a>
                <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                  {forumGroupReqCount}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:flex-row">
              <div class="p-2 bg-white border border-gray-200 rounded-lg shadow text-center sm:w-[49.3%] w-[100%]">
                <h5 class="mb-2 text-xl font-bold text-gray-400">
                  EMERGENCIES
                </h5>
                <BarChart
                  xAxis={[
                    {
                      id: "months-axis",
                      scaleType: "band",
                      data: priorityCounts.map((item) => item.month),
                    },
                  ]}
                  series={[
                    {
                      id: "priority-series",
                      data: priorityCounts.map((item) => item.count),
                      color: "red",
                    },
                  ]}
                  height={320}
                />
              </div>
              <div className="flex gap-4 flex-1">
                <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center h-1/2 sm:w-[49.3%] w-[49.3%]">
                  <a href="/organization-management">
                    <h5 class="mb-2 text-md font-bold text-gray-400">
                      ORGANISATION
                    </h5>
                  </a>
                  <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                    {orgDataCount}
                  </p>
                </div>
                <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow text-center h-1/2  sm:w-[49.3%] w-[49.3%]">
                  <a href="/organization-management?table=directory">
                    <h5 class="mb-2 text-md font-bold text-gray-400">
                      DIRECTORY
                    </h5>
                  </a>
                  <p class="mb-3 leading-20 text-6xl font-bold text-gray-900">
                    {orgCatgryCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LeftSideBar>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
