import React, { useState, useMemo } from "react";
import './../../../App.css';

//import input validation
import inputHook from "../../../hooks/inputHook";

//import firebase functions for forum category creation
import { db } from "../../../firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { AiOutlineLoading } from "react-icons/ai";

const ForumCategoryFormModal = ({ createForumCategory, id, forumCategoryData, setforumCategoryData, displayModal, setDisplayModal, setLoadingTable, displaySuccessModal }) => {
    const [hasErrors, setHasErrors] = useState({
        category_name: false
    });

    const [loading, setLoading] = useState(false);

    //tests user input to input validation requirements
    const onInputChange = (e) => {
        const validation = inputHook(e.target.value, e.target.name);
        setHasErrors({
            ...hasErrors,
            [e.target.name]: validation.hasError
        });

        setforumCategoryData({
            ...forumCategoryData,
            [e.target.name]: e.target.value
        });
    }

    const disabledSubmit = useMemo(() => !((forumCategoryData.length !== 0 && !Object.values(forumCategoryData).some(x => x === null || x === '')) && Object.values(hasErrors).every(x => x === false)), [forumCategoryData, hasErrors])

    //executes creation or update of forum category
    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        forumCategoryData.category_name = forumCategoryData.category_name.toLowerCase();
        createForumCategory ? await addDoc(collection(db, "forum_categories"), forumCategoryData) : await setDoc(doc(db, "forum_categories", forumCategoryData.id), forumCategoryData) ;
        setforumCategoryData({});
        setLoadingTable(true);
        setTimeout(() => {setLoading(false); displaySuccessModal(true);}, 300);
    }
    
    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${displayModal ? "opacity-100 ease-out duration-300" : "ease-in duration-300" }`} style={{ visibility: displayModal ? "visible" : "hidden" }}></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto" style={{ visibility: displayModal ? "visible" : "hidden" }}>
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${!loading && "sm:my-8 sm:w-full sm:max-w-lg"} ${displayModal ? "opacity-100 translate-y-0 sm:scale-100" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }`}>
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="lg:flex lg:items-start">
                                    {loading ?
                                        <AiOutlineLoading className="animate-spin mb-2 text-4xl bold" />
                                    :
                                        <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left flex-1">
                                            <h2 className="text-2xl font-semibold leading-6 text-gray-900" id="modal-title">{createForumCategory ? "Create new category" : "Edit category" }</h2>
                                            <div className="mt-2 mb-5">
                                                <p className="text-sm text-gray-500"><span className="text-red-500">*</span> Fill up required fields.</p>
                                            </div>
                                            <form className="grid gap-4" onSubmit={submitForm}>
                                                <div className="text-base grid gap-2">
                                                    <span className="font-bold">Forum category name <span className="text-red-500">*</span></span>
                                                    <input className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${hasErrors.category_name ? "focus:outline-red-400 focus:ring-red-600" : "focus:ring-indigo-600"} sm:text-sm sm:leading-6 px-2 capitalize`}
                                                        name="category_name" 
                                                        placeholder="Forum category name"
                                                        value={(forumCategoryData.category_name) ? forumCategoryData.category_name : ""}
                                                        onChange={(e) => onInputChange(e)}
                                                        />
                                                    {hasErrors.category_name &&
                                                        <div className="text-red-500 text-sm inline-flex items-center gap-2">
                                                            Forum category name is required.
                                                        </div>
                                                    }
                                                </div>
                                                <div className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}>
                                                    <button type="submit" onClick={(e) => {setDisplayModal(false);}} className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-600 hover:bg-emerald-500 sm:ml-3 sm:w-auto ${disabledSubmit && "cursor-not-allowed"}`} disabled={disabledSubmit}>Save</button>
                                                    <button type="button" onClick={() => setDisplayModal(false)} className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ForumCategoryFormModal