import "./App.css";
import { Route, Routes } from "react-router-dom";

// Public Pages
import Home from "./pages/public/Home";
import Team from "./pages/public/Team";
import TeamDetails from "./pages/public/TeamDetails";
import Contact from "./pages/public/Contact";
import Login from "./pages/public/Login";
import Registration from "./pages/public/Registration";
import ForgotPassword from "./pages/public/ForgotPassword";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import PageNotFound from "./pages/public/PageNotFound";

// User Pages
import Profile from "./pages/user/Profile";
import EditProfile from "./pages/user/EditProfile";
import Dashboard from "./pages/user/Dashboard";
import CommunityForum from "./pages/user/CommunityForum";
import CommunityForumBrowse from "./pages/user/CommunityForumBrowse";
import CommunityForumDetails from "./pages/user/CommunityForumDetails";
import CommunityForumDiscussion from "./pages/user/CommunityForumDiscussion";
import CommunityEvents from "./pages/user/CommunityEvents";
import Announcement from "./pages/user/Announcement";
import AnnouncementDetails from "./pages/user/AnnouncementDetails";
import CommunityDirectory from "./pages/user/CommunityDirectory";

// Admin & Super Admin Pages
import AdminDashboard from "./pages/admin/Dashboard";
import OrganizationManagement from "./pages/admin/OrganizationManagement";
import UserManagement from "./pages/admin/UserManagement";
import TeamManagement from "./pages/admin/TeamManagement";
import AnnouncementManagement from "./pages/admin/AnnouncementManagement";
import ForumManagement from "./pages/admin/ForumManagement";
import PendingApprovals from "./pages/admin/PendingApprovals";

// Protects pages from unauthenticated users
import ProtectedView from "./components/auth/ProtectedView";

//import authentication context to use stored user data globally
import { AuthContext } from "./context/authContext";
import { useEffect, useState } from "react";

function App() {
  const [uid, setUid] = useState(null);
  const [active, setActivity] = useState(null);
  const [token, setToken] = useState(null);
  const [isReady, setIsReady] = useState(false);

  // set constant value uid, active, token values
  // store current user authenticated data in localStorage (userData)
  // JSON.stringify convert object to string
  const onLogin = (uid, active, token, role) => {
    setUid(uid);
    setActivity(active);
    setToken(token);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        isLoggedIn: !!token,
        userId: uid,
        activeUser: active,
        token: token,
        role: role, // Save the role in local storage
      })
    );
  };

  // set constant value uid, active, token values to null
  // remove current user authenticated data in localStorage (userData and userInfo)
  const onLogout = () => {
    setUid(null);
    setActivity(null);
    setToken(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("userInfo");
  };

  // checks if a user is already authenticated
  // redirect user to appropriate page if authenticated
  // isReady is set to true to ensure routes are protected
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      onLogin(userData.userId, userData.activeUser, userData.token, userData.role);
    }
    setIsReady(true);
  }, []);

  return (
    <div className="App">
      {/* Share user authenticated data globally using Context */}
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          userId: uid,
          activeUser: active,
          role:
            active?.role ||
            JSON.parse(localStorage.getItem("userData"))?.role ||
            null, // Include role fallback
          token: token,
          loginUser: onLogin,
          logoutUser: onLogout,
        }}
      >
        {isReady && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-team" element={<Team />} />
            <Route path="/our-team/details" element={<TeamDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/my-profile"
              element={
                <ProtectedView>
                  <Profile />
                </ProtectedView>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <ProtectedView>
                  <EditProfile />
                </ProtectedView>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedView>
                  <Dashboard />
                </ProtectedView>
              }
            />
            <Route
              path="/community-forum"
              element={
                <ProtectedView>
                  <CommunityForum />
                </ProtectedView>
              }
            />
            <Route
              path="/community-forum/browse"
              element={
                <ProtectedView>
                  <CommunityForumBrowse />
                </ProtectedView>
              }
            />
            <Route
              path="/community-forum/details"
              element={
                <ProtectedView>
                  <CommunityForumDetails />
                </ProtectedView>
              }
            />
            <Route
              path="/community-forum/discussion"
              element={
                <ProtectedView>
                  <CommunityForumDiscussion />
                </ProtectedView>
              }
            />
            <Route
              path="/community-events"
              element={
                <ProtectedView>
                  <CommunityEvents />
                </ProtectedView>
              }
            />
            <Route
              path="/announcements"
              element={
                <ProtectedView>
                  <Announcement />
                </ProtectedView>
              }
            />
            <Route
              path="/announcement-details"
              element={
                <ProtectedView>
                  <AnnouncementDetails />
                </ProtectedView>
              }
            />
            <Route
              path="/community-directory"
              element={
                <ProtectedView>
                  <CommunityDirectory />
                </ProtectedView>
              }
            />
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedView requiredRole="admin">
                  <AdminDashboard />
                </ProtectedView>
              }
            />
            <Route
              path="/organization-management"
              element={
                <ProtectedView requiredRole="admin">
                  <OrganizationManagement />
                </ProtectedView>
              }
            />
            <Route
              path="/user-management"
              element={
                <ProtectedView requiredRole="admin">
                  <UserManagement />
                </ProtectedView>
              }
            />
            <Route
              path="/team-management"
              element={
                <ProtectedView requiredRole="admin">
                  <TeamManagement />
                </ProtectedView>
              }
            />
            <Route
              path="/announcement-management"
              element={
                <ProtectedView requiredRole="admin">
                  <AnnouncementManagement />
                </ProtectedView>
              }
            />
            <Route
              path="/forum-management"
              element={
                <ProtectedView requiredRole="admin">
                  <ForumManagement />
                </ProtectedView>
              }
            />
            <Route
              path="/pending-approvals"
              element={
                <ProtectedView requiredRole="admin">
                  <PendingApprovals />
                </ProtectedView>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
