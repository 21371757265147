import React, { useState, useEffect } from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import RightSideBar from '../../components/RightSideBar';
import { useNavigate } from 'react-router-dom';
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

//import firebase functions for announcement
import { db } from "../../firebase";
import { collection, getDocs, query, limit, startAfter, limitToLast, endBefore, orderBy, getCountFromServer, or, and, where } from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const Announcement = () => {
    const navigate = useNavigate();
    const [announcementData, setAnnouncementData] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevSnap, setPrevSnap] = useState([]);

    const [loading, setLoading] = useState(true);

    const [queryArray, setQueryArray] = useState([limit(10)]);
    const [searchQueryArray, setSearchQueryArray] = useState([where("title", ">=", "")]);
    const [orderQueryArray, setOrderQueryArray] = useState([orderBy("date", "desc")]);

    const fetchData = async () => {
        // creates query for announcements pagination or filtering
        const announcementQuery = query(collection(db, "announcements"), ...orderQueryArray, ...searchQueryArray, ...queryArray);
        // executes and fetches data based on query
        const announcementsSnap = await getDocs(announcementQuery);
        // set previous snap
        setPrevSnap({next: announcementsSnap.docs[9], prev: announcementsSnap.docs[0]});
        // set announcements data array for display
        setAnnouncementData(announcementsSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        // pagination number
        const userCount = await getCountFromServer(query(collection(db, "announcements")));
        setTotalPages(Math.ceil(userCount.data().count / 10));
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [loading]);

    const clickRedirect = (announcement) => {
        navigate("/announcement-details", { state: announcement });
    }

    const handlePageChange = (paginateNext) => {
        setQueryArray([limit(10)]);
        // set current page when next or previous is clicked
        if (paginateNext) {
            setCurrentPage((prev) => prev + 1);
            setQueryArray([limit(10), startAfter(prevSnap.next)]);
        } else {
            setCurrentPage((prev) => prev - 1);
            setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
        }
        setLoading(true);
    };

    const handleFilters = (e) => {
        setOrderQueryArray([orderBy("date", "desc")]);
        setQueryArray([limit(10)]);

        if(e.target.value === "desc") {
            setOrderQueryArray([orderBy("date", "desc")]);
        } else if (e.target.value === "asc") {
            setOrderQueryArray([orderBy("date", "asc")]);
        } else if (e.target.value === "priority") {
            setOrderQueryArray([orderBy("priority", "desc")]);
        }
        
        setLoading(true);
    }

    const handleSearch = (searchKey = "") => {
        setSearchQueryArray([where("title", ">=", "")]);
        setQueryArray([limit(10)]);

        setSearchQueryArray([or(
                                and(
                                    where("title", ">=", searchKey.toLocaleLowerCase()),
                                    where("title", "<=", searchKey.toLocaleLowerCase() + "\uf8ff")
                                    )
                                )
                            ]);
        setCurrentPage(1);
        setLoading(true);
    }

    return (
        <div>
            <Navigation />
            <RightSideBar title="Announcements">
                <div>
                    <div className="my-10">
                        <div className="text-xl font-bold mb-2">SEARCH ALL ANNOUNCEMENTS</div>
                        <input onChange={(e) => handleSearch(e.target.value)}className="mb-4 p-2 font-medium rounded-sm shadow-lg ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full" name="search" placeholder="Find group" />
                        <a href="#" className="text-center rounded-lg bg-[#F1BD4E] px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]" type="button">SEARCH</a>
                    </div>
                    <div className="flex gap-10">
                        <div>
                            <div className="text-xl font-bold mb-2">SORT BY</div>
                            <select onChange={(e) => handleFilters(e)} className="mb-4 p-2 font-medium rounded-sm shadow-lg ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full" name="filter">
                                <option value="desc">Newest Post</option>
                                <option value="asc">Oldest Post</option>
                                <option value="priority">Priority</option>
                            </select>
                        </div>
                    </div>
                    <div className="my-10">
                        {loading ? 
                            <>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            <div className="grid gap-2 my-5 animate-pulse">
                                <div className="w-80 bg-gray-200 h-4 rounded-full"></div>
                                <div className="w-60 bg-gray-200 h-2 rounded-full"></div>
                            </div>
                            </>
                        :
                            <>
                            {announcementData.map((announcement) => 
                                <div className="grid gap-2 my-5" onClick={() => clickRedirect(announcement)}>
                                    <div className="text-2xl capitalize cursor-pointer hover:underline">{announcement.title}</div>
                                    <div className="text-base italic">{moment(announcement.date).format("DD MMM YYYY")}</div>
                                </div>
                            )}
                            </>
                        }
                        {(loading == false && announcementData.length == 0) && 
                            <div className="text-left text-2xl font-bold text-gray-500 flex items-center"><IoMdAlert className="mr-2 text-red-500" /> No announcement available.</div>
                        }
                    </div>

                    {totalPages > 0 &&
                        <div className="flex items-center justify-between mt-6">
                            <div></div>
                            <div className="items-center hidden lg:flex gap-x-3">
                                <button onClick={() => handlePageChange(false)} disabled={currentPage === 1} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"><FaCaretLeft /></button>
                                Page {currentPage} of {totalPages}
                                <button onClick={() => handlePageChange(true)} disabled={currentPage === totalPages} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"><FaCaretRight /></button>
                            </div>
                            <div></div>
                        </div>
                    }
                </div>
            </RightSideBar>
            <Footer />
        </div>
    )
}

export default Announcement