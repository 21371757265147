import React, { useContext, useState } from "react";
import './../../App.css';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import RightSideBar from '../../components/RightSideBar';
import Discussions from '../../components/community_forum_details/Discussions';
import Members from '../../components/community_forum_details/Members';
import { IoIosArrowBack } from "react-icons/io";

import { useLocation, useNavigate } from 'react-router-dom';

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

//import moment for date formatting
import moment from "moment/moment";

const CommunityForumDetails = () => {
    const auth = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const forumGroupDetails = JSON.parse(location.state);

    // true is discussion tab while false is members tab
    const [tabActive, setTabActive] = useState(true);

    const checkIfMember = () => {
        let result = false;
        if(forumGroupDetails.members) {
            if(forumGroupDetails.members.includes(auth.userId)) {
                result = true;
            }
        }

        return result;
    }

    return (
        <div>
            <Navigation />
            <RightSideBar title="Community Forum">
                {checkIfMember() ? 
                <>
                    <div className="pt-2 mb-10">
                        <div className="text-xl mb-6 text-blue-500 underline text-left flex items-center cursor-pointer w-80" onClick={() => navigate(-1)}><IoIosArrowBack /> BACK TO PREVIOUS PAGE</div>
                        <div className="text-3xl mb-5 font-bold text-blue-500 capitalize">{forumGroupDetails.title}</div>
                        <div className="md:flex gap-10">
                            <img className="object-cover object-center h-[150px] w-[150px]" src={forumGroupDetails.image_url} />
                            <div className="md:w-11/12">
                                <div className="text-base font-bold flex gap-5 text-gray-400">
                                    <div>{(forumGroupDetails.members) ? forumGroupDetails.members.length : "0"} MEMBERS</div>
                                    <div className="uppercase">{forumGroupDetails.status}</div>
                                </div>
                                <div className="text-base font-bold mt-2 mb-5 capitalize">CREATED: {moment(forumGroupDetails.date).format("DD MMM YYYY")}</div>
                                <div className="text-base">{forumGroupDetails.body}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-5 border-y-black/[.10] border-y-4 py-2">
                        <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
                            <button onClick={() => {setTabActive(true)}} className="px-5 py-2 text-base font-bold text-gray-600 transition-colors duration-200 bg-gray-100">
                                DISCUSSIONS
                            </button>

                            <button onClick={() => {setTabActive(false)}} className="px-5 py-2 text-base font-bold text-gray-600 transition-colors duration-200 hover:bg-gray-100">
                                GROUP MEMBERS
                            </button>
                        </div>
                    </div>
                    {tabActive ?
                        <Discussions forumGroupDetails={forumGroupDetails} />
                    :
                        <Members memberIds={forumGroupDetails.members} />
                    }
                </>
                : <div className="py-10 text-3xl font-bold">You are not a member of this group.</div>}
            </RightSideBar>
            <Footer />
        </div>
    )
}

export default CommunityForumDetails