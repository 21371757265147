import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { Navigate } from "react-router-dom";

const ProtectedView = ({ children, requiredRole }) => {
  const auth = useContext(AuthContext);

  // Check if user is logged in
  if (!auth.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // Validate role if required
  if (requiredRole && auth.role !== requiredRole) {
    return <Navigate to="/dashboard" />; // Redirect unauthorized users
  }

  return children;
};

export default ProtectedView;
