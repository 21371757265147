import React, { useEffect, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { FaRegEdit, FaRegTrashAlt, FaPlus } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import MessageModal from "../../components/modals/public/MessageModal";
import TeamFormModal from "../../components/modals/admin/TeamFormModal";
import LeftSideBar from "../../components/LeftSideBar";

//import firebase functions for fetching users
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  deleteDoc,
  or,
  and,
  where,
} from "firebase/firestore";

const TeamManagement = () => {
    const [teamFormModal, setTeamFormModal] = useState(false);
    const [createMember, setCreateMember] = useState(true);
    const [teamMemberData, setTeamMemberData] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false);

  const [searchQueryArray, setSearchQueryArray] = useState([
    where("first_name", ">=", ""),
  ]);

  const fetchData = async () => {
    // executes and fetches data based on query
    const teamSnap = await getDocs(
      query(collection(db, "team"), ...searchQueryArray)
    );
    // set forum category data array for display
    setTeamMembers(teamSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [loading]);

    const onDelete = (memberData) => {
        setTeamMemberData(memberData);
        setDeleteModal(true);
        setDeleteMessage(true);
    }

    const onEdit = (memberData) => {
        setTeamMemberData(memberData);
        setCreateMember(false);
        setTeamFormModal(true);
        setDeleteMessage(false);
    }

    const modalFunction = async () => {
        await deleteDoc(doc(db, "team", teamMemberData.id));
        setDisplaySuccessModal(true);
        setLoading(true);
    }

    const modalClose = () => {
        setDeleteMessage(false);
    };

  const handleSearchQueries = (searchKey) => {
    setSearchQueryArray([where("first_name", ">=", "")]);
    searchKey &&
      setSearchQueryArray([
        or(
          and(
            where("first_name", ">=", searchKey),
            where("first_name", "<=", searchKey + "\uf8ff")
          ),
          and(
            where("last_name", ">=", searchKey),
            where("last_name", "<=", searchKey + "\uf8ff")
          ),
          and(
            where("full_name", ">=", searchKey),
            where("full_name", "<=", searchKey + "\uf8ff")
          )
        ),
      ]);
    setLoading(true);
  };

    return (
        <div>
            <Navigation />
            <LeftSideBar>
                <div className="grid place-items-center">
                    <div className="container text-left my-10">
                        <div className="text-4xl">Team Management</div>
                        <div className="mt-6 md:flex md:items-center md:justify-between">
                            <button onClick={() => {setTeamFormModal(true); setCreateMember(true); setTeamMemberData([]); setDeleteMessage(false);}} className="inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-800 hover:bg-emerald-700 sm:w-auto flex items-center gap-2">
                                <FaPlus /> New team member
                            </button>
                            <div className="relative flex items-center md:mt-0">
                                <span className="absolute">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mx-3 text-gray-400"
                                    >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                    </svg>
                                </span>

                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    onChange={(e) =>
                                        handleSearchQueries(e.target.value.toLowerCase())
                                    }
                                    className="block py-1.5 pr-5 text-gray-700 bg-white border border-gray-600 rounded-lg md:w-80 placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                />
                            </div>
                        </div>
                        <div className="my-10 overflow-x-scroll w-[300px] sm:w-[590px] md:w-full">
                            <table className="min-w-min md:min-w-fit divide-y divide-gray-200 md:w-[100%]">
                                <thead className="bg-blue-100">
                                    <tr>
                                        <th scope="col" className="py-4 px-4 text-sm font-bold text-left rtl:text-right">
                                            Name
                                        </th>

                                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                                            Role
                                        </th>

                                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                                            Description
                                        </th>

                                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {loading ?
                                        <>
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                                </td>
                                            </tr>
                                        </>
                                        :
                                        teamMembers.map((memberInfo, index) => 
                                            <tr>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                                                    {memberInfo.first_name} {memberInfo.last_name}
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    {memberInfo.role}
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    {memberInfo.description.slice(0, 50 - 1) + "..."}
                                                </td>
                                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                                    <div className="flex items-center gap-x-2">
                                                        <button onClick={(e) => onDelete(memberInfo)} className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-red-800 transition-colors duration-200 hover:bg-red-600 focus:outline-none" aria-label="Delete">
                                                            <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">Delete</span>
                                                            <FaRegTrashAlt id={`${index}`} />
                                                        </button>
                                                        <button onClick={(e) => onEdit(memberInfo)} className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-teal-800 transition-colors duration-200 hover:bg-teal-600 focus:outline-none" aria-label="Edit">
                                                            <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">Edit</span>
                                                            <FaRegEdit />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {(teamMembers.length <= 0 && loading == false) &&
                                        <tr>
                                            <td colSpan={4} className="text-center"><div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center"><IoMdAlert className="mr-2 text-red-500" /> No team member available.</div></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </LeftSideBar>
            <TeamFormModal createMember={createMember} teamMemberData={teamMemberData} setTeamMemberData={setTeamMemberData} displayModal={teamFormModal} setDisplayModal={setTeamFormModal} fetchTableData={fetchData} setLoadingTable={setLoading} displaySuccessModal={setDisplaySuccessModal} />
            <MessageModal title={'Delete team member?'} description={'Are you sure you want to delete this team member?'} buttonText={'Delete'} gif={null} approve={false} displayModal={deleteModal} setDisplayModal={setDeleteModal} functionPassed={modalFunction} />
            <MessageModal title={"Action Successful"} description={`Team member has been successfully ${deleteMessage ? "deleted" : createMember ? "created" : "updated"}.`} buttonText={"Close"} gif={"success"} approve={true} displayModal={displaySuccessModal} setDisplayModal={setDisplaySuccessModal} functionPassed={modalClose} />
            <Footer />
    </div>
  );
};

export default TeamManagement;
