import React from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { IoIosArrowBack } from "react-icons/io";

import { useLocation, useNavigate } from "react-router-dom";

const TeamDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const teamMemberDetail = location.state;
  return (
    <div>
      <Navigation />
      <section className="grid justify-items-center">
        <div className="container">
          <div
            className="text-xl mt-6 text-blue-500 underline text-left flex items-center cursor-pointer w-80"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack /> BACK TO PREVIOUS PAGE
          </div>
          <div className="flex items-center">
            <img className="my-10 h-80" src={teamMemberDetail.image_url} />
            <div className="my-10 text-left ml-10">
              <div className="font-bold text-2xl capitalize">
                {teamMemberDetail.first_name} {teamMemberDetail.last_name}
              </div>
              <div className="font-bold text-xl text-[#1C75BC]">
                {teamMemberDetail.role}
              </div>
            </div>
          </div>
          <div className="bg-[#00A5E552] h-1.5 w-full self-end mb-10"></div>
          <div className="mt-5 text-left whitespace-pre-line">
            {teamMemberDetail.description}
          </div>
          <div className="bg-[#00A5E552] h-1.5 w-full self-end my-10"></div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TeamDetails;
