import React, { useContext, useEffect, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

const Profile = () => {
  const auth = useContext(AuthContext);
  //set user data using local storage (user info)
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div>
      <Navigation />
      <div className="grid place-items-center">
        <div className="container text-left my-20">
          <form className="grid gap-3 p-10 md:p-0">
            <div className="text-base grid md:flex md:items-center w-full justify-items-center gap-10">
              <div>
                {userData.profile_image_url != null ? (
                  <img
                    src={userData.profile_image_url}
                    className="w-60 h-60 object-cover rounded-full border-[8px] border-gray-200"
                    alt=""
                  />
                ) : (
                  <FaRegCircleUser className="text-9xl text-gray-400" />
                )}
              </div>
              <div className="flex-1 text-center md:text-left">
                <span className="font-bold text-4xl">
                  {userData.first_name} {userData.last_name}
                </span>
                <br />
                <span className="font-bold capitalize">{userData.gender}</span>
              </div>
              <div className="justify-self-end">
                <a
                  href="/edit-profile"
                  className="inline-flex gap-2 items-center text-blue-500"
                >
                  <FaRegEdit /> Edit
                </a>
              </div>
            </div>
            <div className="bg-[#00a5e580] h-1.5 w-full self-end my-2"></div>
            <div className="text-2xl font-bold">Contact Information</div>
            <div className="text-base grid gap-2 my-5">
              <span className="font-bold text-xl">Email</span>
              <span className="text-xl">{userData.email}</span>
            </div>
            <div className="text-base grid gap-2 my-2">
              <span className="font-bold text-xl">Phone</span>
              <span className="text-xl">{userData.phone_number}</span>
            </div>
            <div className="text-base grid gap-2 my-2">
              <span className="font-bold text-xl">Address</span>
              <span className="text-xl capitalize">
                {userData.address}, &nbsp;
                {userData.suburb}, {userData.postcode}
              </span>
            </div>
            <div className="text-2xl font-bold">Volunteering Preferences</div>
            <div className="text-base grid gap-2 my-2">
              <span className="font-bold text-xl">
                Are you willing to volunteer?
              </span>
              <span className="text-xl capitalize">
                {userData.is_willing_to_volunteer || "No"}
              </span>
            </div>
            <div className="text-base grid gap-2 my-2">
              <span className="font-bold text-xl">
                Which volunteering group are you interested in?
              </span>
              <div className="flex gap-2">
                {userData.groups_interested_in == undefined && (
                  <span className="text-xl capitalize">None</span>
                )}
                {userData.groups_interested_in?.map((group) => (
                  <div className="rounded-full bg-gray-200 p-2 capitalize flex hover:bg-gray-300">
                    {group}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-base grid gap-2 my-2">
              <span className="font-bold text-xl">Availability</span>
              <div className="flex gap-2">
                {userData.availability == undefined && (
                  <span className="text-xl capitalize">None</span>
                )}
                {userData.availability?.map((group) => (
                  <div className="rounded-full bg-gray-200 p-2 capitalize flex hover:bg-gray-300">
                    {group}
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
