import React, { useEffect, useState } from "react";
import "./../../App.css";
import { FaRegEdit, FaRegTrashAlt, FaPlus, FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import MessageModal from "../../components/modals/public/MessageModal";
import ForumCategoryFormModal from "../../components/modals/admin/ForumCategoryFormModal";

//import firebase functions for forum categories
import { db } from "../../firebase";
import { collection, getDocs, doc, query, limit, limitToLast, deleteDoc, getCountFromServer, startAfter, endBefore, orderBy, or, and, where } from "firebase/firestore";

const CommunityForum = () => {
    const [categoriesData, setCategoriesData] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevSnap, setPrevSnap] = useState([]);

    const [displayModal, setDisplayModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
    const [forumCategorySelected, setForumCategorySelected] = useState([]);

    const [forumCategoryModal, setForumCategoryModal] = useState(false);
    const [createFormCategoryForm, setCreateFormCategoryForm] = useState(false);

    const [loading, setLoading] = useState(true);

    const [queryArray, setQueryArray] = useState([limit(10)]);
    const [searchQueryArray, setSearchQueryArray] = useState([where("category_name", ">=", "")]);
    const [orderQueryArray, setOrderQueryArray] = useState([orderBy("category_name", "asc")]);

    const fetchData = async () => {
        // creates query for forum category pagination or filtering
        const forumCategoryQuery = query(collection(db, "forum_categories"), ...orderQueryArray, ...queryArray, ...searchQueryArray);
        // executes and fetches data based on query
        const forumCategorySnap = await getDocs(forumCategoryQuery);
        // set previous snap
        setPrevSnap({next: forumCategorySnap.docs[9], prev: forumCategorySnap.docs[0]});
        // set forum category data array for display
        setCategoriesData(forumCategorySnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        // pagination number
        const forumCategoryCount = await getCountFromServer(query(collection(db, "forum_categories")), ...searchQueryArray);
        setTotalPages(Math.ceil(forumCategoryCount.data().count / 10));
        // set loading animations to false
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [loading]);

    const onDelete = (category) => {
        setForumCategorySelected(category);
        setDeleteMessage(true);
        setDisplayModal(true);
    };

    const onEdit = (category) => {
        setCreateFormCategoryForm(false);
        setForumCategorySelected(category);
        setForumCategoryModal(true);
        setDeleteMessage(false);
    };

    const modalFunction = async () => {
        await deleteDoc(doc(db, "forum_categories", forumCategorySelected.id));
        setDisplaySuccessModal(true);
        setLoading(true);
    };

    const modalClose = () => {
        setDeleteMessage(false);
    };

    const handlePageChange = (paginateNext) => {
        setQueryArray([limit(10)]);
        // set current page when next or previous is clicked
        if(paginateNext) {
            setCurrentPage((prev) => prev+1);
            setQueryArray([limit(10), startAfter(prevSnap.next)]);
        } else {
            setCurrentPage((prev) => prev-1);
            setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
        }
        setLoading(true);
    }

    const handleSearchQueries = (searchKey) => {
        setSearchQueryArray([where("category_name", ">=", "")]);
        setQueryArray([limit(10)]);
        
        searchKey &&
            setSearchQueryArray([
                or(
                    and(
                        where("category_name", ">=", searchKey),
                        where("category_name", "<=", searchKey + "\uf8ff")
                    )
                )
            ]);
        
        setCurrentPage(1);
        setLoading(true);
    };

    const handleFilters = (e) => {
        setOrderQueryArray([]);
        setQueryArray([limit(10)]);

        if(e.target.value === "asc") {
            setOrderQueryArray([orderBy("category_name", "desc")]);
        } else if (e.target.value === "desc") {
            setOrderQueryArray([orderBy("category_name", "asc")]);
        }

        setCurrentPage(1);
        setLoading(true);
    };

    return (
        <>
        <div className="mt-6 md:flex md:items-center md:justify-between">
            <button onClick={() => {setCreateFormCategoryForm(true); setForumCategorySelected([]); setForumCategoryModal(true); setDeleteMessage(false);}} className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-800 hover:bg-emerald-700 sm:w-auto flex items-center gap-2">
                <FaPlus /> New category
            </button>
        </div>
        
        <div className="mt-6 md:flex md:items-center md:justify-between">
            <select onChange={(e) => handleFilters(e)} className="border border-gray-600 text-base rounded-lg focus:ring-blue-700 focus:border-blue-700 block w-auto p-2.5" name="filter" aria-label="Sort">
                <option value="desc">A-Z</option>
                <option value="asc">Z-A</option>
            </select>
            <div className="relative flex items-center md:mt-0">
                <span className="absolute">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mx-3 text-gray-400"
                    >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                    </svg>
                </span>

                <input
                    type="text"
                    placeholder="Search by category name"
                    onChange={(e) =>
                        handleSearchQueries(e.target.value.toLowerCase())
                    }
                    className="block py-1.5 pr-5 text-gray-700 bg-white border border-gray-600 rounded-lg md:w-80 placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
            </div>
        </div>
        <div className="my-10">
            <table className="w-full divide-y divide-gray-200">
                <thead className="bg-blue-100">
                    <tr>
                        <th scope="col" className="py-4 px-4 text-sm font-bold text-left rtl:text-right">
                            Name
                        </th>

                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {loading ?
                        <>
                            <tr className="animate-pulse">
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                            </tr>
                            <tr className="animate-pulse">
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                            </tr>
                            <tr className="animate-pulse">
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                            </tr>
                            <tr className="animate-pulse">
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                            </tr>
                            <tr className="animate-pulse">
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                                </td>
                            </tr>
                        </>
                    :
                        categoriesData.map((category, index) =>
                            <tr>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                                    {category.category_name}
                                </td>
                                <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                    <div className="flex items-center gap-x-2">
                                        <button onClick={(e) => onDelete(category)} className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-red-800 transition-colors duration-200 hover:bg-red-600 focus:outline-none" aria-label="Delete">
                                            <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">Delete</span>
                                            <FaRegTrashAlt id={`${index}`} />
                                        </button>
                                        <button onClick={(e) => onEdit(category)} className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-teal-800 transition-colors duration-200 hover:bg-teal-600 focus:outline-none" aria-label="Edit">
                                            <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">Edit</span>
                                            <FaRegEdit />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    {(categoriesData.length <= 0 && loading == false) &&
                        <tr>
                            <td colSpan={5} className="text-center"><div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center"><IoMdAlert className="mr-2 text-red-500" /> No forum category available.</div></td>
                        </tr>
                    }
                </tbody>
            </table>
            {(totalPages > 0 && loading == false) &&
                <div className="flex items-center justify-between mt-6">
                    <div></div>
                    <div className="items-center hidden lg:flex gap-x-3">
                        <button onClick={() => handlePageChange(false)} disabled={currentPage === 1} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60" aria-label="Next"><FaCaretLeft /></button>
                        Page {currentPage} of {totalPages}
                        <button onClick={() => handlePageChange(true)} disabled={currentPage === totalPages} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60" aria-label="Previous"><FaCaretRight /></button>
                    </div>
                    <div></div>
                </div>
            }
        </div>
        <ForumCategoryFormModal createForumCategory={createFormCategoryForm} id={null} forumCategoryData={forumCategorySelected} setforumCategoryData={setForumCategorySelected} displayModal={forumCategoryModal} setDisplayModal={setForumCategoryModal} setLoadingTable={setLoading} displaySuccessModal={setDisplaySuccessModal} />
        <MessageModal title={"Delete Forum Category?"} description={"Are you sure you want to delete this forum category?"} buttonText={"Delete"} gif={null} approve={false} displayModal={displayModal} setDisplayModal={setDisplayModal} functionPassed={modalFunction} />
        <MessageModal title={"Action Successful"} description={` Category has been successfully ${deleteMessage ? "deleted" : createFormCategoryForm ? "created" : "updated"}.`} buttonText={"Close"} gif={"success"} approve={true} displayModal={displaySuccessModal} setDisplayModal={setDisplaySuccessModal} functionPassed={modalClose} />
        </>
    )
}

export default CommunityForum;