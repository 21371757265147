import React, { useState, useMemo, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
import { FixedSizeList as List } from "react-window";

import "./../../App.css";

const VirtualizedDropdown = ({ data, onSelect, selectedData }) => (
  <List
    height={200} // Dropdown height
    itemCount={data.length}
    itemSize={35} // Height of each item
    width="100%"
  >
    {({ index, style }) => (
      <div
        style={style}
        className={`p-2 cursor-pointer ${
          selectedData?.suburb === data[index].suburb &&
          selectedData?.postcode === data[index].postcode
            ? "bg-gray-200 font-bold"
            : "hover:bg-gray-100"
        }`}
        onClick={() => onSelect(data[index])}
      >
        {`${data[index].suburb} (${data[index].postcode})`}
      </div>
    )}
  </List>
);

// capitalize data when display
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const SearchableSelectSub = ({
  data,
  placeholder,
  onSelectionChange,
  selectedSuburb,
}) => {
  const [search, setSearch] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [activeSelect, setActiveSelect] = useState(false);

  // Synchronize selectedData with selectedSuburb prop
  useEffect(() => {
    if (selectedSuburb) {
      setSelectedData({
        ...selectedSuburb,
        suburb: capitalizeWords(selectedSuburb.suburb),
      });
    }
  }, [selectedSuburb]);

  // Filter data based on debounced search value
  const searchDebounced = useDebounce(search, 300); // Delay of 300ms

  const filteredData = useMemo(() => {
    if (!searchDebounced) return data;
    const lowerSearch = searchDebounced.toLowerCase();
    return data.filter(
      (item) =>
        item.suburb.toLowerCase().includes(lowerSearch) ||
        item.postcode.includes(lowerSearch)
    );
  }, [searchDebounced, data]);

  const onSelect = (item) => {
    if (
      selectedData &&
      selectedData.suburb === item.suburb &&
      selectedData.postcode === item.postcode
    ) {
      // If the same item is selected, unselect it
      setSelectedData(null);
      onSelectionChange(null);
    } else {
      setSelectedData(item);
      onSelectionChange(item);
    }
    setActiveSelect(false);
  };

  return (
    <div className="relative inline-block text-left group z-[5] w-full mt-2">
      <div>
        <button
          type="button"
          onClick={() => setActiveSelect(!activeSelect)}
          className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center"
        >
          {selectedData
            ? `${selectedData.suburb} (${selectedData.postcode})`
            : placeholder || "Select suburb and postal code"}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400 font-medium"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {activeSelect && (
        <div className="absolute z-10 w-full bg-white border border-gray-300 max-h-60 overflow-y-auto">
          <input
            className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search suburbs or postcodes"
          />
          <VirtualizedDropdown
            data={filteredData}
            onSelect={onSelect}
            selectedData={selectedData}
          />
        </div>
      )}
    </div>
  );
};

export default SearchableSelectSub;
