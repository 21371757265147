export const isEmail = (value) => {
  return value.includes("@");
};

export const isNotEmpty = (value) => {
  return value.trim() !== "";
};

export const minLength = (value, min_length) => {
  return value.length >= min_length;
};

export const hasUpperCase = (value) => {
  return /[A-Z]/.test(value);
};

export const hasLowerCase = (value) => {
  return /[a-z]/.test(value);
};

export const hasNumber = (value) => {
  return /\d/.test(value);
};

export const hasSpecialCharacter = (value) => {
  // This regex matches any special character (non-alphanumeric)
  return /[!@#$%^&*(),.?":{}|<>]/.test(value);
};

export const confirmPassword = (pval, cpval) => {
  return pval === cpval;
};
