import React, { useState, useEffect } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import RightSideBar from "../../components/RightSideBar";
import { db } from "../../firebase";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import {
  collection,
  getDocs,
  query,
  limit,
  limitToLast,
  startAfter,
  orderBy,
  where,
  getCountFromServer,
  endBefore
} from "firebase/firestore";

const CommunityDirectory = () => {
  const [data, setData] = useState([]);

  // Fetch data of organization in firestore
  const [orgCategoryData, setOrgCategoryData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [lastVisible, setLastVisible] = useState();
  const [firstVisible, setFirstVisible] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchOrgData = async (searchKey = false, isNextPage = null) => {
    setIsLoading(true);
    try {
      const orgDataCollection = collection(db, "org_data");
      let q;

      if (searchKey) {
        setData([]);
        setCurrentPage(1);
        setLastVisible(null);
      }

      const lowerCaseSearchKey = searchTerm.toLowerCase();

      if (searchTerm && selectedCategory) {
        q = query(
          orgDataCollection,
          where("org_name", ">=", lowerCaseSearchKey),
          where("org_name", "<=", lowerCaseSearchKey + "\uf8ff"),
          where("org_ctgry_id", "==", selectedCategory),
          orderBy("org_name"),
          limit(10)
        );
      } else if (searchTerm) {
        q = query(
          orgDataCollection,
          where("org_name", ">=", lowerCaseSearchKey),
          where("org_name", "<=", lowerCaseSearchKey + "\uf8ff"),
          orderBy("org_name"),
          limit(10)
        );
      } else if (selectedCategory) {
        q = query(
          orgDataCollection,
          where("org_ctgry_id", "==", selectedCategory),
          orderBy("org_name"),
          limit(10)
        );
      } else {
        q = query(orgDataCollection, orderBy("org_name"), limit(10));
      }

      if (isNextPage === true && lastVisible) {
        q = query(q, startAfter(lastVisible), limit(10));
      } else if (isNextPage === false && firstVisible) {
        q = query(q, endBefore(firstVisible), limitToLast(10));
        // Logic for handling "previous" would go here, but Firestore does not natively support going backward.
        // Instead, you'll need to cache previous pages or refetch from the start.
      }

      const querySnapshot = await getDocs(q);

      const newData = querySnapshot.docs.map((d) => {
        const data = d.data();
        return {
          id: d.id,
          ...data,
          org_name: capitalizeWords(data.org_name || ""),
          org_add: capitalizeWords(data.org_add || ""),
          org_cont_per: capitalizeWords(data.org_cont_per || ""),
        };
      });

      setData(newData);

      if (!querySnapshot.empty) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
      }

      const countQuery =
        searchTerm && selectedCategory
          ? query(
              orgDataCollection,
              where("org_name", ">=", searchTerm),
              where("org_name", "<=", searchTerm + "\uf8ff"),
              where("org_ctgry_id", "==", selectedCategory)
            )
          : searchTerm
          ? query(
              orgDataCollection,
              where("org_name", ">=", searchTerm),
              where("org_name", "<=", searchTerm + "\uf8ff")
            )
          : selectedCategory
          ? query(
              orgDataCollection,
              where("org_ctgry_id", "==", selectedCategory)
            )
          : orgDataCollection;

      const orgCount = await getCountFromServer(query(countQuery));
      setTotalPages(Math.ceil(orgCount.data().count / 10));
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debounceSearch = debounce(() => {
    fetchOrgData(true);
  }, 500);

  useEffect(() => {
    handleCategory();
    debounceSearch();
    return debounceSearch.cancel;
  }, [searchTerm, selectedCategory]);

  const handleCategory = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "org_catgry"), orderBy("ctgry_name"))
    );
    const newData = querySnapshot.docs.map((d) => {
      const data = d.data();
      return {
        id: d.id,
        ...data,
        ctgry_name: capitalizeWords(data.ctgry_name || ""),
      };
    });
    setOrgCategoryData(newData);
  };

  const handlePageChange = (isNextPage) => {
    if (isNextPage && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      fetchOrgData(false, true); // Fetch next page
    } else if (!isNextPage && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      fetchOrgData(false, false); // Fetch previous page (if needed)
    }
  };

  return (
    <div>
      <Navigation />
      <RightSideBar title="Community Directory">
        <img className="my-10" src={"/assets/user/community-directory.jpeg"} />
        <div className="bg-black/[.10] h-1.5 w-1/2 self-end my-10"></div>
        <div className="text-4xl font-bold my-10">
          Connect Eurobodalla Directory
        </div>
        <div className="mt-6 md:flex md:items-center md:justify-between">
          <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
            <select
              className="border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="filter"
              onChange={(e) => setSelectedCategory(e.target.value)}
              value={selectedCategory}
            >
              <option value={""}>All</option>
              {orgCategoryData.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.ctgry_name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="relative flex items-center mt-4 md:mt-0">
            <span className="absolute">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 mx-3 text-gray-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </span>

            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
        </div>
        <div className="my-10 overflow-x-scroll w-[300px] sm:w-[590px] md:w-full">
          <table className="min-w-min md:min-w-fit divide-y divide-gray-200 md:w-[100%]">
            <thead className="bg-blue-100">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 px-4 text-sm font-bold text-left rtl:text-right"
                >
                  Organisation
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Phone
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right w-2"
                >
                  Email address
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right w-6"
                >
                  Website
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Contact Person
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {isLoading ? (
                <>
                  <tr>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                    <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                      <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                    </td>
                  </tr>
                </>
              ) : (
                data.map((org) => (
                  <tr key={org.id}>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-words">
                      {org.org_name || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-all">
                      {org.org_add || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-words">
                      {org.org_cont_num || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-all">
                      {org.org_email || "N/A"}
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-all">
                      <a
                        className="text-blue-500 cursor-pointer hover:underline"
                        href={org.org_website}
                      >
                        {org.org_website || ""}
                      </a>
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-600 w-[16.6%] break-words">
                      {org.org_cont_per || "N/A"}
                    </td>
                  </tr>
                ))
              )}
              {data.length <= 0 && isLoading === false && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center">
                      <IoMdAlert className="mr-2 text-red-500" /> No
                      organisation available.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="flex items-center justify-between mt-6">
            <div></div>
            <div className="items-center hidden lg:flex gap-x-3">
              <button
                onClick={() => handlePageChange(false)}
                disabled={currentPage === 1}
                className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              >
                <FaCaretLeft />
              </button>
              Page {currentPage} of {totalPages}
              <button
                onClick={() => handlePageChange(true)}
                disabled={currentPage === totalPages}
                className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              >
                <FaCaretRight />
              </button>
            </div>
            <div></div>
          </div>
        </div>
      </RightSideBar>
      <Footer />
    </div>
  );
};

export default CommunityDirectory;
