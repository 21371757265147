import React, { useState, useEffect } from "react";
import "./../../App.css";
import {
  FaUserCog,
  FaRegEdit,
  FaCaretRight,
  FaCaretLeft,
  FaPlus,
  FaFileExcel,
} from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

import MessageModal from "../modals/public/MessageModal";
import UserFormModal from "../modals/admin/UserFormModal";
import { useLocation } from "react-router-dom";

//import firebase functions for fetching users
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  limit,
  limitToLast,
  deleteDoc,
  getCountFromServer,
  startAfter,
  endBefore,
  orderBy,
  where,
  and,
  or,
} from "firebase/firestore";

//export table to xlsx (excel sheet)
import * as XLSX from "xlsx/xlsx";

const UserTable = () => {
  const [userData, setUserData] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [editUserData, setEditUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    organization: "",
    role: "",
    status: "",
  });
  const [deleteSelected, setDeleteSelected] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevSnap, setPrevSnap] = useState([]);

  const [createUserForm, setCreateUserForm] = useState(false);

  const [filterTab, setFilterTab] = useState("all");
  const [searchKey, setSearchKey] = useState("");
  const [dataTab, setDataTab] = useState("user management");

  const [queryArray, setQueryArray] = useState([limit(10)]);
  const [searchQueryArray, setSearchQueryArray] = useState([
    where("full_name", ">=", ""),
  ]);

  const location = useLocation();

  const fetchData = async () => {
    // creates query for user pagination or filtering
    const userQuery = query(
      collection(db, "users"),
      orderBy("full_name"),
      ...queryArray,
      ...searchQueryArray
    );
    // executes and fetches data based on query
    const userSnap = await getDocs(userQuery);
    // executes and fetches data based on query
    setPrevSnap({ next: userSnap.docs[9], prev: userSnap.docs[0] });
    // set user data array for display
    setUserData(userSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
    // pagination number
    const userCount = await getCountFromServer(
      query(collection(db, "users"), ...searchQueryArray)
    );
    setTotalPages(Math.ceil(userCount.data().count / 10));
    // set loading animations to false
    setLoading(false);
  };

  useEffect(() => {
    tabNavigation();
    fetchData();
  }, [location, loading]);

  const tabNavigation = () => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("dataTab");
    if (tab) {
      setDataTab(tab);
      handleSearchQueries(tab);
    }
  };

  const handleSearchQueries = (filterTab, searchKey) => {
    setSearchQueryArray([where("full_name", ">=", "")]);
    setQueryArray([limit(10)]);

    if ((filterTab == "active" || filterTab == "inactive") && searchKey == "") {
      setSearchQueryArray([
        where("status", "==", filterTab == "active" ? true : false),
      ]);
    } else if (
      filterTab == "active" ||
      (filterTab == "inactive" && searchKey != "")
    ) {
      searchKey &&
        setSearchQueryArray([
          or(
            and(
              where("full_name", ">=", searchKey),
              where("full_name", "<=", searchKey + "\uf8ff"),
              where("status", "==", filterTab == "active" ? true : false)
            ),
            and(
              where("organization", ">=", searchKey),
              where("organization", "<=", searchKey + "\uf8ff"),
              where("status", "==", filterTab == "active" ? true : false)
            )
          ),
        ]);
    } else if (filterTab == "all" && searchKey != "") {
      searchKey &&
        setSearchQueryArray([
          or(
            and(
              where("full_name", ">=", searchKey),
              where("full_name", "<=", searchKey + "\uf8ff")
            ),
            and(
              where("organization", ">=", searchKey),
              where("organization", "<=", searchKey + "\uf8ff")
            )
          ),
        ]);
    }

    setCurrentPage(1);
    setLoading(true);
  };

  const modalFunction = async () => {
    setLoading(false);
    //delete user document
    await deleteDoc(doc(db, "users", deleteSelected));
    fetchData();
  };

  const modalClose = () => {};

  const handlePageChange = (paginateNext) => {
    setQueryArray([limit(10)]);
    // set current page when next or previous is clicked
    if (paginateNext) {
      setCurrentPage((prev) => prev + 1);
      setQueryArray([limit(10), startAfter(prevSnap.next)]);
    } else {
      setCurrentPage((prev) => prev - 1);
      setQueryArray([limitToLast(10), endBefore(prevSnap.prev)]);
    }
    setLoading(true);
  };

  const exportDataToExcel = async () => {
    const userSnap = await getDocs(
      query(collection(db, "users"), orderBy("full_name"))
    );
    const userSheetData = userSnap.docs.map((d) => ({
      "First name": d.data().first_name,
      "Last name": d.data().last_name,
      Email: d.data().email,
      Phone: d.data().phone_number,
      Role: d.data().role,
      Status: d.data().status ? "Active" : "Inactive",
      Organisation: d.data().organization,
      "Reason for joining": d.data().reasons_for_joining,
    }));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(userSheetData);

    XLSX.utils.book_append_sheet(wb, ws, "User Data");
    XLSX.writeFile(wb, "User Data " + new Date() + ".xlsx");
  };

  return (
    <div>
      <div className="mt-6 md:flex md:items-center md:justify-between">
        {dataTab == "user management" && (
          <div className="inline-flex bg-white border divide-x rounded-lg rtl:flex-row-reverse">
            <button
              onClick={() => {
                setFilterTab("all");
                handleSearchQueries("all", searchKey);
              }}
              className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                filterTab == "all" && "bg-gray-100"
              } sm:text-sm hover:bg-gray-100`}
            >
              All
            </button>

            <button
              onClick={() => {
                setFilterTab("active");
                handleSearchQueries("active", searchKey);
              }}
              className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                filterTab == "active" && "bg-gray-100"
              } sm:text-sm hover:bg-gray-100`}
            >
              Active
            </button>

            <button
              onClick={() => {
                setFilterTab("inactive");
                handleSearchQueries("inactive", searchKey);
              }}
              className={`px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                filterTab == "inactive" && "bg-gray-100"
              } sm:text-sm hover:bg-gray-100`}
            >
              Inactive
            </button>
          </div>
        )}

        <div className="relative flex items-center mt-4 md:mt-0">
          <span className="absolute">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 mx-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </span>

          <input
            type="text"
            placeholder="Search by name, organisation"
            onChange={(e) => {
              handleSearchQueries(filterTab, e.target.value.toLowerCase());
              setSearchKey(e.target.value.toLowerCase());
            }}
            className="block py-1.5 pr-5 text-gray-700 bg-white border border-gray-600 rounded-lg md:w-80 placeholder-gray-600 pl-11 rtl:pr-11 rtl:pl-5 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="mt-6 md:flex md:items-center md:justify-between">
        <button
          onClick={() => {
            setUserModal(true);
            setCreateUserForm(true);
            setEditUserData({
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              organization: "",
              role: "",
              status: "",
            });
          }}
          className="inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-800 hover:bg-emerald-700 sm:w-auto flex items-center gap-2"
        >
          <FaPlus /> New user
        </button>
        <button
          onClick={() => {
            exportDataToExcel();
          }}
          className="inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 sm:w-auto flex items-center gap-2"
        >
          <FaFileExcel /> Export
        </button>
      </div>
      <div className="my-10 overflow-x-scroll w-[300px] sm:w-[590px] md:w-full">
        <table className="min-w-max md:min-w-fit divide-y divide-gray-200 md:w-[100%]">
          <thead className="bg-blue-100">
            <tr>
              <th
                scope="col"
                className="py-4 px-4 text-sm font-bold text-left rtl:text-right"
              >
                Name
              </th>
              <>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Role
                </th>

                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Organisation
                </th>
                <th
                  scope="col"
                  className="px-4 py-4 text-sm font-bold text-left rtl:text-right"
                >
                  Action
                </th>
              </>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {loading ? (
              <>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                  </td>
                </tr>
              </>
            ) : (
              userData.map((user, index) => (
                <tr>
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                    {user.full_name}
                  </td>
                  {
                    <>
                      <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                        {user.email}
                      </td>
                      <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-all">
                        <div
                          className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                            user.role === "admin"
                              ? "bg-orange-100/60"
                              : user.role === "super admin"
                              ? "bg-violet-100/60"
                              : "bg-yellow-100/60"
                          }`}
                        >
                          <FaUserCog
                            className={`h-4 w-4 text-gray-700`}
                          />
                          <h2
                            className={`text-sm font-normal capitalize text-gray-700`}
                          >
                            {user.role}
                          </h2>
                        </div>
                      </td>
                      <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-all">
                        <div
                          className={`inline-flex items-center px-3 py-1 rounded-full gap-x-2 ${
                            user.status ? "bg-emerald-100/60" : "bg-red-100/60"
                          }`}
                        >
                          <span
                            className={`h-1.5 w-1.5 rounded-full ${
                              user.status ? "bg-emerald-500" : "bg-red-500"
                            }`}
                          ></span>
                          <h2
                            className={`text-sm font-normal text-gray-700`}
                          >
                            {user.status ? "Active" : "Inactive"}
                          </h2>
                        </div>
                      </td>
                      <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words capitalize">
                        {user.organization}
                      </td>
                    </>
                  }
                  <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                    <div className="flex items-center gap-x-2">
                      {dataTab == "user management" && (
                        <button
                          onClick={(e) => {
                            setUserModal(true);
                            setEditUserData(user);
                            setCreateUserForm(false);
                          }}
                          className="has-tooltip flex gap-1 items-center p-1 px-2 rounded text-white bg-teal-800 transition-colors duration-200 hover:bg-teal-600 focus:outline-none" aria-label="Edit"
                        >
                          <span className="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 left-auto">
                            Edit
                          </span>
                          <FaRegEdit />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
            {userData.length <= 0 && loading == false && (
              <tr>
                <td colSpan={5} className="text-center">
                  <div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center">
                    <IoMdAlert className="mr-2 text-red-500" /> No user
                    information available.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex items-center justify-between mt-6">
          <div></div>
          <div className="items-center hidden lg:flex gap-x-3">
            <button
              onClick={() => handlePageChange(false)}
              disabled={currentPage === 1}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              aria-label="Next"
            >
              <FaCaretLeft />
            </button>
            Page {currentPage} of {totalPages}
            <button
              onClick={() => handlePageChange(true)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60"
              aria-label="Previous"
            >
              <FaCaretRight />
            </button>
          </div>
          <div></div>
        </div>
      </div>
      <UserFormModal
        createUser={createUserForm}
        id={editUserData.id}
        userData={editUserData}
        setUserData={setEditUserData}
        displayModal={userModal}
        setDisplayModal={setUserModal}
        setLoadingTable={setLoading}
        displaySuccessModal={setDisplaySuccessModal}
      />
      <MessageModal
        title={"Delete User?"}
        description={"Are you sure you want to delete this user?"}
        buttonText={"Delete"}
        gif={null}
        approve={false}
        displayModal={deleteModal}
        setDisplayModal={setDeleteModal}
        functionPassed={modalFunction}
      />
      <MessageModal
        title={"Action Successful"}
        description={
          createUserForm
            ? "User has been successfully created."
            : "User has been successfully updated."
        }
        buttonText={"Close"}
        gif={"success"}
        approve={true}
        displayModal={displaySuccessModal}
        setDisplayModal={setDisplaySuccessModal}
        functionPassed={modalClose}
      />
    </div>
  );
};

export default UserTable;
