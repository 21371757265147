import React from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navigation />
      <section className="grid justify-items-center">
        <div className="container bg-white mt-[100px] drop-shadow-md mb-[100px] p-10 rounded-3xl text-left">
          <h3 className="text-4xl font-bold mb-6">
            CONNECT EUROBODALLA PRIVACY POLICY
          </h3>
          <h4 className="text-2xl font-bold mb-6">
            Introduction
          </h4>
          <p className="text-base mb-3">
            Connect Eurobodalla via Rotary Batemans Bay respect your privacy and are committed to protecting it through our compliance with this privacy policy (&quot;Policy&quot;). Maintaining protection of the data entrusted to our care by our constituents is of the utmost importance to Rotary and The Tec Exec.
          </p>
          <p className="text-base mb-3">
            This Policy describes the types of data we may collect from you or that you may provide and describes our policies and practices for collecting, using, protecting, and disclosing that data.
          </p>
          <p className="text-base mb-3">
            This Policy may change from time to time (see Changes to Our Privacy Policy section), so check the Policy periodically for the most current version. If you have any questions about the meaning or interpretation of this Policy, the English- language version of this Policy is the official text.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Data We Collect About You
          </h4>
          <p className="text-base mb-3">
            We collect several types of personal data that can be used to identify you (&quot;Personal Data&quot;), including your:
            <ul className="list-disc ml-10">
                <li>Name</li>
                <li>Email address</li>
                <li>Telephone number</li>
                <li>Location</li>
                <li>Event location and details</li>
                <li>Volunteer preferences</li>
                <li>Equipment used to access our Website and usage details</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            Depending on the type of interaction that you have with Connect Eurobodalla, we may also collect your:
            <ul className="list-disc ml-10">
                <li>Gender</li>
                <li>Birth year</li>
                <li>Marital Status</li>
                <li>Spouse's or parent's name</li>
                <li>Occupation</li>
                <li>Employer</li>
                <li>Photographs/images</li>
            </ul>
          </p>
          <h4 className="text-2xl font-bold mb-6">
            How We Collect Your Data
          </h4>
          <p className="text-base mb-3">
            We collect Personal Data from:
            <ul className="list-disc ml-10">
                <li>Users of our Website</li>
                <li>Members of Rotary Batemans Bay and residents of the Eurobodalla</li>
                <li>Donnors to the Connect Eurobodalla platform</li>
                <li>People who attend Rotary and Connect Eurobodalla events</li>
                <li>People who participate in local programs and events</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            We collect this data:
            <ul className="list-disc ml-10">
                <li>Directly when you provide it to us through any interaction offline or in person</li>
                <li>From our website ce.org.au (our "Website")</li>
                <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this Policy</li>
                <li>Automatically, as you navigate through the Website (data collected automatically may include usage details, IP addresses, and information collected through cookies and other tracking technologies)</li>
                <li>When you become a member of the Connect Eurobodalla only community</li>
                <li>When you use our services</li>
                <li>When you participate in our programs/events or sign up for and/or attend any events that we host</li>
                <li>From third parties we contract with to provide services on our behalf, such as event organisers and community groups</li>
                <li>When you interact with us in another way, for example, contacting us with an inquiry</li>
                <li>From publicly available resources</li>
            </ul>
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Data You Provide to Us
          </h4>
          <p className="text-base mb-3">
            We collect data you provide when you interact with Connect Eurobodalla, either through our Website or any interaction offline or in person. That data includes:
            <ul className="list-disc ml-10">
                <li>Personal data that you provide by making an inquiry or joining as a member of a Connect Eurobodalla online community</li>
                <li>Personal data you provide when participating in services available on our Website, including in:</li>
                <ul className="list-disc ml-10">
                    <li>The Education Portal</li>
                    <li>The Service Project Center</li>
                </ul>
                <li>Personal data you provide us when you enter a contest or promotion sponsored by us</li>
                <li>Personal data you provide when you report a problem with our Website</li>
                <li>Records and copies of your correspondence (including email, social media posts, and other electronic messages), if you contact us</li>
                <li>Your responses to surveys that we might ask you to complete for research purposes</li>
                <li>Your registration to attend and/or participate in Rotary, Connect Eurobodalla events</li>
                <li>Personal data you provide when making a donation</li>
                <li>Personal data you provide when submitting application, for example, for grants, fellowships, or scholarships</li>
                <li>Biographical or other data you provide to us, Rotary Batemans Bay or Connect Eurobodalla</li>
                <li>Your search queries on our Website</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            Features that are developed in the future may result in the collection of additional new personal data.
          </p>
          <p className="text-base mb-3">
            You also may provide data to be published or displayed (hereinafter, &quot;posted&quot;) on public areas of the Website or transmitted to other users of the Website or third parties (collectively, &quot;User Content&quot;). Your User Content is posted on the Website and transmitted to others at your own risk. We limit access to certain pages and you may set certain privacy settings for this data from your account profile. However, we cannot control the actions of other Website users with whom you may choose to share your User Content.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Usage Details, IP Addresses, Cookies, and Other Technologies
          </h4>
          <p className="text-base mb-3">
            As you navigate through and interact with our Website, we may automatically collect certain data about your equipment, browsing actions, and patterns, including:
            <ul className="list-disc ml-10">
                <li>Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on our Website</li>
                <li>Information about your computer and internet connection, including your IP address, operating system, and browser type</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            When you are not signed in to a Connect Eurobodalla account, the data collected in this way is anonymous. It is aggregated into statistical data to help us improve our Website and to deliver a better and more personalized service by enabling us to:
            <ul className="list-disc ml-10">
                <li>Estimate our audience size, browser statistics, popularity of content, and usage patterns</li>
                <li>Speed up your searches and improve Connect Eurobodalla services</li>
                <li>Recognise you when you return to our Website to help speed up your user experience</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            We use cookies (or browser cookies) for this automatic data collection. A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
          </p>
          <p className="text-base mb-3">
            When you are signed in to your Connect Eurobodalla account, we collect usage data that is tied to your individual account. This data is collected to improve site functionality and to tailor site behaviour and content to you, and we process this personal data in accordance with this Policy.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Third-party Advertiser Use of Cookies and Other Tracking Technologies
          </h4>
          <p className="text-base mb-3">
            Some advertisements and offers on Connect Eurobodalla are served by third-party advertisers, ad networks, and ad servers. Additionally, some of the applications accessible from, or on, our Website may be owned by third parties. These third parties may use cookies alone or in conjunction with other tracking technologies to collect data about our users. We do not control these third parties&#39; tracking technologies or how they may be used.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            How We Use Your Personal Data
          </h4>
          <p className="text-base mb-3">
            We use data that we collect about you or that you provide to us, including any personal data:
            <ul className="list-disc ml-10">
                <li>To present our Website and its contents to you</li>
                <li>To provide you with local information, events, or services that you request from us</li>
                <li>To offer and fulfill our core business purposes, which include:</li>
                <ul className="list-disc ml-10">
                    <li>Fulfilling Connect Eurobodalla and Rotary Batemans Bays obligation to individuals and local residents especially in the event of an emergency.</li>
                    <li>Financial processing</li>
                    <li>Supporting connect Eurobodalla Events, including fundraising efforts</li>
                    <li>Communicating key organisational messages through Connect Eurobodalla publications and other materials</li>
                    <li>Supporting the programs and membership of Connect Eurobodalla</li>
                    <li>Complying with any legal obligations</li>
                    <li>Preserving Connect Eurobodalla legacy by building and maintaining accurate archives that effectively document Connect Eurobodalla history</li>
                </ul>
                <li>To fulfill any other purpose for which you provide it</li>
                <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection</li>
                <li>To notify you about changes to our Website or any products or services we offer or provide through it</li>
                <li>To allow you to participate in interactive features on our Website</li>
                <li>To store information about your preferences, allowing us to customise our Website according to your individual interests</li>
                <li>To help us develop and test updates to this Website and other Connect Eurobodalla applications that support Rotary Batemans Bay&#39;s core business purposes</li>
                <li>In any other way we may describe when you provide the personal data</li>
                <li>For any other purpose where we have your consent</li>
            </ul>
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Disclosure of Your Personal Data
          </h4>
          <p className="text-base mb-3">We may disclose aggregated data about our users, and data that cannot be used to identify any individual, without restriction.</p>
          <p className="text-base mb-3">
            We may disclose personal data that we collect or you provide as described in this Policy:
            <ul className="list-disc ml-10">
                <li>To the Connect Eurobodalla community in which you are a member (if applicable), as well as the assigned Rotary club</li>
                <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal data confidential and use it only for the purposes for which we disclose it to them, including:</li>
                <ul className="list-disc ml-10">
                    <li>Companies that produce, publish, and/or ship Rotary publications and Rotary branded goods and other merchandise</li>
                    <li>Online shop vendor</li>
                    <li>Payment processing vendors</li>
                    <li>Financial institutions and fiscal agents when processing financial transactions, such as expense reimbursements</li>
                    <li>Software and applications used for administrative functions such as providing online forms/surveys/applications, newsletter services, online learning, webinar/teleconference services, electronic voting</li>
                    <li>Connect Eurobodalla committees and other event organisers and in the event of an emergency</li>
                    <li>Email distribution services</li>
                </ul>
                <li>To third parties to promote Connect Eurobodalla or for the third party to market their products or services to you if you have consented to these disclosures. We contractually require these third parties to keep personal data confidential and use it only for the purposes for which we disclose it to them.</li>
                <ul className="list-disc ml-10">
                    <li>If you do not want us to share your personal data (even when anonymised) with unaffiliated or non-agent third parties for advertising or promotional purposes, you can send an email stating your request to <span className="text-blue underline">connecteurobodalla@gmail.com</span></li>
                </ul>
                <li>To fulfill the purpose for which you provide it</li>
                <li>For any other purpose disclosed by us when you provide the data</li>
                <li>With your consent</li>
            </ul>
          </p>
          <p className="text-base mb-3">
            We may also disclose your personal data:
            <ul className="list-disc ml-10">
                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                <li>If disclosure is necessary or appropriate to protect the rights, property, or safety Connect Eurobodalla members or others. This includes exchanging personal data with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
            </ul>
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Children Under the Age of 16
          </h4>
          <p className="text-base mb-3"> 
            Our Website is not intended for children under 16 years. We do not knowingly collect personal data from children under 16 without parental consent. No one under age 16 may provide any personal data to or on our Website.
          </p>
          <p className="text-base mb-3">
            If you are under 16, do not:
            <ul className="list-disc ml-10">
                <li>Use or provide any personal data on our Website or on or through any of its features</li>
                <li>Register on our Website, make any purchases through our Website</li>
                <li>Use any of the interactive or public comment features of our Website, or</li>
                <li>Provide any personal data about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use.</li>
            </ul>
          </p>
          <p className="text-base mb-3"> 
            If we learn we have collected or received personal data from a child under 16 without verification of parental consent, we will delete that personal data. If you believe we might have any personal data from or about a child under 16, please contact us at <span className="text-blue underline">connecteurobodalla@gmail.com</span>.
          </p>
          <h4 className="text-2xl font-bold mb-6">
           Data Security
          </h4>
          <p className="text-base mb-3">
            We have implemented technical and operational measures designed to secure your personal data from accidental loss and from unauthorised access, use, alteration, and disclosure. Additionally:
            <ul className="list-disc ml-10">
                <li>When developing new or enhancing existing systems and processes, Connect Eurobodalla implements appropriate data protection throughout its data processing operations.</li>
                <li>All personal data you provide to us is stored on password-protected databases on our secure servers behind firewalls and we use Secure Sockets Layer (SSL) to ensure that the transmission of sensitive data for payments and contributions is encrypted and appropriately safeguarded.</li>
                <li>Employees and team members are trained on the importance of data security and focus specifically on practices for protecting against unauthorised disclosure of personal data.</li>
                <li>We have a documented incident response plan for promptly acting upon events that violate Connect Eurobodalla security or privacy policies, should they occur, and this plan is reviewed and updated on an ongoing basis.</li>
            </ul>
          </p>
          <p className="text-base mb-3"> 
            The safety and security of your personal data also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. Passwords registered with our Website are encrypted to ensure protection against unauthorised access to your personal data.
          </p>
          <p className="text-base mb-3">
            Unfortunately, the transmission of personal data via the internet is not completely secure. Although we do our best to protect your personal data, we cannot guarantee the security of your personal data transmitted to our Website or over any public network. Any transmission of personal data is at your own risk. Without prejudice to any mandatory legal obligations to which we may be subject, we are not responsible for circumvention of any privacy settings or security measures on our Website.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Changes to Our Privacy Policy
          </h4>
          <p className="text-base mb-3"> 
            Connect Eurobodalla may change, add, modify or remove portions of this Policy at any time, which shall become effective immediately upon posting on this page. The date the Policy was last revised is identified at the bottom of the policy. It is your responsibility to review this Policy for any changes. By continuing to use our Website, maintain your membership, use our services, or participate in our programs, you agree to any changes in the Policy.
          </p>
          <h4 className="text-2xl font-bold mb-6">
            Contact Information
          </h4>
          <p className="text-base mb-3">
            Connect Eurobodalla is contacted via Rotary Batemans Bay, in the NSW south Coast. If you have any questions about Connect Eurobodalla privacy protection policies or practices, please contact us at <span className="text-blue underline">batemansbayrotary@hotmail.com</span>
          </p>
          <p className="text-base mt-10">
            Last modified: 28 January 2025
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;