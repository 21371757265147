import React, { useState, useEffect } from "react";
import '../../App.css';
import MessageModal from "../../components/modals/public/MessageModal";
import { FaCheck, FaTimes, FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

//import firebase functions for fetching user join group
import { db } from "../../firebase";
import { collection, getDocs, doc, updateDoc, deleteDoc, query, limit, limitToLast, getCountFromServer, startAfter, endBefore, orderBy, arrayRemove, arrayUnion } from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const ForumJoinTable = () => {
    const [userRequestData, setUserRequestData] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevSnap, setPrevSnap] = useState();

    const queryArray = [limit(10)];

    const fetchData = async () => {
        // creates query for user join group requests pagination or filtering
        const userQuery = query(collection(db, "forum_group_join_request"),orderBy("user_id"), ...queryArray);
        // executes and fetches data based on query
        const userSnap = await getDocs(userQuery);
        // executes and fetches data based on query
        setPrevSnap(userSnap.docs[userSnap.docs.length-1]);
        // set user data array for display
        setUserRequestData(userSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        // pagination number
        const userCount = await getCountFromServer(query(collection(db, "forum_group_join_request")));
        setTotalPages(Math.ceil(userCount.data().count / 10));
        // set loading animations to false
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [userSelected, setUserSelected] = useState("");
    const [approveModal, setApproveModal] = useState(false);
    const [refuseModal, setRefuseModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const modalFunction = async () => {
        setLoading(true);
        if(approveModal) {
            await updateDoc(doc(db, "forum_groups", userSelected.group_id), { "members": arrayUnion(userSelected.user_id) });
            await updateDoc(doc(db, "forum_groups", userSelected.group_id), { "members_request": arrayRemove(userSelected.user_id) });
            await deleteDoc(doc(db, "forum_group_join_request", userSelected.id));
        } else {
            await updateDoc(doc(db, "forum_groups", userSelected.group_id), { "members_request": arrayRemove(userSelected.user_id) });
            await deleteDoc(doc(db, "forum_group_join_request", userSelected.id));
        }
        fetchData();
    }

    const handlePageChange = (paginateNext) => {
        setLoading(true);
        queryArray.length = 0;
        // set current page when next or previous is clicked
        if(paginateNext) {
            setCurrentPage((prev) => prev+1);
            queryArray.push(limit(10), startAfter(prevSnap));
        } else {
            setCurrentPage((prev) => prev-1);
            queryArray.push(limitToLast(10), endBefore(prevSnap));
        }
        fetchData();
    }

    return (
        <>
            <table className="w-full divide-y divide-gray-200">
                <thead className="bg-blue-100">
                    <tr>
                        <th scope="col" className="py-4 px-4 text-sm font-bold text-left rtl:text-right">
                            Join Request
                        </th>

                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                            Request Date
                        </th>

                        <th scope="col" className="px-4 py-4 text-sm font-bold text-left rtl:text-right">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {loading ?
                        <>
                        <tr>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="w-full bg-gray-200 h-4 rounded-full"></div>
                            </td>
                        </tr>
                        </>
                        :
                        userRequestData.map((userRequest) => 
                        <tr>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <span className="capitalize">{userRequest.user_full_name}</span> has requested to join {userRequest.group_title}
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                {moment(userRequest.joined_date).format("DD MMM YYYY")}
                            </td>
                            <td className="px-4 py-4 text-base font-medium text-gray-700 w-[16.6%] break-words">
                                <div className="flex items-center gap-x-2">
                                    <button onClick={() => {setApproveModal(true); setUserSelected(userRequest);}} className="text-gray-500 transition-colors duration-200 dark:hover:text-emerald-500 dark:text-gray-300 hover:text-emerald-500 focus:outline-none" aria-label="Approve">
                                        <FaCheck />
                                    </button>
                                    <button onClick={() => {setRefuseModal(true); setUserSelected(userRequest);}} className="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-300 hover:text-red-500 focus:outline-none" aria-label="Reject">
                                        <FaTimes />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        )
                    }
                    {(userRequestData.length <= 0 && loading == false) &&
                        <tr>
                            <td colSpan={3} className="text-center"><div className="w-full text-center mt-5 text-left text-base font-bold text-gray-500 flex items-center"><IoMdAlert className="mr-2 text-red-500" /> No request available.</div></td>
                        </tr>
                    }
                </tbody>
            </table>
            {(totalPages > 0 && loading == false) &&
                <div className="flex items-center justify-between mt-6">
                    <div></div>
                    <div className="items-center hidden lg:flex gap-x-3">
                        <button onClick={() => handlePageChange(false)} disabled={currentPage === 1} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60" aria-label="Next"><FaCaretLeft /></button>
                        Page {currentPage} of {totalPages}
                        <button onClick={() => handlePageChange(true)} disabled={currentPage === totalPages} className="px-2 py-1 text-sm text-blue-500 rounded-md bg-blue-100/60" aria-label="Previous"><FaCaretRight /></button>
                    </div>
                    <div></div>
                </div>
            }
            <MessageModal title={'Approve user?'} description={'Are you sure you want to approve this user to the group?'} buttonText={'Approve'} approve={true} displayModal={approveModal} setDisplayModal={setApproveModal} functionPassed={modalFunction} />
            <MessageModal title={'Refuse user?'} description={'Are you sure you want to refuse this user to the group?'} buttonText={'Refuse'} approve={false} displayModal={refuseModal} setDisplayModal={setRefuseModal} functionPassed={modalFunction} />
        </>
    )
}

export default ForumJoinTable