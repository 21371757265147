import React, { useMemo, useState } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { GoAlert, GoEye, GoEyeClosed } from "react-icons/go";

//import input validation
import inputHook from "../../hooks/inputHook";

//import firebase functions for registration
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../firebase";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

//import message modal for success and failed messages
import MessageModal from "../../components/modals/public/MessageModal";

//import searchable select for organization drop down and filtering
import SearchableSelect from "../../components/forms/SearchableSelect";

const Registration = () => {
  // message modal
  const [title, setTitle] = useState("Registration Success");
  const [description, setDescription] = useState(
    "Account will be active in 3-5 business days!"
  );
  const [buttonText, setButtonText] = useState("Close");
  const [gifAction, setGifAction] = useState("success");
  const [approve, setApprove] = useState(false);

  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    reasons_for_joining: "",
  });

  const [hasErrors, setHasErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
    reasons_for_joining: false,
  });

  const [viewPassword, setViewPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [registrationError, setRegistrationError] = useState(false);

  //shows message if registration is success or failed
  const [successModal, setSuccessModal] = useState(false);

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(
      e.target.value,
      e.target.name,
      inputData.password
    );
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  //executes creation of user account
  const signUp = async (e) => {
    e.preventDefault();

    try {
      const userRef = collection(db, "users");
      const querySnapshot = await getDocs(
        query(userRef, where("email", "==", inputData.email))
      );

      if (!querySnapshot.empty) {
        setSuccessModal(true);
        setFunctionPassed(() => modalFunctionClose);
        setTitle("Error!");
        setDescription("Email is already taken!");
        setApprove(false);
        setGifAction("");
        setButtonText("Close");
        return;
      }
      // Proceed with creating the user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        inputData.email,
        inputData.password
      );
      // Remove unwanted fields and prepare user data
      const userData = {
        ...inputData,
        full_name: `${inputData.first_name.toLowerCase()} ${inputData.last_name.toLowerCase()}`,
        status: false,
        role: "user",
      };
      delete userData.password;
      delete userData.confirm_password;
      // Send email verification
      await sendEmailVerification(userCredential.user);
      // Save user data in Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
      // Show success message
      setSuccessModal(true);
    } catch (error) {
      //throw error when an input is invalid or registration did not go through
      setRegistrationError(true);
      console.log(error);
    }
  };

  const disabledSubmit = useMemo(
    () =>
      !(
        !Object.values(inputData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [inputData, hasErrors]
  );

  const modalFunction = () => {
    navigate("/login");
  };

  const modalFunctionClose = () => {
    setSuccessModal(false);
    setTitle("Registration Success!");
    setDescription("Account will be active in 3-5 business days!");
    setApprove(false);
    setButtonText("Close");
    setGifAction("success");
  };

  const [functionPassed, setFunctionPassed] = useState(() => modalFunction);

  return (
    <div>
      <Navigation />
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register an account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-left">
          <form className="space-y-3" onSubmit={signUp}>
            {registrationError && (
              <div className="text-red-500 text-sm flex items-center gap-2 justify-center p-2 bg-red-200 rounded-lg">
                <GoAlert /> Something went wrong.
              </div>
            )}

            <div>
              <label
                for="first_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First name
              </label>
              <div className="mt-2">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.first_name
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="first_name"
                  name="first_name"
                  type="text"
                  autocomplete="first_name"
                  required
                  value={inputData.first_name}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {hasErrors.first_name && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  First name is required.
                </div>
              )}
            </div>

            <div>
              <label
                for="last_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last name
              </label>
              <div className="mt-2">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.last_name
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="last_name"
                  name="last_name"
                  type="text"
                  autocomplete="last_name"
                  required
                  value={inputData.last_name}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {hasErrors.last_name && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Last name is required.
                </div>
              )}
            </div>

            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.email
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  value={inputData.email}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              {hasErrors.email && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  {registrationError || "Invalid email address."}
                </div>
              )}
            </div>

            <div>
              <label
                for="select"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organization
              </label>
              <SearchableSelect
                organization={inputData}
                setOrganization={setInputData}
              />
            </div>

            <div>
              <label
                for="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2 relative flex items-center flex-row-reverse">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 pr-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.password
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="password"
                  name="password"
                  type={viewPassword.password ? "text" : "password"}
                  autocomplete="current-password"
                  required
                  value={inputData.password}
                  onChange={(e) => onInputChange(e)}
                />
                <div className="absolute">
                  {viewPassword.password ? (
                    <GoEyeClosed
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          password: !viewPassword.password,
                        })
                      }
                    />
                  ) : (
                    <GoEye
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          password: !viewPassword.password,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              {hasErrors.password && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Password must contain at least:
                  <br></br>* 6 characters<br></br>* 1 capital letter<br></br>* 1
                  number
                  <br></br>* 1 special character
                </div>
              )}
            </div>

            <div>
              <label
                for="confirm_password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Confirm password
              </label>
              <div className="mt-2 relative flex items-center flex-row-reverse">
                <input
                  className={`block w-full rounded-md border-0 py-1.5 pr-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.confirm_password
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="confirm_password"
                  name="confirm_password"
                  type={viewPassword.confirmPassword ? "text" : "password"}
                  autocomplete="confirm-password"
                  required
                  value={inputData.confirm_password}
                  onChange={(e) => onInputChange(e)}
                />
                <div className="absolute">
                  {viewPassword.confirmPassword ? (
                    <GoEyeClosed
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          confirmPassword: !viewPassword.confirmPassword,
                        })
                      }
                    />
                  ) : (
                    <GoEye
                      className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          confirmPassword: !viewPassword.confirmPassword,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              {hasErrors.confirm_password && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Password and confirm password needs to match.
                </div>
              )}
            </div>

            <div>
              <label
                for="textarea"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Reason for joining
              </label>
              <div className="mt-2">
                <textarea
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasErrors.reasons_for_joining
                      ? "focus:outline-red-400 focus:ring-red-600"
                      : "focus:ring-indigo-600"
                  } sm:text-sm sm:leading-6 px-2`}
                  id="textarea"
                  name="reasons_for_joining"
                  autocomplete="textarea"
                  required
                  value={inputData.reasons_for_joining}
                  onChange={(e) => onInputChange(e)}
                ></textarea>
              </div>
              {hasErrors.reasons_for_joining && (
                <div className="text-red-500 text-sm inline-flex items-center gap-2">
                  Reason for joining is required.
                </div>
              )}
            </div>

            <div>
              <button
                className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-75 ${
                  disabledSubmit && "cursor-not-allowed"
                }`}
                type="submit"
                disabled={disabledSubmit}
              >
                Submit
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?
            <a
              href="/login"
              className="ml-1 font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in here
            </a>
          </p>
        </div>
      </div>
      <MessageModal
        title={title}
        description={description}
        approve={approve}
        gif={gifAction}
        buttonText={buttonText}
        displayModal={successModal}
        setDisplayModal={setSuccessModal}
        functionPassed={functionPassed}
      />
      <Footer />
    </div>
  );
};

export default Registration;
