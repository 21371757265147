import React, { useState, useMemo } from "react";
import "./../../../App.css";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { AiOutlineLoading } from "react-icons/ai";

//import input validation
import inputHook from "../../../hooks/inputHook";

//import firebase functions for user creation
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../../../firebase";
import { doc, updateDoc, setDoc } from "firebase/firestore";

//import searchable select for organization drop down and filtering
import SearchableSelect from "../../../components/forms/SearchableSelect";

const UserFormModal = ({
  createUser,
  id,
  userData,
  setUserData,
  displayModal,
  setDisplayModal,
  setLoadingTable,
  displaySuccessModal
}) => {
  const [hasErrors, setHasErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    organization: false,
    role: false,
    status: false,
  });

  const [viewPassword, setViewPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [loading, setLoading] = useState(false);

  //tests user input to input validation requirements
  const onInputChange = (e) => {
    const validation = inputHook(
      e.target.value,
      e.target.name,
      userData.password
    );
    setHasErrors({
      ...hasErrors,
      [e.target.name]: validation.hasError,
    });

    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  const disabledSubmit = useMemo(
    () =>
      !(
        userData.length !== 0 &&
        !Object.values(userData).some((x) => x === null || x === "") &&
        Object.values(hasErrors).every((x) => x === false)
      ),
    [userData, hasErrors]
  );

  //executes creation or update of user account
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (createUser) {
      createUserWithEmailAndPassword(auth, userData.email, userData.password)
        .then(async (userCredential) => {
          //remove unwanted fields from userData
          delete userData.password;
          // create new field for full name
          userData.full_name =
            userData.first_name.toLowerCase() +
            " " +
            userData.last_name.toLowerCase();
          userData.status = false;
          userData.organization = userData.organization.toLowerCase();
          //sends email verification to user
          sendEmailVerification(userCredential.user);
          //creates a new document of user information with id of created user
          await setDoc(doc(db, "users", userCredential.user.uid), userData);
        })
        .catch((error) => {
          //throw error when an input is invalid or registration did not go through
        });
    } else {
      // create new field for full name
      userData.status = userData.status == "active" || userData.status == true ? true : false;
      userData.full_name =
        userData.first_name.toLowerCase() +
        " " +
        userData.last_name.toLowerCase();
      userData.organization = userData.organization.toLowerCase();
      await updateDoc(doc(db, "users", id), userData);
    }

    setLoadingTable(true);
    setTimeout(() => {setLoading(false); displaySuccessModal(true);}, 300);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${
          displayModal
            ? "opacity-100 ease-out duration-300"
            : "ease-in duration-300"
        }`}
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      ></div>
      <div
        className="fixed inset-0 z-10 w-screen overflow-y-auto"
        style={{ visibility: displayModal ? "visible" : "hidden" }}
      >
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${!loading && "sm:my-8 sm:w-full sm:max-w-lg"} ${
              displayModal
                ? "opacity-100 translate-y-0 sm:scale-100"
                : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }`}
          >
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-auto">
              {loading ?
                  <AiOutlineLoading className="animate-spin mb-2 text-4xl bold" />
              :
                <div className="lg:flex lg:items-start">
                  <div className="mt-3 sm:mx-4 sm:mt-0 text-left flex-1">
                    <h2
                      className="text-2xl font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      {createUser ? "Create new user" : "Edit user"}
                    </h2>
                    <div className="mt-2 mb-5">
                      <p className="text-sm text-gray-500">
                        <span className="text-red-500">*</span> Fill up required fields.
                      </p>
                    </div>
                    <form className="grid gap-4" onSubmit={submitForm}>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">First name <span className="text-red-500">*</span></span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.first_name
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="first_name"
                          placeholder="First name"
                          value={userData.first_name ? userData.first_name : ""}
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.first_name && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            First name is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">Last name <span className="text-red-500">*</span></span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.last_name
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="last_name"
                          placeholder="Last name"
                          value={userData.last_name ? userData.last_name : ""}
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.last_name && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Last name is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">Email <span className="text-red-500">*</span></span>
                        <input
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.email
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="email"
                          placeholder="Email"
                          value={userData.email ? userData.email : ""}
                          onChange={(e) => onInputChange(e)}
                        />
                        {hasErrors.email && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Valid email is required.
                          </div>
                        )}
                      </div>
                      {createUser == true && (
                        <div className="text-base grid gap-2">
                          <span className="font-bold">Password <span className="text-red-500">*</span></span>
                          <div className="relative flex items-center flex-row-reverse">
                            <input
                              className={`block w-full rounded-md border-0 py-1.5 pr-11 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                                hasErrors.password
                                  ? "focus:outline-red-400 focus:ring-red-600"
                                  : "focus:ring-indigo-600"
                              } sm:text-sm sm:leading-6 px-2`}
                              id="password"
                              name="password"
                              type={viewPassword.password ? "text" : "password"}
                              autocomplete="current-password"
                              value={userData.password}
                              onChange={(e) => onInputChange(e)}
                            />
                            <div className="absolute">
                              {viewPassword.password ? (
                                <GoEyeClosed
                                  className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                                  onClick={() =>
                                    setViewPassword({
                                      ...viewPassword,
                                      password: !viewPassword.password,
                                    })
                                  }
                                />
                              ) : (
                                <GoEye
                                  className="w-6 h-6 mx-3 text-gray-300 dark:text-gray-500 cursor-pointer"
                                  onClick={() =>
                                    setViewPassword({
                                      ...viewPassword,
                                      password: !viewPassword.password,
                                    })
                                  }
                                />
                              )}
                            </div>
                          </div>
                          {hasErrors.password && (
                            <div className="text-red-500 text-sm inline-flex items-center gap-2">
                              Valid password is required.
                            </div>
                          )}
                        </div>
                      )}
                      <div className="text-base grid gap-2">
                        <span className="font-bold">Organization <span className="text-red-500">*</span></span>
                        <SearchableSelect
                          organization={userData}
                          setOrganization={setUserData}
                        />
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">Role <span className="text-red-500">*</span></span>
                        <select
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.role
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="role"
                          value={userData.role ? userData.role : ""}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="">Please select one</option>
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </select>
                        {hasErrors.role && (
                          <div className="text-red-500 text-sm inline-flex items-center gap-2">
                            Role is required.
                          </div>
                        )}
                      </div>
                      <div className="text-base grid gap-2">
                        <span className="font-bold">Status <span className="text-red-500">*</span></span>
                        <select
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                            hasErrors.status
                              ? "focus:outline-red-400 focus:ring-red-600"
                              : "focus:ring-indigo-600"
                          } sm:text-sm sm:leading-6 px-2`}
                          name="status"
                          value={
                            (userData.status && userData.status == true) ||
                            userData.status == "active"
                              ? "active"
                              : "inactive"
                          }
                          onChange={(e) => onInputChange(e)}
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                      <div
                        className={`bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}
                      >
                        <button
                          type="submit"
                          onClick={(e) => {
                            setDisplayModal(false);
                          }}
                          className={`inline-flex w-full justify-center rounded-full px-3 py-2 text-sm font-semibold text-white shadow-sm bg-emerald-600 hover:bg-emerald-500 sm:ml-3 sm:w-auto ${
                            disabledSubmit && "cursor-not-allowed"
                          }`}
                          disabled={disabledSubmit}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setDisplayModal(false)}
                          className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFormModal;
