import React, { useState, useContext, useEffect } from "react";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import MessageModal from "../../components/modals/public/MessageModal";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import australianPostcodes from "../../utils/australian-postcodes.json";

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

//import firebase functions for updating user info
import { db, storage } from "../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, updateDoc, getDoc, getDocs, collection, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SearchableSelectSub from "../../components/forms/SearchableSelectSub";

const EditProfile = () => {
  const auth = useContext(AuthContext);
  //set user data using local storage (user info)
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );
  const [availability, setAvailability] = useState(userData.availability || []);
  const [groupsInterestedIn, setGroupsInterestedIn] = useState(
    userData.groups_interested_in || []
  );

  // capitalize data when display
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docRef = doc(db, "users", auth.userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData({
            ...data,
            first_name: capitalizeWords(data.first_name || ""),
            last_name: capitalizeWords(data.last_name || ""),
            address: capitalizeWords(data.address || ""),
            suburb: capitalizeWords(data.suburb || ""),
          });
          setAvailability(data.availability || []);
          setGroupsInterestedIn(data.groups_interested_in || []);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [auth.userId]);

  const navigate = useNavigate();

  //shows message if update profile is success or failed
  const [successModal, setSuccessModal] = useState(false);
  // show address error
  const [addressError, setAddressError] = useState("");

  //set user data according to user input
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]:
        name === "address" || name === "first_name" || name === "last_name"
          ? capitalizeWords(value) // Capitalize specific fields
          : value,
    }));

    // Clear groups and availability if volunteering is "No"
    if (name === "is_willing_to_volunteer" && value === "no") {
      setGroupsInterestedIn([]);
      setAvailability([]);
    }
  };

  const onChangeAvailabilityOrGroupInterestedIn = (value, remove, setState) => {
    const index = availability.indexOf(value);
    if (remove) {
      setState((oldValues) => {
        return oldValues.filter((availability) => availability !== value);
      });
    } else {
      index === -1 && setState((prev) => [...prev, value]);
    }
  };

  //executes update user info
  const submitEditProfile = async (e) => {
    e.preventDefault();

    // Validate address and suburb before proceeding
    if (userData.is_willing_to_volunteer === "yes") {
      if (!userData.address || userData.address.trim() === "") {
        setAddressError("Please make sure address and suburb is valid!");
        return;
      }
      if (
        !selectedSuburb ||
        !selectedSuburb.suburb ||
        selectedSuburb.suburb.trim() === "" ||
        selectedSuburb.postcode === "-"
      ) {
        setAddressError("Please make sure address and suburb is valid!");
        return;
      }
    }

    // Handle image upload logic (unchanged)
    const imageFile = e.target[0].files[0];
    if (imageFile) {
      await uploadBytes(
        ref(storage, `users/images/${auth.userId}.jpg`),
        imageFile
      ).then(async () => {
        if (userData.image_url) {
          await deleteObject(ref(storage, userData.image_url)).catch(() => {});
        }
      });
      const url = await getDownloadURL(
        ref(storage, `users/images/${auth.userId}.jpg`)
      )
        .then((url) => url)
        .catch(() => null);
      if (url) {
        userData.profile_image_url = url;
        setUserData({ ...userData, profile_image_url: url });
        e.target[0].value = "";
      }
    }

    // Construct user data to save
    const userDataToSave = {
      ...userData,
      full_name:
        userData.first_name.toLowerCase() +
        " " +
        userData.last_name.toLowerCase(),
      availability,
      groups_interested_in: groupsInterestedIn,
      address: userData.address.toLowerCase(),
      suburb: selectedSuburb?.suburb.toLowerCase() || "",
      postcode: selectedSuburb?.postcode || "",
    };

    // Ensure suburb is not empty or invalid
    if (!userDataToSave.suburb || userDataToSave.suburb.trim() === "") {
      console.error("Suburb is empty or invalid, stopping update.");
      return;
    }

    // Update Firestore
    await updateDoc(doc(db, "users", auth.userId), userDataToSave)
      .then(() => {
        localStorage.setItem("userInfo", JSON.stringify(userDataToSave));
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });

    await getDocs(query(collection(db, "forum_discussion_comments"), where("user_id", "==",auth.userId))).then((data) => {
      data.docs.map(async (discussion_comment) => {
        await updateDoc(doc(db, "forum_discussion_comments", discussion_comment.id), { user: { first_name: userDataToSave.first_name, last_name: userDataToSave.last_name, profile_image_url: userDataToSave.profile_image_url  } });
      });
    });

    await getDocs(query(collection(db, "forum_discussion_subcomments"), where("user_id", "==",auth.userId))).then((data) => {
      data.docs.map(async (discussion_subcomment) => {
        await updateDoc(doc(db, "forum_discussion_subcomments", discussion_subcomment.id), { user: { first_name: userDataToSave.first_name, last_name: userDataToSave.last_name, profile_image_url: userDataToSave.profile_image_url  } });
      });
    });

    setSuccessModal(true);
  };

  

  const modalFunction = () => {
    console.log("success");
  };

  // SuburbOptions
  const [selectedSuburb, setSelectedSuburb] = useState({
    suburb: userData.suburb || "",
    postcode: userData.postcode || "",
  });

  const suburbOptions = australianPostcodes.filter(
    (item) => item.postcode !== "-" && item.suburb.trim() !== ""
  );

  const handleSuburbChange = (selected) => {
    if (
      selected &&
      selected.suburb.trim() !== "" &&
      selected.postcode !== "-"
    ) {
      setAddressError("");
      setSelectedSuburb(selected);
    } else {
      setSelectedSuburb({ suburb: "", postcode: "" }); // Reset invalid selection
    }
  };

  return (
    <div>
      <Navigation />
      <div className="grid place-items-center">
        <div className="container text-left my-10 p-10 md:p-0">
          <div className="text-4xl">Edit Personal Details</div>
          <form className="grid gap-3 mt-10" onSubmit={submitEditProfile}>
            <div className="text-base grid md:flex md:items-center justify-items-center gap-10">
              <div>
                {userData.profile_image_url != null ? (
                  <img
                    src={userData.profile_image_url}
                    className="w-60 h-60 object-cover rounded-full border-[8px] border-gray-200"
                    alt=""
                  />
                ) : (
                  <FaRegCircleUser className="text-9xl text-gray-400" />
                )}
              </div>
              <div>
                <span className="font-bold">Upload Image</span>
                <br />
                <span>Profile Photo</span>
                <input
                  type="file"
                  accept="image/*"
                  className="block w-full px-3 py-2 mt-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-lg file:bg-gray-200 file:text-gray-700 file:text-sm file:px-4 file:py-1 file:border-none file:rounded-full focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="bg-[#00a5e580] h-1.5 w-full self-end my-5"></div>
            <div className="text-2xl mb-5">Personal Information</div>
            <div className="text-base grid gap-2">
              <span className="font-bold">First name</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="first_name"
                placeholder="First name"
                value={userData.first_name}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Last name</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="last_name"
                placeholder="Last name"
                value={userData.last_name}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Email</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="email"
                placeholder="Email"
                value={userData.email}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Phone Number</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="phone_number"
                placeholder="Phone number"
                value={userData.phone_number}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Street Address</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="address"
                placeholder="House # and street address"
                value={userData.address}
                onChange={(e) => onInputChange(e)}
              />
              {addressError && (
                <span className="text-red-500">{addressError}</span>
              )}
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Suburb and Postcode</span>
              <SearchableSelectSub
                data={suburbOptions}
                placeholder="Search and select suburb"
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                onSelectionChange={handleSuburbChange}
                selectedSuburb={selectedSuburb}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Suffix</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="suffix"
                placeholder="Suffix"
                value={userData.suffix}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Gender</span>
              <select
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="gender"
                value={userData.gender}
                onChange={(e) => onInputChange(e)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Date of Birth</span>
              <input
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="date_of_birth"
                type="date"
                value={userData.date_of_birth}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="text-4xl mb-5 mt-5">
              Edit Volunteering Preferences
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Are you willing to volunteer?</span>
              <select
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                name="is_willing_to_volunteer"
                value={userData.is_willing_to_volunteer}
                onChange={(e) => onInputChange(e)}
              >
                <option>Please select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">
                Which volunteering group are you interested in?
              </span>
              <select
                disabled={userData.is_willing_to_volunteer === "no"}
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                onChange={(e) =>
                  onChangeAvailabilityOrGroupInterestedIn(
                    e.target.value,
                    false,
                    setGroupsInterestedIn
                  )
                }
              >
                <option>Please select an option</option>
                <option value="tree planting">Tree Planting</option>
                <option value="calamities">Calamities</option>
                <option value="clean & green">Clean & Green</option>
              </select>
            </div>
            <div className="text-base flex gap-2">
              {groupsInterestedIn.map((group) => (
                <div className="rounded-full bg-gray-200 p-2 capitalize flex hover:bg-gray-300">
                  {group}{" "}
                  <IoMdClose
                    className="text-xs cursor-pointer"
                    onClick={() =>
                      onChangeAvailabilityOrGroupInterestedIn(
                        group,
                        true,
                        setGroupsInterestedIn
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <div className="text-base grid gap-2">
              <span className="font-bold">Availability</span>
              <select
                className="p-2 font-medium rounded-sm shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 w-full"
                onChange={(e) =>
                  onChangeAvailabilityOrGroupInterestedIn(
                    e.target.value,
                    false,
                    setAvailability
                  )
                }
                disabled={userData.is_willing_to_volunteer === "no"}
              >
                <option>Please select an option</option>
                <option value="monday">Monday</option>
                <option value="tuesday">Tuesday</option>
                <option value="wednesday">Wednesday</option>
                <option value="thursday">Thursday</option>
                <option value="friday">Friday</option>
                <option value="saturday">Saturday</option>
                <option value="sunday">Sunday</option>
              </select>
            </div>
            <div className="text-base flex gap-2">
              {availability.map((day) => (
                <div className="rounded-full bg-gray-200 p-2 capitalize flex hover:bg-gray-300">
                  {day}{" "}
                  <IoMdClose
                    className="text-xs cursor-pointer"
                    onClick={() =>
                      onChangeAvailabilityOrGroupInterestedIn(
                        day,
                        true,
                        setAvailability
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <button
              className="mt-5 text-center rounded-full bg-[#00A5E5] px-3 py-2 text-base font-bold text-white shadow-sm hover:bg-[#1479D1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
              type="submit"
            >
              SAVE CHANGES
            </button>
            <button
              className="mt-5 text-center rounded-full bg-white border-2 border-black px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#1479D1] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#1479D1]"
              type="button"
              onClick={() => {
                navigate("/my-profile");
              }}
            >
              CANCEL
            </button>
          </form>
        </div>
      </div>
      <MessageModal
        title={"Update profile success"}
        description={
          "Your profile has been updated with the changes you have made!"
        }
        approve={true}
        gif={"success"}
        buttonText={"Close"}
        displayModal={successModal}
        setDisplayModal={setSuccessModal}
        functionPassed={modalFunction}
      />
      <Footer />
    </div>
  );
};

export default EditProfile;
