import React from "react";
import './../../../App.css';
import { GoAlert, GoCheckCircle } from "react-icons/go";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const AnnouncementModal = ({ title, description, approve, gif=null, announcementsData, displayModal, setDisplayModal }) => {
    const navigate = useNavigate();

    const closeModal = () => {
        setDisplayModal(!displayModal);
    }

    const clickAnnouncementRedirect = (announcement) => {
        navigate("/announcement-details", { state: announcement });
    }

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity opacity-0 ${displayModal ? "opacity-100 ease-out duration-300" : "ease-in duration-300" }`} style={{ visibility: displayModal ? "visible" : "hidden" }}></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto" style={{ visibility: displayModal ? "visible" : "hidden" }}>
                    <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                        <div className={`relative transform min-h-60 flex flex-row overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl ${displayModal ? "opacity-100 translate-y-0 sm:scale-100" : "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }`}>
                            
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 self-center">
                                {gif === "success" &&
                                    <img src="../assets/success.gif" />
                                }
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 text-left">
                                        <div className="font-semibold leading-12 text-red-600 lg:text-5xl text-xl flex text-left" id="modal-title">
                                            {gif !== "success" &&
                                                <div className={`mr-2 mx-auto flex sm:h-20 sm:w-20 h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${approve === true ? "bg-emerald-100" : "bg-red-100" } sm:mx-0`}>
                                                    {approve === true ? <GoCheckCircle className={"sm:h-10 sm:w-10 h-8 w-8 text-emerald-600"} /> : <GoAlert className={"sm:h-10 sm:w-10 h-8 w-8 text-red-600"} /> }
                                                </div>
                                            }
                                            <div className="pl-2">{title}</div>
                                        </div>
                                        <div className="mt-5">
                                            <p className="text-base text-gray-500 text-left">{description}</p>
                                        </div>
                                        <ul className="ml-5 text-left">
                                        {announcementsData.map((announcement, index) => 
                                            <li className="mt-3 list-disc text-red-500 cursor-pointer" onClick={() => clickAnnouncementRedirect(announcement)}>
                                                <p className="font-semibold leading-6 text-lg capitalize hover:underline" id="modal-title">{announcement.title}</p>
                                            </li>
                                        )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 self-start">
                                <AiOutlineCloseCircle className="h-8 w-8 cursor-pointer" onClick={() => closeModal()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default AnnouncementModal