import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  activeUser: null,
  role: null, // Include role here
  token: null, // Include token here
  loginUser: () => {},
  logoutUser: () => {},
});
