import React, { useState, useEffect, useCallback, useContext } from "react";
import './../../App.css';
import CommentInputArea from '../../components/forms/CommentInputArea';
import MessageModal from './../modals/public/MessageModal';
import { FaComments, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";

//import authentication context to access user info
import { AuthContext } from "../../context/authContext";

//import firebase functions for forum discussion subcomments
import { db } from "../../firebase";
import { collection, getDocs, doc, query, limit, orderBy, startAfter, limitToLast, endBefore, where, addDoc, updateDoc, deleteDoc, getCountFromServer, increment } from "firebase/firestore";

//import moment for date formatting
import moment from "moment/moment";

const CommentCard = ({commentData, handleEditNode, handleDeleteNode, subComment, discussionId}) => {
    const [commentInputVisible, setCommentInputVisible] = useState(false);
    const [commentVisible, setCommentVisible] = useState(true);
    const [commentEdit, setCommentEdit] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const onDeleteComment = () => {
        setCommentVisible(false);
        handleDeleteNode(commentData.id);
    };

    const [totalPages, setTotalPages] = useState(0);
    const [subCommentCount, setSubCommentCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [forumDiscussionSubComments, setForumDiscussionSubComments] = useState([]);
    const [prevSnap, setPrevSnap] = useState();

    const fetchData = async (showMore = null) => {
        const queryArray = [where("reply_to", "==", commentData.id)];
        // pagination for forum discussion subcomments
        prevSnap ? showMore ? queryArray.push(limit(5), startAfter(prevSnap)) : queryArray.push(limitToLast(5), endBefore(prevSnap)) : queryArray.push(limit(5));
        // creates query for forum discussion subcomments pagination
        const forumDiscussionSubCommentsQuery = query(collection(db, "forum_discussion_subcomments"),orderBy("date"), ...queryArray);
        // executes and fetches data based on query
        const forumDiscussionSubCommentsSnap = await getDocs(forumDiscussionSubCommentsQuery);
        // push paginated array to original state
        showMore ? setForumDiscussionSubComments(comments => comments.concat(forumDiscussionSubCommentsSnap.docs.map((d) => ({ id: d.id, ...d.data() })))) : setForumDiscussionSubComments(forumDiscussionSubCommentsSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        setPrevSnap(forumDiscussionSubCommentsSnap.docs[forumDiscussionSubCommentsSnap.docs.length-1]);
        setCurrentPage(currentPage => currentPage+1);
        setSubCommentCount(subCommentCount-5);
    };

    const checkSubComments = async () => {
        // pagination number
        const forumDiscussionCommentsCount = await getCountFromServer(query(collection(db, "forum_discussion_subcomments"), where("reply_to", "==", commentData.id)));
        setSubCommentCount(forumDiscussionCommentsCount.data().count);
        setTotalPages(Math.ceil(forumDiscussionCommentsCount.data().count / 5));
    }

    const auth = useContext(AuthContext);
    const userData = JSON.parse(localStorage.getItem('userInfo'));
    const handleInsertSubComment = useCallback( async (input) => {
        const commentInputData = {
            comment: input,
            user_id: auth.userId,
            reply_to: commentData.id,
            date: new Date().getTime(),
            user: {
                first_name: userData.first_name,
                last_name: userData.last_name,
                profile_image_url: (userData.profile_image_url || "")
            }
        }

        const {id} = await addDoc(collection(db, "forum_discussion_subcomments"), commentInputData);
        await updateDoc(doc(db, "forum_discussions", discussionId), { comments_count: increment(1), latest_post: new Date().getTime() });
        commentInputData.id = id;
        setForumDiscussionSubComments(comments => ([...comments, commentInputData ]));
    }, [forumDiscussionSubComments])

    const handleEditSubComment = useCallback( async (id, input) => {
        await updateDoc(doc(db, "forum_discussion_subcomments", id), { comment: input });
    }, [forumDiscussionSubComments])

    const handleDeleteSubComment = useCallback( async (id) => {
        setCommentVisible(false);
        await deleteDoc(doc(db, "forum_discussion_subcomments", id));
        await updateDoc(doc(db, "forum_discussions", discussionId), { comments_count: increment(-1), latest_post: new Date().getTime() });
    }, [forumDiscussionSubComments])

    useEffect(() => {
        checkSubComments();
    }, []);

    return (
        <>
            <div className="flex flex-row relative">
                <div className="w-[99%] z-2">
                    <div className={`flex my-2 gap-2 sm:gap-5 sm:my-5 ${commentVisible ? "visible" : "hidden"}`}>
                        <div className={`${subComment ? "w-[35px]" : "w-[50px]"} sm:w-[110px]`}>
                            <img className={`object-cover object-center ${subComment ? "h-[35px] w-[35px]" : "h-[50px] w-[50px] max-w-[50px]"} sm:h-[90px] sm:w-[90px] sm:max-w-[90px] rounded-full border-4 border-white max-w-fit`} src={commentData.user.profile_image_url || "./assets/user/bg-lg.png"} />
                        </div>
                        <div className="bg-[#FFFFFF] rounded-xl p-3 sm:p-5 w-[100%]">
                            <div className="font-bold capitalize">{commentData.user.first_name + " " + commentData.user.last_name}</div>
                            <div className="font-bold text-gray-400 text-xs">{moment(commentData.date).format("DD MMM YYYY, h:mma")}</div>
                            <div className="my-2 sm:my-5">{commentData.comment}</div>
                            <div className={`flex gap-5`}>
                                <button onClick={() => {setCommentInputVisible(true); setCommentEdit(false);}} type="button" className="text-blue-500 hover:underline text-left flex gap-1 items-center"><FaComments /> Reply</button>
                                {commentData.user_id === auth.userId &&
                                <>
                                    <button onClick={() => {setCommentInputVisible(true); setCommentEdit(true);}} className="text-blue-500 hover:underline flex gap-1 items-center"><FaRegEdit /> Edit</button>
                                    <button onClick={() => {setDisplayModal(true)}} type="button" className="text-blue-500 hover:underline flex gap-1 items-center"><FaRegTrashAlt /> Delete</button>
                                </>
                                }
                            </div>
                            <CommentInputArea commentInputVisible={commentInputVisible} setCommentInputVisible={setCommentInputVisible} commentData={commentData} handleInsertNode={handleInsertSubComment} handleEditNode={handleEditNode} commentEdit={commentEdit} setCommentEdit={setCommentEdit} />
                        </div>
                    </div>
                    <MessageModal title={'Delete Comment?'} description={'Are you sure you want to delete your comment?'} buttonText={'Delete'} approve={false} displayModal={displayModal} setDisplayModal={setDisplayModal} functionPassed={onDeleteComment} />
                    {forumDiscussionSubComments?.map((comment) =>
                        <div className="ml-14 sm:ml-20">
                            <CommentCard commentData={comment} handleEditNode={handleEditSubComment} handleDeleteNode={handleDeleteSubComment} subComment={true} discussionId={discussionId} />
                        </div>
                    )}
                    {currentPage < totalPages &&
                        <div className="flex">
                            <div className="w-20"></div>
                            <button onClick={() => {fetchData(true)}} type="button" className="w-full text-center rounded-lg bg-[#F1BD4E] border-2 border-[#F1BD4E] px-3 py-2 text-base font-bold text-black shadow-sm hover:bg-[#F1BD4E] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-[#F1BD4E]">View {subCommentCount} {subCommentCount <= 1 ? 'reply' : 'replies'}...</button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CommentCard