import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./../../App.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import LeftSideBar from "../../components/LeftSideBar";
import OrganisationTable from "../../components/organisation_management/OrganisationTable";
import DirectoryTable from "../../components/organisation_management/DirectoryTable";

const OrganisationManagement = () => {
  const [showOrgTable, setShowOrgTable] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const table = params.get("table");
    if (table === "directory") {
      setShowOrgTable(false);
    }
  }, [location]);
  return (
    <div>
      <Navigation />
      <LeftSideBar>
        <div className="grid place-items-center">
          <div className="container text-left my-10">
            <div className="text-4xl">Organisation Management</div>
            <div className="mt-6 md:flex md:items-center md:justify-between">
              <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
                <button
                  onClick={() => setShowOrgTable(true)}
                  className={
                    `px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                      showOrgTable === true && "bg-blue-100"
                    } sm:text-sm hover:bg-gray-100`
                  }
                >
                  Organisation
                </button>

                <button
                  onClick={() => setShowOrgTable(false)}
                  className={
                    `px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 ${
                      showOrgTable === false && "bg-blue-100"
                    } sm:text-sm hover:bg-gray-100`
                  }
                >
                  Directory
                </button>
              </div>
            </div>

            <div className="my-10">
              {showOrgTable ? <OrganisationTable /> : <DirectoryTable />}
            </div>
          </div>
        </div>
      </LeftSideBar>
      <Footer />
    </div>
  );
};

export default OrganisationManagement;
