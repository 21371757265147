import React, { useContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import "./../App.css";
// Import authentication context to use stored user
import { AuthContext } from "../context/authContext";

const Footer = () => {
  // User authenticated data/functions
  const auth = useContext(AuthContext);
  // Get current URL of page
  return (
    <footer>
      <div className="bg-[#353535]">
        <div className="container px-6 py-2 mx-auto">
          <div className="flex flex-col items-center text-center md:flex-row md:justify-between">
            <a href={`${!auth.isLoggedIn ? "/" : "/dashboard"}`}>
              <img
                className="w-auto h-32"
                src="../assets/temp_logo.png"
                alt="logo"
                aria-label="logo"
              />
            </a>
            <div className="flex flex-wrap justify-center mt-6 -mx-4">
              <a
                href={`${!auth.isLoggedIn ? "/" : "/dashboard"}`}
                className="mx-4 text-sm text-gray-200 transition-colors duration-300 hover:text-blue-500"
              >
                {" "}
                Home{" "}
              </a>
              <a
                href="/our-team"
                className="mx-4 text-sm text-gray-200 transition-colors duration-300 hover:text-blue-500 "
              >
                {" "}
                Our Team{" "}
              </a>
              <a
                href="/contact"
                className="mx-4 text-sm text-gray-200 transition-colors duration-300 hover:text-blue-500 "
              >
                {" "}
                Contact Us{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#8DD8F8C7]">
        <div className="container px-6 py-2 mx-auto">
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <p className="text-sm">
              Copyright © 2024 Rotary Batemans Bay | Created by The Tec Exec and
              Practera
            </p>
            <div className="flex gap-10">
              <a href="/privacy-policy" className="text-sm">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
